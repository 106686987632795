import { Locale } from "../locale.config";

const regionLocaleConfig: Locale[] = [
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Name is required.",
    id: "Nama wajib diisi",
  },
  {
    en: "Region",
    id: "Wilayah",
  },
  {
    en: "Region Detail",
    id: "Detail Wilayah",
  },
  {
    en: "Add Region",
    id: "Tambah Wilayah",
  },
  {
    en: "Update Region",
    id: "Ubah Wilayah",
  },
  {
    en: "Delete Region",
    id: "Hapus Wilayah",
  },
  {
    en: "Are you sure you want to delete region? Deleted Region cannot be undone.",
    id: "Anda yakin menghapus wilayah? Wilayah yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Region Successfully Deleted",
    id: "Wilayah Berhasil dihapus",
  },
  {
    en: "Failed to Delete Region",
    id: "Gagal Menghapus Wilayah",
  },
  {
    en: "Region successfully created",
    id: "Wilayah berhasil dibuat",
  },
  {
    en: "Failed to create region",
    id: "Gagal membuat wilayah",
  },
  {
    en: "Region successfully updated",
    id: "Wilayah berhasil diubah",
  },
  {
    en: "Failed to update region",
    id: "Gagal mengubah wilayah",
  },
  {
    en: "Try adding Region by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default regionLocaleConfig;
