import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type UserCreateRouteParam = DefaultRouteParam;

export const userCreateRoute: RouteConfig = {
  name: "Create User",
  Component: React.lazy(() => import("./UserCreate.view")),
  path: "/user-management/user/create",
  isPrivate: true,
};
