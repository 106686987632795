// #region IMPORT
import urlcat from "urlcat";
import {
  ILocationAutocompleteListApiRequest,
  ILocationAutocompleteListApiResponse,
  ILocationCreateApiRequest,
  ILocationCreateApiResponse,
  ILocationDeleteApiRequest,
  ILocationDeleteApiResponse,
  ILocationDetailApiRequest,
  ILocationDetailApiResponse,
  ILocationListApiRequest,
  ILocationListApiResponse,
  ILocationTypeAutocompleteListApiRequest,
  ILocationTypeAutocompleteListApiResponse,
  ILocationUpdateApiRequest,
  ILocationUpdateApiResponse,
} from "../../../../model/location/location.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type LocationEndpoint = {
  getLocations: QD<ILocationListApiRequest, ILocationListApiResponse>;
  getLocationsDetail: QD<ILocationDetailApiRequest, ILocationDetailApiResponse>;

  getLocationAutocomplete: QD<
    ILocationAutocompleteListApiRequest,
    ILocationAutocompleteListApiResponse
  >;
  getLocationTypeAutocomplete: QD<
    ILocationTypeAutocompleteListApiRequest,
    ILocationTypeAutocompleteListApiResponse
  >;
  createLocation: MD<ILocationCreateApiRequest, ILocationCreateApiResponse>;
  updateLocation: MD<ILocationUpdateApiRequest, ILocationUpdateApiResponse>;
  deleteLocation: MD<ILocationDeleteApiRequest, ILocationDeleteApiResponse>;
};
// #endregion

// #region ENDPOINT
export const locationTagTypes: IEndpointTags<LocationEndpoint> = {
  getLocationsDetail: "get-location-detail",
  getLocations: "get-locations",
  getLocationAutocomplete: "get-location-autocomplete",
  getLocationTypeAutocomplete: "get-location-type-autocomplete",
  createLocation: "create-location",
  updateLocation: "update-location",
  deleteLocation: "delete-location",
};

export const locationEndpoint = (builder: Builder): LocationEndpoint => ({
  getLocations: builder.query({
    query: (param) => urlcat("/locations", param),
    providesTags: [locationTagTypes.getLocations],
  }),
  getLocationsDetail: builder.query({
    query: ({ id }) => ({
      url: `/locations/${id}`,
    }),
    providesTags: [locationTagTypes.getLocationsDetail],
  }),

  getLocationAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/locations`, params),
    }),
    providesTags: [locationTagTypes.getLocationAutocomplete],
  }),
  getLocationTypeAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/location-types`, params),
    }),
    providesTags: [locationTagTypes.getLocationAutocomplete],
  }),
  createLocation: builder.mutation({
    query: (body) => ({
      url: "/locations",
      method: "POST",
      body,
    }),
    invalidatesTags: [locationTagTypes.getLocations],
  }),
  updateLocation: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/locations/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [locationTagTypes.getLocations],
  }),
  deleteLocation: builder.mutation({
    query: ({ id }) => ({
      url: `/locations/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [locationTagTypes.getLocations],
  }),
});
// #endregion
