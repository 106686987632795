import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type UserRouteParam = DefaultRouteParam;

export const userRoute: RouteConfig = {
  name: "User",
  Component: React.lazy(() => import("./User.view")),
  path: "/user-management/user",
  isPrivate: true,

  options: {
    label: "User",
    testID: "MenuUser",
  },
};
