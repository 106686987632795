import { Locale } from "../locale.config";

const roomLocaleConfig: Locale[] = [
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Name is required.",
    id: "Nama wajib diisi",
  },
  {
    en: "Room",
    id: "Ruangan",
  },
  {
    en: "Room Detail",
    id: "Detail Ruangan",
  },
  {
    en: "Add Room",
    id: "Tambah Ruangan",
  },
  {
    en: "Update Room",
    id: "Ubah Ruangan",
  },
  {
    en: "Delete Room",
    id: "Hapus Ruangan",
  },
  {
    en: "Are you sure you want to delete room? Deleted Room cannot be undone.",
    id: "Anda yakin menghapus ruangan? Ruangan yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Room Successfully Deleted",
    id: "Ruangan Berhasil dihapus",
  },
  {
    en: "Failed to Delete Room",
    id: "Gagal Menghapus Ruangan",
  },
  {
    en: "Room successfully created",
    id: "Ruangan berhasil dibuat",
  },
  {
    en: "Failed to create room",
    id: "Gagal membuat ruangan",
  },
  {
    en: "Room successfully updated",
    id: "Ruangan berhasil diubah",
  },
  {
    en: "Failed to update room",
    id: "Gagal mengubah ruangan",
  },
  {
    en: "Try adding Room by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default roomLocaleConfig;
