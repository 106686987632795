import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type RegionCreateRouteParam = DefaultRouteParam;

export const regionCreateRoute: RouteConfig = {
  name: "Create Region",
  Component: React.lazy(() => import("./RegionCreate.view")),
  path: "/user-management/region/create",
  isPrivate: true,
};
