import React from 'react';
import { DefaultRouteParam, RouteConfig } from '../../../config/router/router.config';

export type LoginRouteParam = DefaultRouteParam;

export const loginRoute: RouteConfig = {
  name: 'Login',
  path: '/login',
  Component: React.lazy(() => import('./Login.view')),
  isPrivate: false,
  options: {
    label: 'login',
    testID: 'MenuLogin',
  },
};
