import React, { useEffect } from "react";
import tw, { styled } from "twin.macro";
import useScreenDetection from "../../../hook/common/useScreenDetection/useScreenDetection.hook";
import { Button as BaseButton } from "../../atom/Button/index";
import Close from "../../atom/Icon/SVG/Close";
import Warning from "../../atom/Icon/SVG/Warning";
import BaseIconButton from "../../atom/IconButton/IconButton.atom";
import { BodySmall } from "../../atom/Text/Text.atom";

// #region INTERFACES
export enum SnackbarTheme {
  dark = "dark",
  light = "light",
  warning = "warning",
  success = "success",
  error = "error",
}

type Props = React.ComponentProps<"div"> & {
  body: string;
  onClickClose: () => void;
  theme?: SnackbarTheme;
  autoHide?: boolean;
  withClose?: boolean;
  buttonLabel?: string;
  buttonAction?: () => void;
};
// #endregion

const AUTOHIDE_DURATION = 3000;

// #region STYLED
const Container = styled.div(
  (props: { theme: SnackbarTheme; isMobile?: boolean }) => [
    tw`fixed z-10 rounded bg-gray-700 text-gray-50 text-white box-border flex inset-x-4 whitespace-normal`,
    props.isMobile && tw`top-6 mx-auto `,
    !props.isMobile && tw`bottom-4 sm:([max-width:400px] bottom-10 mx-auto) `,
    props.theme === SnackbarTheme.warning && tw`bg-error-400 text-gray-50`,
    props.theme === SnackbarTheme.success && tw`bg-accent-600 text-gray-50`,
  ]
);
const Content = tw(BodySmall)`text-gray-50 py-3.5 flex-1`;
const HorizontalDivider = tw.div`w-px bg-current opacity-30`;
const IconButton = tw(BaseIconButton)`
  text-current mr-2 self-center relative
  hover:(bg-transparent after:(content-[' '] absolute inset-0 bg-current rounded-full opacity-10))
`;
const ActionButton = tw(BaseButton.Solid)`
  bg-transparent relative px-4 py-3 text-sm text-current
  hover:(bg-transparent after:(content-[' '] absolute inset-0 bg-current rounded-r opacity-10))
`;
// #endregion

export default function Snackbar({
  body,
  onClickClose,
  theme = SnackbarTheme.dark,
  autoHide = true,
  withClose,
  buttonLabel,
  buttonAction,
}: Props) {
  const { isMobile } = useScreenDetection();
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (autoHide) {
      timeoutId = setTimeout(() => {
        onClickClose();
      }, AUTOHIDE_DURATION);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [autoHide, onClickClose]);

  return (
    <Container theme={theme} isMobile={isMobile}>
      <div tw="px-3 py-3.5 flex items-center">
        <Warning height={16} width={16} />
      </div>
      <Content>{body}</Content>

      {withClose && !buttonAction && (
        <IconButton
          onClick={(ev) => {
            ev.stopPropagation();
            onClickClose();
          }}
        >
          <Close height={16} width={16} />
        </IconButton>
      )}

      {!!buttonAction && (
        <>
          <HorizontalDivider />
          <ActionButton onClick={buttonAction}>{buttonLabel}</ActionButton>
        </>
      )}
    </Container>
  );
}
