import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type NewsCreateRouteParam = DefaultRouteParam;

export const newsCreateRoute: RouteConfig = {
  name: "Create News",
  Component: React.lazy(() => import("./NewsCreate.view")),
  path: "/master-data/news/create",
  isPrivate: true,
};
