import React, { createRef, useEffect } from "react";

type Props = React.ComponentProps<"div"> & {
  onClickAway: () => void;
};

export default function OutsideAlerter({
  onClickAway,
  children,
  ...props
}: Props) {
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway();
      }
    };
    const handleFocusOutside = (event: FocusEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("focusin", handleFocusOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("focusin", handleFocusOutside);
    };
  }, [ref, onClickAway]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={ref} {...props}>
      {children}
    </div>
  );
}
