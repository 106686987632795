import { Locale } from "../locale.config";

const newsLocaleConfig: Locale[] = [
  {
    en: "News",
    id: "Pewarta",
  },
  {
    en: "Title",
    id: "Judul",
  },
  {
    en: "Title is required",
    id: "Judul wajib diisi",
  },
  {
    en: "Action",
    id: "Aksi",
  },
  {
    en: "Add News",
    id: "Tambah Pewarta",
  },
  {
    en: "Update News",
    id: "Ubah Pewarta",
  },
  {
    en: "Date",
    id: "Tanggal",
  },
  {
    en: "Images",
    id: "Gambar",
  },
  {
    en: "Images is required.",
    id: "Gambar wajib diisi.",
  },
  {
    en: "Title is required.",
    id: "Judul wajib diisi.",
  },
  {
    en: "Select Date",
    id: "Pilih Tanggal",
  },
  {
    en: "Max size 2 MB.",
    id: "Ukuran Maksimal 2 MB.",
  },
  {
    en: "Content is required.",
    id: "Konten wajib diisi.",
  },
  {
    en: "Date is required.",
    id: "Tanggal wajib diisi.",
  },
  {
    en: "Content",
    id: "Konten",
  },
  {
    en: "News successfully updated",
    id: "Pewarta berhasil diubah",
  },
  {
    en: "News successfully created",
    id: "Pewarta berhasil dibuat",
  },
  {
    en: "Failed to update news",
    id: "Gagal mengubah pewarta",
  },
  {
    en: "Failed to create news",
    id: "Gagal membuat pewarta",
  },
  {
    en: "Delete News",
    id: "Hapus Pewarta",
  },
  {
    en: "Are you sure you want to delete news? Deleted News cannot be undone.",
    id: "Anda yakin menghapus pewarta? Pewarta yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "News Successfully Deleted",
    id: "Pewarta Berhasil Dihapus",
  },
  {
    en: "Failed to Delete News",
    id: "Gagal Menghapus pewarta",
  },
];

export default newsLocaleConfig;
