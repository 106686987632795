import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IAgendaAssignment } from "../../../../model/agendaAssignment/agendaAssignment.model";

export type AgendaAssignmentEditRouteParam = {
  agendaAssignment: IAgendaAssignment;
};

export const agendaAssignmentEditRoute: RouteConfig = {
  name: "Edit Agenda Assignment",
  Component: React.lazy(() => import("./AgendaAssignmentEdit.view")),
  path: "/agenda/assignment/edit/:id",
  isPrivate: true,
};
