// #group IMPORT
import urlcat from "urlcat";
import {
  IGroupAutocompleteListApiRequest,
  IGroupAutocompleteListApiResponse,
  IGroupCreateApiRequest,
  IGroupCreateApiResponse,
  IGroupDeleteApiRequest,
  IGroupDeleteApiResponse,
  IGroupListApiRequest,
  IGroupListApiResponse,
  IGroupUpdateApiRequest,
  IGroupUpdateApiResponse,
} from "../../../../model/group/group.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endgroup

// #group INTERFACES
export type GroupEndpoint = {
  getGroup: QD<IGroupListApiRequest, IGroupListApiResponse>;
  getGroupAutocomplete: QD<
    IGroupAutocompleteListApiRequest,
    IGroupAutocompleteListApiResponse
  >;
  createGroup: MD<IGroupCreateApiRequest, IGroupCreateApiResponse>;
  updateGroup: MD<IGroupUpdateApiRequest, IGroupUpdateApiResponse>;
  deleteGroup: MD<IGroupDeleteApiRequest, IGroupDeleteApiResponse>;
};
// #endgroup

// #group ENDPOINT
export const groupTagTypes: IEndpointTags<GroupEndpoint> = {
  getGroup: "get-group",
  getGroupAutocomplete: "get-group-autocomplete",
  createGroup: "create-group",
  updateGroup: "update-group",
  deleteGroup: "delete-group",
};

export const groupEndpoint = (builder: Builder): GroupEndpoint => ({
  getGroup: builder.query({
    query: ({ regionId, ...params }) => ({
      url: urlcat(`/regions/${regionId}/groups`, params),
    }),
    providesTags: [groupTagTypes.getGroup],
  }),
  getGroupAutocomplete: builder.query({
    query: ({ regionId, ...params }) => ({
      url: urlcat(`/auto-complete/regions/${regionId}/groups`, params),
    }),
    providesTags: [groupTagTypes.getGroupAutocomplete],
  }),
  createGroup: builder.mutation({
    query: ({ regionId, ...body }) => ({
      url: `/regions/${regionId}/groups`,
      method: "POST",
      body,
    }),
    invalidatesTags: [groupTagTypes.getGroup],
  }),
  updateGroup: builder.mutation({
    query: ({ id, regionId, ...body }) => ({
      url: `/regions/${regionId}/groups/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [groupTagTypes.getGroup],
  }),
  deleteGroup: builder.mutation({
    query: ({ id, regionId }) => ({
      url: `/regions/${regionId}/groups/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [groupTagTypes.getGroup],
  }),
});
// #endgroup
