// #region IMPORT
import urlcat from "urlcat";
import {
  IRegionAutocompleteListApiRequest,
  IRegionAutocompleteListApiResponse,
  IRegionCreateApiRequest,
  IRegionCreateApiResponse,
  IRegionDeleteApiRequest,
  IRegionDeleteApiResponse,
  IRegionListApiRequest,
  IRegionListApiResponse,
  IRegionUpdateApiRequest,
  IRegionUpdateApiResponse,
} from "../../../../model/region/region.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type RegionEndpoint = {
  getRegion: QD<IRegionListApiRequest, IRegionListApiResponse>;
  getRegionAutocomplete: QD<
    IRegionAutocompleteListApiRequest,
    IRegionAutocompleteListApiResponse
  >;
  createRegion: MD<IRegionCreateApiRequest, IRegionCreateApiResponse>;
  updateRegion: MD<IRegionUpdateApiRequest, IRegionUpdateApiResponse>;
  deleteRegion: MD<IRegionDeleteApiRequest, IRegionDeleteApiResponse>;
};
// #endregion

// #region ENDPOINT
export const regionTagTypes: IEndpointTags<RegionEndpoint> = {
  getRegion: "get-region",
  getRegionAutocomplete: "get-region-autocomplete",
  createRegion: "create-region",
  updateRegion: "update-region",
  deleteRegion: "delete-region",
};

export const regionEndpoint = (builder: Builder): RegionEndpoint => ({
  getRegion: builder.query({
    query: (param) => urlcat("/regions", param),
    providesTags: [regionTagTypes.getRegion],
  }),
  getRegionAutocomplete: builder.query({
    query: (param) => urlcat("/auto-complete/regions", param),
    providesTags: [regionTagTypes.getRegionAutocomplete],
  }),
  createRegion: builder.mutation({
    query: (body) => ({
      url: "/regions",
      method: "POST",
      body,
    }),
    invalidatesTags: [regionTagTypes.getRegion],
  }),
  updateRegion: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/regions/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [regionTagTypes.getRegion],
  }),
  deleteRegion: builder.mutation({
    query: ({ id }) => ({
      url: `/regions/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [regionTagTypes.getRegion],
  }),
});
// #endregion
