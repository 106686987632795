import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type NewsRouteParam = DefaultRouteParam;

export const newsRoute: RouteConfig = {
  name: "News",
  Component: React.lazy(() => import("./News.view")),
  path: "/master-data/news",
  isPrivate: true,

  options: {
    label: "News",
    testID: "MenuNews",
  },
};
