import { Locale } from "../locale.config";

const sacramentLocaleConfig: Locale[] = [
  {
    en: "Applicant Type",
    id: "Tipe Pemohon",
  },
  {
    en: "Applicant",
    id: "Pemohon",
  },
  {
    en: "Served By",
    id: "Dilayani Oleh",
  },
  {
    en: "Sacrament",
    id: "Pengajuan Pelayanan",
  },
  {
    en: "Sacrament TNC",
    id: "TnC Pengajuan Pelayanan",
  },
  {
    en: "Document",
    id: "Dokumen",
  },
  {
    en: "Update Sacrament TNC",
    id: "Ubah TnC Pengajuan Pelayanan",
  },
  {
    en: "Self",
    id: "Sendiri",
  },
  {
    en: "Parent",
    id: "Orang tua",
  },
  {
    en: "Child",
    id: "Anak",
  },
  {
    en: "Baptism",
    id: "Baptis",
  },
  {
    en: "Baptism Child",
    id: "Baptis Anak",
  },
  {
    en: "Baptism Adult",
    id: "Baptis Dewasa",
  },
  {
    en: "Sidi",
    id: "Sidi",
  },
  {
    en: "Sins Confession",
    id: "Pengakuan Dosa",
  },
  {
    en: "Marriage Blessing",
    id: "Pemberkatan Nikah",
  },
  {
    en: "Consolation",
    id: "Penghiburan",
  },
  {
    en: "Funeral",
    id: "Pemakaman",
  },
  {
    en: "Bidston",
    id: "Bidston Keluarga",
  },
  {
    en: "Gratitude Worship",
    id: "Ibadah Syukur",
  },
  {
    en: "Service Type",
    id: "Type Pelayanan",
  },
  {
    en: "Select Service Type",
    id: "Pilih Tipe Pelayanan",
  },
  {
    en: "Update Sacrament",
    id: "Ubah Pengajuan Pelayanan",
  },
  {
    en: "Applicant Names",
    id: "Yang dilayani",
  },
  {
    en: "Prayer Meeting",
    id: "Persekutuan Doa",
  },
  {
    en: "Reject Sacrament",
    id: "Tolak Pengajuan",
  },
  {
    en: "Approve Sacrament",
    id: "Approval Pengajuan",
  },
  {
    en: "Are you sure you want to approve sacrament? Approved Sacrament cannot be undone.",
    id: "Anda yakin menyetujui pengajuan? Pengajuan yang disetujui tidak dapat diubah kembali.",
  },
  {
    en: "Are you sure you want to reject Sacrament?",
    id: "Anda yakin menolak pengajuan?",
  },
  {
    en: "Rejected sacrament cannot be undone.",
    id: "Pengajuan yang ditolak tidak dapat diubah kembali.",
  },
  {
    en: "Confirm",
    id: "Konfirmasi",
  },
  {
    en: "Accepted",
    id: "Diterima",
  },
  {
    en: "Approve",
    id: "Setujui",
  },
  {
    en: "Reject",
    id: "Tolak",
  },
  {
    en: "Rejected",
    id: "Ditolak",
  },
  {
    en: "Waiting for Approval",
    id: "Menunggu Persetujuan",
  },
  {
    en: "Reject Reason",
    id: "Alasan Penolakan",
  },
  {
    en: "Detail Sacrament",
    id: "Detil Pengajuan Layanan",
  },
  {
    en: "Sacrament Type",
    id: "Jenis Pelayanan",
  },
  {
    en: "Served by",
    id: "Dilayani oleh",
  },
  {
    en: "Served",
    id: "Yang dilayani",
  },
  {
    en: "Place of Service",
    id: "Tempat Pelayanan",
  },
  {
    en: "Sacrament successfully updated",
    id: "Pengajuan Pelayanan berhasil diubah",
  },
  {
    en: "Failed to update sacrament",
    id: "Gagal mengubah pengajuan pelayanan",
  },
  {
    en: "Are you sure you want to delete sacrament? Deleted Sacrament cannot be undone.",
    id: "Anda yakin menghapus pengajuan? Pengajuan yang dihapus tidak dapat diubah kembali.",
  },
];

export default sacramentLocaleConfig;
