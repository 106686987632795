import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  viewBox?: string;
};

export default function Report({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = 'currentColor',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
    >
      <g mask="url(#mask0_49_2288)">
        <path
          fill={fill}
          d="M3 20V7l4 3 5-7 5 4h4v13H3zm5-3l4-5.5 7 5.45V9h-2.7l-3.9-3.125-4.95 6.95L5 11v3.6L8 17z"
        />
      </g>
    </svg>
  );
}
