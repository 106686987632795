import React from "react";
import Dashboard from "../../component/atom/Icon/SVG/Dashboard";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";
import { announcementsRoute } from "../announcements/Announcements.route";
import { faqRoute } from "../faq/FAQ.route";
import { newsRoute } from "../news/News.route";
import { reflectionsRoute } from "../reflections/Reflections.route";
import { sacramentRoute } from "../sacrament/Sacrament.route";
import { sacramentTNCRoute } from "../sacramentTNC/SacramentTNC.route";

export type MasterDataRouteParam = DefaultRouteParam;

export const masterDataRoute: RouteConfig = {
  name: "Pengelolaan Konten",
  Component: React.lazy(() => import("./MasterData.view")),
  path: "/master-data",
  isPrivate: false,
  breadcrumb: "Pengelolaan Konten",
  children: [
    {
      ...newsRoute,
      path: "news",
    },
    {
      ...reflectionsRoute,
      path: "reflections",
    },
    {
      ...sacramentRoute,
      path: "sacrament",
    },
    {
      ...sacramentTNCRoute,
      path: "sacrament-tnc",
    },
    {
      ...announcementsRoute,
      path: "announcements",
    },
    {
      ...faqRoute,
      path: "faq",
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    icon: <Dashboard />,
    label: "Pengelolaan Konten",
    testID: "MenuMasterData",
    hasDivider: true,
    hasSub: [
      newsRoute,
      reflectionsRoute,
      sacramentRoute,
      announcementsRoute,
      sacramentTNCRoute,
      faqRoute,
    ],
  },
};
