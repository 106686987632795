// #REGION IMPORT
import urlcat from "urlcat";
import {
  IFamilyCreateApiRequest,
  IFamilyCreateApiResponse,
  IFamilyDeleteApiRequest,
  IFamilyDeleteApiResponse,
  IFamilyListApiRequest,
  IFamilyListApiResponse,
  IFamilyUpdateApiRequest,
  IFamilyUpdateApiResponse,
} from "../../../../model/family/family.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #REGION INTERFACES
export type FamilyEndpoint = {
  getFamily: QD<IFamilyListApiRequest, IFamilyListApiResponse>;
  createFamily: MD<IFamilyCreateApiRequest, IFamilyCreateApiResponse>;
  updateFamily: MD<IFamilyUpdateApiRequest, IFamilyUpdateApiResponse>;
  deleteFamily: MD<IFamilyDeleteApiRequest, IFamilyDeleteApiResponse>;
};
// #endregion

// #REGION ENDPOINT
export const familyTagTypes: IEndpointTags<FamilyEndpoint> = {
  getFamily: "get-family",
  createFamily: "create-family",
  updateFamily: "update-family",
  deleteFamily: "delete-family",
};

export const familyEndpoint = (builder: Builder): FamilyEndpoint => ({
  getFamily: builder.query({
    query: (params) => ({
      url: urlcat(`/families`, params),
    }),
    providesTags: [familyTagTypes.getFamily],
  }),
  createFamily: builder.mutation({
    query: (body) => ({
      url: `/families`,
      method: "POST",
      body,
    }),
    invalidatesTags: [familyTagTypes.getFamily],
  }),
  updateFamily: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/families/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [familyTagTypes.getFamily],
  }),
  deleteFamily: builder.mutation({
    query: ({ id }) => ({
      url: `/families/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [familyTagTypes.getFamily],
  }),
});
// #endregion
