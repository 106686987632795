import { Locale } from "../locale.config";

const agendaScheduleLocaleConfig: Locale[] = [
  {
    en: "Agenda Schedule",
    id: "Jadwal",
  },
  {
    en: "Agenda Schedule Detail",
    id: "Detail Jadwal",
  },
  {
    en: "Add Agenda Schedule",
    id: "Tambah Jadwal",
  },
  {
    en: "Documentation Url",
    id: "Url Dokumentasi",
  },
  {
    en: "Zoom Url",
    id: "Url Zoom",
  },
  {
    en: "Month",
    id: "Bulan",
  },
  {
    en: "Event Type",
    id: "Jenis Kegiatan",
  },
  {
    en: "Select Month",
    id: "Pilih Bulan",
  },
  {
    en: "Place of Activity",
    id: "Tempat Kegiatan",
  },
  {
    en: "Speaker Name",
    id: "Pembawa Firman",
  },
  {
    en: "Theme",
    id: "Tema",
  },
  {
    en: "Author Name",
    id: "Nama Penulis",
  },
  {
    en: "Agenda Type is required.",
    id: "Jenis Jadwal wajib diisi.",
  },
  {
    en: "Phase",
    id: "Fase",
  },
  {
    en: "Phase is required.",
    id: "Fase wajib diisi.",
  },
  {
    en: "Update Agenda Schedule",
    id: "Ubah Jadwal",
  },
  {
    en: "Select Agenda Types",
    id: "Pilih Jenis Jadwal",
  },
  {
    en: "Select Agenda",
    id: "Pilih Kegiatan",
  },
  {
    en: "Agenda Event is required.",
    id: "Kegiatan wajib diisi.",
  },
  {
    en: "Select Agenda Types",
    id: "Pilih Jenis Jadwal",
  },
  {
    en: "Select Agenda Event",
    id: "Pilih Jenis Kegiatan",
  },
  {
    en: "Literature",
    id: "Literatur",
  },
  {
    en: "Select Room",
    id: "Pilih Ruangan",
  },
  {
    en: "Delete Agenda Schedule",
    id: "Hapus Jadwal",
  },
  {
    en: "Are you sure you want to delete agenda Schedule? Deleted Agenda Schedule cannot be undone.",
    id: "Anda yakin menghapus Jadwal? Jadwal yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Agenda Schedule Successfully Deleted",
    id: "Jadwal Berhasil dihapus",
  },
  {
    en: "Failed to Delete Agenda Schedule",
    id: "Gagal Menghapus Jadwal",
  },
  {
    en: "Agenda Schedule successfully created",
    id: "Jadwal berhasil dibuat",
  },
  {
    en: "Failed to create agenda Schedule",
    id: "Gagal membuat Jadwal",
  },
  {
    en: "Agenda Schedule successfully updated",
    id: "Jadwal berhasil diubah",
  },
  {
    en: "Failed to update agenda Schedule",
    id: "Gagal mengubah Jadwal",
  },
  {
    en: "Try adding Agenda Schedule by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default agendaScheduleLocaleConfig;
