import { Locale } from "../locale.config";

const familyLocaleConfig: Locale[] = [
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Name is required.",
    id: "Nama wajib diisi",
  },
  {
    en: "Family",
    id: "Keluarga",
  },
  {
    en: "Family Detail",
    id: "Detail Keluarga",
  },
  {
    en: "Add Family",
    id: "Tambah Keluarga",
  },
  {
    en: "Update Family",
    id: "Ubah Keluarga",
  },
  {
    en: "Delete Family",
    id: "Hapus Keluarga",
  },
  {
    en: "Are you sure you want to delete family? Deleted Family cannot be undone.",
    id: "Anda yakin menghapus keluarga? Keluarga yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Family Successfully Deleted",
    id: "Keluarga Berhasil dihapus",
  },
  {
    en: "Failed to Delete Family",
    id: "Gagal Menghapus Keluarga",
  },
  {
    en: "Family successfully created",
    id: "Keluarga berhasil dibuat",
  },
  {
    en: "Failed to create family",
    id: "Gagal membuat keluarga",
  },
  {
    en: "Family successfully updated",
    id: "Keluarga berhasil diubah",
  },
  {
    en: "Failed to update family",
    id: "Gagal mengubah keluarga",
  },
  {
    en: "Head of Family",
    id: "Kepala Keluarga",
  },
  {
    en: "Head of Family",
    id: "Kepala Keluarga",
  },
  {
    en: "Head of Family is required.",
    id: "Kepala Keluarga wajib diisi.",
  },
  {
    en: "Please Input NIW",
    id: "Mohon Masukkan NIW",
  },
  {
    en: "Please Input Exact NIW, if User is not found that means user already in family",
    id: "Mohon Input NIW yang tepat, Jika umat tidak ditemukan berarti umat sudah menjadi anggota keluarga",
  },
  {
    en: "Try adding Family by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default familyLocaleConfig;
