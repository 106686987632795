import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type RegionRouteParam = DefaultRouteParam;

export const regionRoute: RouteConfig = {
  name: "Region",
  Component: React.lazy(() => import("./Region.view")),
  path: "/user-management/region",
  isPrivate: true,

  options: {
    label: "Region",
    testID: "MenuRegion",
  },
};
