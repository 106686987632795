import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type PlaceCreateRouteParam = DefaultRouteParam;

export const placeCreateRoute: RouteConfig = {
  name: "Create place",
  Component: React.lazy(() => import("./PlaceCreate.view")),
  path: "/location/place/create",
  isPrivate: true,
};
