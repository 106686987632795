// #region IMPORT
import urlcat from "urlcat";
import {
  IAgendaAssignmentAutocompleteListApiRequest,
  IAgendaAssignmentAutocompleteListApiResponse,
  IAgendaAssignmentCreateApiRequest,
  IAgendaAssignmentCreateApiResponse,
  IAgendaAssignmentDeleteApiRequest,
  IAgendaAssignmentDeleteApiResponse,
  IAgendaAssignmentDetailApiRequest,
  IAgendaAssignmentDetailApiResponse,
  IAgendaAssignmentListApiRequest,
  IAgendaAssignmentListApiResponse,
  IAgendaAssignmentUpdateApiRequest,
  IAgendaAssignmentUpdateApiResponse,
} from "../../../../model/agendaAssignment/agendaAssignment.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type AgendaAssignmentEndpoint = {
  getAgendaAssignment: QD<
    IAgendaAssignmentListApiRequest,
    IAgendaAssignmentListApiResponse
  >;
  getAgendaAssignmentDetail: QD<
    IAgendaAssignmentDetailApiRequest,
    IAgendaAssignmentDetailApiResponse
  >;
  getAgendaAssignmentAutocomplete: QD<
    IAgendaAssignmentAutocompleteListApiRequest,
    IAgendaAssignmentAutocompleteListApiResponse
  >;
  createAgendaAssignment: MD<
    IAgendaAssignmentCreateApiRequest,
    IAgendaAssignmentCreateApiResponse
  >;
  updateAgendaAssignment: MD<
    IAgendaAssignmentUpdateApiRequest,
    IAgendaAssignmentUpdateApiResponse
  >;
  deleteAgendaAssignment: MD<
    IAgendaAssignmentDeleteApiRequest,
    IAgendaAssignmentDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const agendaAssignmentTagTypes: IEndpointTags<AgendaAssignmentEndpoint> =
  {
    getAgendaAssignment: "get-agenda-assignment",
    getAgendaAssignmentDetail: "get-agenda-assignment-detail",
    getAgendaAssignmentAutocomplete: "get-agenda-assignment-autocomplete",
    createAgendaAssignment: "create-agenda-assignment",
    updateAgendaAssignment: "update-agenda-assignment",
    deleteAgendaAssignment: "delete-agenda-assignment",
  };

export const agendaAssignmentEndpoint = (
  builder: Builder
): AgendaAssignmentEndpoint => ({
  getAgendaAssignment: builder.query({
    query: (param) => urlcat("/agenda-assignments", param),
    providesTags: [agendaAssignmentTagTypes.getAgendaAssignment],
  }),
  getAgendaAssignmentDetail: builder.query({
    query: ({ id }) => ({ url: `/agenda-assignments/${id}` }),
    providesTags: [agendaAssignmentTagTypes.getAgendaAssignmentDetail],
  }),
  getAgendaAssignmentAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/agenda-assignments`, params),
    }),
    providesTags: [agendaAssignmentTagTypes.getAgendaAssignmentAutocomplete],
  }),
  createAgendaAssignment: builder.mutation({
    query: (body) => ({
      url: "/agenda-assignments",
      method: "POST",
      body,
    }),
    invalidatesTags: [agendaAssignmentTagTypes.getAgendaAssignment],
  }),
  updateAgendaAssignment: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/agenda-assignments/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [agendaAssignmentTagTypes.getAgendaAssignment],
  }),
  deleteAgendaAssignment: builder.mutation({
    query: ({ id }) => ({
      url: `/agenda-assignments/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [agendaAssignmentTagTypes.getAgendaAssignment],
  }),
});
// #endregion
