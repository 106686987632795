import { Locale } from "../locale.config";

const reflectionsLocaleConfig: Locale[] = [
  {
    en: "Reflections",
    id: "Renungan",
  },
  {
    en: "Add Reflections",
    id: "Tambah Renungan",
  },
  {
    en: "Update Reflections",
    id: "Ubah Renungan",
  },
  {
    en: "Daily",
    id: "Harian",
  },
  {
    en: "Evening Prayer",
    id: "Doa Malam",
  },
  {
    en: "Type",
    id: "Tipe",
  },
  {
    en: "Weekly",
    id: "Mingguan",
  },
  {
    en: "Soul Vitamin",
    id: "Vitamin Jiwa",
  },
  {
    en: "Reflections Date",
    id: "Tanggal Renungan",
  },
  {
    en: "Description",
    id: "Deskripsi",
  },
  {
    en: "Verse",
    id: "Ayat",
  },
  {
    en: "Type is required",
    id: "Tipe wajib diisi",
  },
  {
    en: "Date is required",
    id: "Tanggal wajib diisi",
  },
  {
    en: "Descriptions is required",
    id: "Deskripsi wajib diisi",
  },
  {
    en: "Verse is required",
    id: "Ayat wajib diisi",
  },

  {
    en: "Reflections successfully updated",
    id: "Renungan berhasil diubah",
  },
  {
    en: "Reflections successfully created",
    id: "Renungan berhasil dibuat",
  },
  {
    en: "Failed to update reflections",
    id: "Gagal mengubah renungan",
  },
  {
    en: "Failed to create reflections",
    id: "Gagal membuat renungan",
  },
  {
    en: "Youtube URL is not valid",
    id: "URL Youtube tidak valid",
  },
  {
    en: "Zoom URL is not valid",
    id: "URL Zoom tidak valid",
  },
  {
    en: "Can not input more than 255 characters",
    id: "Max 255 karakter",
  },
  {
    en: "Reflections Successfully Deleted",
    id: "Renungan Berhasil Dihapus",
  },
  {
    en: "Failed to Delete Reflections",
    id: "Gagal Menghapus renungan",
  },
  {
    en: "Are you sure you want to delete reflections? Deleted Reflections cannot be undone.",
    id: "Anda yakin menghapus renungan? Renungan yang dihapus tidak dapat diubah kembali.",
  },
];

export default reflectionsLocaleConfig;
