// #region IMPORT
import urlcat from "urlcat";
import {
  ICouncilCreateApiRequest,
  ICouncilCreateApiResponse,
  ICouncilDeleteApiRequest,
  ICouncilDeleteApiResponse,
  ICouncilDetailApiRequest,
  ICouncilDetailApiResponse,
  ICouncilListApiRequest,
  ICouncilListApiResponse,
  ICouncilUpdateApiRequest,
  ICouncilUpdateApiResponse,
  ICouncilUpdateStatusRequest,
  ICouncilUpdateStatusResponse,
} from "../../../../model/council/council.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type CouncilEndpoint = {
  getCouncils: QD<ICouncilListApiRequest, ICouncilListApiResponse>;
  getCouncilDetail: QD<ICouncilDetailApiRequest, ICouncilDetailApiResponse>;
  createCouncil: MD<ICouncilCreateApiRequest, ICouncilCreateApiResponse>;
  updateCouncil: MD<ICouncilUpdateApiRequest, ICouncilUpdateApiResponse>;
  activateCouncil: MD<
    ICouncilUpdateStatusRequest,
    ICouncilUpdateStatusResponse
  >;
  inactivateCouncil: MD<
    ICouncilUpdateStatusRequest,
    ICouncilUpdateStatusResponse
  >;
  deleteCouncil: MD<ICouncilDeleteApiRequest, ICouncilDeleteApiResponse>;
};
// #endregion

// #region ENDPOINT
export const councilTagTypes: IEndpointTags<CouncilEndpoint> = {
  getCouncils: "get-councils",
  getCouncilDetail: "get-council-detail",
  createCouncil: "create-council",
  updateCouncil: "update-council",
  activateCouncil: "activate-council",
  inactivateCouncil: "inactivate-council",
  deleteCouncil: "delete-council",
};

export const councilEndpoint = (builder: Builder): CouncilEndpoint => ({
  getCouncils: builder.query({
    query: (param) => urlcat("/councils", param),
    providesTags: [councilTagTypes.getCouncils],
  }),
  getCouncilDetail: builder.query({
    query: ({ id }) => ({
      url: `/councils/${id}`,
    }),
    providesTags: [councilTagTypes.getCouncilDetail],
  }),

  createCouncil: builder.mutation({
    query: (body) => ({
      url: "/councils",
      method: "POST",
      body,
    }),
    invalidatesTags: [councilTagTypes.getCouncils],
  }),
  updateCouncil: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/councils/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [councilTagTypes.getCouncils],
  }),
  activateCouncil: builder.mutation({
    query: ({ id: councilId }) => ({
      url: `/councils/${councilId}/active`,
      method: "PUT",
    }),
    invalidatesTags: [councilTagTypes.getCouncils],
  }),
  inactivateCouncil: builder.mutation({
    query: ({ id: councilId }) => ({
      url: `/councils/${councilId}/inactive`,
      method: "PUT",
    }),
    invalidatesTags: [councilTagTypes.getCouncils],
  }),
  deleteCouncil: builder.mutation({
    query: ({ id }) => ({
      url: `/councils/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [councilTagTypes.getCouncils],
  }),
});
// #endregion
