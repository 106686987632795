import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type CongregationCreateRouteParam = DefaultRouteParam;

export const congregationCreateRoute: RouteConfig = {
  name: "Create Congregation",
  Component: React.lazy(() => import("./CongregationCreate.view")),
  path: "/administration/congregation/create",
  isPrivate: true,
};
