import React from "react";
import Customer from "../../component/atom/Icon/SVG/Customer";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type SettingsRouteParam = DefaultRouteParam;

export const settingsRoute: RouteConfig = {
  name: "Settings",
  Component: React.lazy(() => import("./Settings.view")),
  path: "/setting",
  isPrivate: true,
  breadcrumb: "Settings",

  props: {
    isUnclickable: true,
  },
  options: {
    icon: <Customer />,
    label: "Settings",
    testID: "MenuSettings",
  },
};
