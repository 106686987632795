// #region IMPORT
import urlcat from "urlcat";
import {
  IUserAutocompleteListApiRequest,
  IUserAutocompleteListApiResponse,
  IUserCreateApiRequest,
  IUserCreateApiResponse,
  IUserDeleteApiRequest,
  IUserDeleteApiResponse,
  IUserDetailApiRequest,
  IUserDetailApiResponse,
  IUserListApiRequest,
  IUserListApiResponse,
  IUserUpdateApiRequest,
  IUserUpdateApiResponse,
} from "../../../../model/user/user.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type UserEndpoint = {
  getUsers: QD<IUserListApiRequest, IUserListApiResponse>;
  getUsersDetail: QD<IUserDetailApiRequest, IUserDetailApiResponse>;
  getUserAutocomplete: QD<
    IUserAutocompleteListApiRequest,
    IUserAutocompleteListApiResponse
  >;
  createUser: MD<IUserCreateApiRequest, IUserCreateApiResponse>;
  updateUser: MD<IUserUpdateApiRequest, IUserUpdateApiResponse>;
  deleteUser: MD<IUserDeleteApiRequest, IUserDeleteApiResponse>;
};
// #endregion

// #region ENDPOINT
export const userTagTypes: IEndpointTags<UserEndpoint> = {
  getUsers: "get-users",
  getUsersDetail: "get-users-detail",
  getUserAutocomplete: "get-user-autocomplete",
  createUser: "create-user",
  updateUser: "update-user",
  deleteUser: "delete-user",
};

export const userEndpoint = (builder: Builder): UserEndpoint => ({
  getUsers: builder.query({
    query: (param) => urlcat("/users", param),
    providesTags: [userTagTypes.getUsers],
  }),
  getUsersDetail: builder.query({
    query: ({ id }) => ({
      url: `/users/${id}`,
    }),
    providesTags: [userTagTypes.getUsersDetail],
  }),
  getUserAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/users`, params),
    }),
    providesTags: [userTagTypes.getUserAutocomplete],
  }),
  createUser: builder.mutation({
    query: (body) => ({
      url: `/users`,
      method: "POST",
      body,
    }),
    invalidatesTags: [userTagTypes.getUsers],
  }),
  updateUser: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/users/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [userTagTypes.getUsers],
  }),
  deleteUser: builder.mutation({
    query: ({ id }) => ({
      url: `/users/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [userTagTypes.getUsers],
  }),
});
// #endregion
