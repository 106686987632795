import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import tw from "twin.macro";
import { RootState } from "../../../app/store/store.app";
import { snackbarAction } from "../../../service/reducer/store/snackbar/snackbar.store";
import Snackbar, { SnackbarTheme } from "./Snackbar.molecule";

// #region STYLED
const SnackbarContainer = tw.aside`fixed bottom-0 left-1/2 -translate-x-1/2 translate-y-1/4 z-[9999] w-full transition-opacity animate-fade-in`;
// #endregion

export default function SnackbarAdapter() {
  const snackbar = useSelector((state: RootState) => state.snackbar);
  const dispatch = useDispatch();

  const onClickCloseSnackbar = useCallback(() => {
    dispatch(snackbarAction.hide());
  }, [dispatch]);

  if (!snackbar.data) return null;

  return (
    <SnackbarContainer>
      <Snackbar
        body={snackbar.data?.message || ""}
        onClickClose={onClickCloseSnackbar}
        theme={snackbar.data?.type as SnackbarTheme}
        autoHide={snackbar.options.autoHide}
        withClose={snackbar.options.withClose}
      />
    </SnackbarContainer>
  );
}
