import id from 'date-fns/locale/id';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { GlobalStyles } from 'twin.macro';
import App from './app/container/Container.app';
import './index.css';
import reportWebVitals from './reportWebVitals';
import regiterUploaderPlugin from './service/uploader/uploader.service';

setDefaultOptions({
  locale: id,
});

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
regiterUploaderPlugin();
reportWebVitals();
