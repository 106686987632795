import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../config/router/router.config";

export type ResetPasswordRouteParam = DefaultRouteParam;

export const resetPasswordRoute: RouteConfig = {
  name: "Reset Password",
  path: "/reset-password",
  Component: React.lazy(() => import("./ResetPassword.view")),
  isPrivate: false,
  options: {
    label: "resetPassword",
    testID: "MenuResetPassword",
  },
};
