// #region IMPORT
import urlcat from "urlcat";
import {
  ICongregationAutocompleteListApiRequest,
  ICongregationAutocompleteListApiResponse,
  ICongregationCreateApiRequest,
  ICongregationCreateApiResponse,
  ICongregationDeleteApiRequest,
  ICongregationDeleteApiResponse,
  ICongregationListApiRequest,
  ICongregationListApiResponse,
  ICongregationUpdateApiRequest,
  ICongregationUpdateApiResponse,
} from "../../../../model/congregation/congregation.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type CongregationEndpoint = {
  getCongregation: QD<
    ICongregationListApiRequest,
    ICongregationListApiResponse
  >;
  getCongregationAutocomplete: QD<
    ICongregationAutocompleteListApiRequest,
    ICongregationAutocompleteListApiResponse
  >;
  createCongregation: MD<
    ICongregationCreateApiRequest,
    ICongregationCreateApiResponse
  >;
  updateCongregation: MD<
    ICongregationUpdateApiRequest,
    ICongregationUpdateApiResponse
  >;
  deleteCongregation: MD<
    ICongregationDeleteApiRequest,
    ICongregationDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const congregationTagTypes: IEndpointTags<CongregationEndpoint> = {
  getCongregation: "get-congregation",
  getCongregationAutocomplete: "get-congregation-autocomplete",
  createCongregation: "create-congregation",
  updateCongregation: "update-congregation",
  deleteCongregation: "delete-congregation",
};

export const congregationEndpoint = (
  builder: Builder
): CongregationEndpoint => ({
  getCongregation: builder.query({
    query: (param) => urlcat("/congregations", param),
    providesTags: [congregationTagTypes.getCongregation],
  }),
  getCongregationAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/congregations`, params),
    }),
    providesTags: [congregationTagTypes.getCongregationAutocomplete],
  }),
  createCongregation: builder.mutation({
    query: (body) => ({
      url: "/congregations",
      method: "POST",
      body,
    }),
    invalidatesTags: [congregationTagTypes.getCongregation],
  }),
  updateCongregation: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/congregations/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [congregationTagTypes.getCongregation],
  }),
  deleteCongregation: builder.mutation({
    query: ({ id }) => ({
      url: `/congregations/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [congregationTagTypes.getCongregation],
  }),
});
// #endregion
