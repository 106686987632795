import { Locale } from "../locale.config";

const positionLocaleConfig: Locale[] = [
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Name is required.",
    id: "Nama wajib diisi",
  },
  {
    en: "Position",
    id: "Jabatan",
  },
  {
    en: "Position Detail",
    id: "Detail Jabatan",
  },
  {
    en: "Add Position",
    id: "Tambah Jabatan",
  },
  {
    en: "Update Position",
    id: "Ubah Jabatan",
  },
  {
    en: "Delete Position",
    id: "Hapus Jabatan",
  },
  {
    en: "Are you sure you want to delete position? Deleted Position cannot be undone.",
    id: "Anda yakin menghapus jabatan? Jabatan yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Position Successfully Deleted",
    id: "Jabatan Berhasil dihapus",
  },
  {
    en: "Failed to Delete Position",
    id: "Gagal Menghapus Jabatan",
  },
  {
    en: "Position successfully created",
    id: "Jabatan berhasil dibuat",
  },
  {
    en: "Failed to create position",
    id: "Gagal membuat jabatan",
  },
  {
    en: "Position successfully updated",
    id: "Jabatan berhasil diubah",
  },
  {
    en: "Failed to update position",
    id: "Gagal mengubah jabatan",
  },
  {
    en: "Congregation is required.",
    id: "Organisasi wajib diisi.",
  },
  {
    en: "Sub Congregation is required.",
    id: "Sub Organisasi wajib diisi.",
  },
  {
    en: "Select Congregation",
    id: "Pilih Organisasi",
  },
  {
    en: "Select Sub Congregation",
    id: "Pilih Sub Organisasi",
  },
  {
    en: "Try adding Position by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default positionLocaleConfig;
