// #region IMPORT
import urlcat from "urlcat";
import {
  IAgendaEventAutocompleteListApiRequest,
  IAgendaEventAutocompleteListApiResponse,
  IAgendaEventCreateApiRequest,
  IAgendaEventCreateApiResponse,
  IAgendaEventDeleteApiRequest,
  IAgendaEventDeleteApiResponse,
  IAgendaEventDetailApiRequest,
  IAgendaEventDetailApiResponse,
  IAgendaEventListApiRequest,
  IAgendaEventListApiResponse,
  IAgendaEventUpdateApiRequest,
  IAgendaEventUpdateApiResponse,
} from "../../../../model/agendaEvent/agendaEvent.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type AgendaEventEndpoint = {
  getAgendaEvent: QD<IAgendaEventListApiRequest, IAgendaEventListApiResponse>;
  getAgendaEventDetail: QD<
    IAgendaEventDetailApiRequest,
    IAgendaEventDetailApiResponse
  >;
  getAgendaEventAutocomplete: QD<
    IAgendaEventAutocompleteListApiRequest,
    IAgendaEventAutocompleteListApiResponse
  >;
  createAgendaEvent: MD<
    IAgendaEventCreateApiRequest,
    IAgendaEventCreateApiResponse
  >;
  updateAgendaEvent: MD<
    IAgendaEventUpdateApiRequest,
    IAgendaEventUpdateApiResponse
  >;
  deleteAgendaEvent: MD<
    IAgendaEventDeleteApiRequest,
    IAgendaEventDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const agendaEventTagTypes: IEndpointTags<AgendaEventEndpoint> = {
  getAgendaEvent: "get-agenda-event",
  getAgendaEventDetail: "get-agenda-event-detail",
  getAgendaEventAutocomplete: "get-agenda-event-autocomplete",
  createAgendaEvent: "create-agenda-event",
  updateAgendaEvent: "update-agenda-event",
  deleteAgendaEvent: "delete-agenda-event",
};

export const agendaEventEndpoint = (builder: Builder): AgendaEventEndpoint => ({
  getAgendaEvent: builder.query({
    query: (param) => urlcat("/agendas", param),
    providesTags: [agendaEventTagTypes.getAgendaEvent],
  }),
  getAgendaEventDetail: builder.query({
    query: ({ id }) => ({ url: `/agendas/${id}` }),
    providesTags: [agendaEventTagTypes.getAgendaEventDetail],
  }),
  getAgendaEventAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/agendas`, params),
    }),
    providesTags: [agendaEventTagTypes.getAgendaEventAutocomplete],
  }),
  createAgendaEvent: builder.mutation({
    query: (body) => ({
      url: "/agendas",
      method: "POST",
      body,
    }),
    invalidatesTags: [agendaEventTagTypes.getAgendaEvent],
  }),
  updateAgendaEvent: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/agendas/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [agendaEventTagTypes.getAgendaEvent],
  }),
  deleteAgendaEvent: builder.mutation({
    query: ({ id }) => ({
      url: `/agendas/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [agendaEventTagTypes.getAgendaEvent],
  }),
});
// #endregion
