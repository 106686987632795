import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IGroup } from "../../../../model/group/group.model";

export type GroupEditRouteParam = {
  group: IGroup;
};

export const groupEditRoute: RouteConfig = {
  name: "Edit Group",
  Component: React.lazy(() => import("./GroupEdit.view")),
  path: "/user-management/region/:regionId/group/edit/:id",
  isPrivate: true,
};
