import React from "react";
import tw, { styled } from "twin.macro";
import { ExtraStyle } from "../../Type.component";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator.atom";
import { HeadingFive, buttonLarge } from "../Text/Text.atom";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  testID?: string;
  ref?: React.RefObject<HTMLButtonElement>;
  disabled?: boolean;
  hasOutline?: boolean;
  icon?: React.ReactNode;
  small?: boolean;
  fullwidth?: boolean;
  loading?: boolean;
  feature?: "flat" | "rounded";
};

const baseButton = tw`rounded items-center justify-center inline-flex select-none cursor-pointer duration-200 gap-2`;
const buttonSizes = {
  small: tw`h-9 box-border py-2 px-4`,
  medium: tw`h-11 box-border py-3 px-4`,
};
const buttonFullwidth = tw`w-full`;

// #region SOLID BUTTON

const solidButton = tw`border-0 text-white bg-primary-500 hover:bg-primary-400 `;
const solidButtonDisabled = tw`bg-gray-300 cursor-default text-white`;
const SolidButton = styled.button(
  ({ icon, small, disabled, fullwidth }: Props) => [
    baseButton,
    buttonLarge,
    fullwidth && buttonFullwidth,
    small ? buttonSizes.small : buttonSizes.medium,
    !disabled && solidButton,
    disabled && solidButtonDisabled,
    icon && !fullwidth && tw`pl-2`,
  ]
);

export function Solid({ icon, loading, disabled, children, ...props }: Props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SolidButton disabled={disabled || loading} icon={icon} {...props}>
      {icon}
      {children}
      {loading && <LoadingIndicator color="white" size="small" />}
    </SolidButton>
  );
}

// #endregion

// #region OUTLINED BUTTON

const outlinedButton = tw`text-primary-500 box-border border border-solid border-primary-500 bg-transparent hover:(text-primary-400 border-primary-400)`;
const outlinedButtonDisabled = tw`border border-solid border-gray-300 text-gray-300 cursor-default`;
const OutlinedButton = styled.button(
  ({ icon, small, disabled, fullwidth }: Props) => [
    baseButton,
    buttonLarge,
    fullwidth && buttonFullwidth,
    small ? buttonSizes.small : buttonSizes.medium,
    !disabled && outlinedButton,
    disabled && outlinedButtonDisabled,
    icon && !fullwidth && tw`pl-2`,
  ]
);

export function Outlined({
  icon,
  loading,
  disabled,
  children,
  ...props
}: Props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <OutlinedButton disabled={disabled || loading} icon={icon} {...props}>
      {icon}
      {children}
      {loading && <LoadingIndicator size="small" />}
    </OutlinedButton>
  );
}

// #endregion

// #region TEXT BUTTON

const textButton = tw`text-primary-500 border-none bg-transparent hover:(text-primary-400)`;
const textButtonDisabled = tw`text-gray-300 cursor-default`;
const TextButton = styled.button(
  ({ icon, small, disabled, fullwidth }: Props) => [
    baseButton,
    buttonLarge,
    fullwidth && buttonFullwidth,
    small ? buttonSizes.small : buttonSizes.medium,
    !disabled && textButton,
    disabled && textButtonDisabled,
    icon && !fullwidth && tw`pl-2`,
  ]
);

export function Text({ icon, loading, disabled, children, ...props }: Props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextButton disabled={disabled || loading} icon={icon} {...props}>
      {icon}
      {children}
      {loading && <LoadingIndicator size="small" />}
    </TextButton>
  );
}

// #endregion

// #region ICON BUTTON

type IconButtonProps = {
  label: string;
  icon: JSX.Element;
  action: () => void;
  isDisabled?: boolean;
  hasOutline?: boolean;
  containerStyle?: ExtraStyle;
};

const IconButtonWrapper = styled.div(({ disabled, hasOutline }: Props) => [
  baseButton,
  !disabled && hasOutline && outlinedButton,
  disabled && hasOutline && outlinedButtonDisabled,
]);
const IconButtonContainer = tw.div`inline-grid [grid-template-columns:26px_1fr] gap-2 w-max`;
const IconContainer = styled.div`
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export function Icon({
  label,
  icon,
  action,
  isDisabled,
  hasOutline,
  containerStyle,
}: IconButtonProps) {
  return (
    <IconButtonWrapper
      disabled={isDisabled}
      hasOutline={hasOutline}
      css={containerStyle}
      onClick={!isDisabled ? action : undefined}
    >
      <Text disabled={isDisabled} tw="text-primary-500">
        <IconButtonContainer>
          <IconContainer
            css={[tw`flex items-center`, isDisabled && tw`text-grey-four`]}
          >
            {icon}
          </IconContainer>
          <HeadingFive
            css={[tw`text-primary-500`, isDisabled && tw`text-grey-four`]}
          >
            {label}
          </HeadingFive>
        </IconButtonContainer>
      </Text>
    </IconButtonWrapper>
  );
}

// #endregion
