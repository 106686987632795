// #region INTERFACES
export enum BulkActionStatusEnum {
  WAITING = 'WAITING',
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED',
}

export type BulkActionEntity<T> = T & {
  _actionDetail: string;
  _actionStatus: BulkActionStatusEnum;
};

// #endregion

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PAGE_LIMIT_OPTIONS = ['25', '50', '100'];
