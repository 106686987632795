import { Locale } from "../locale.config";

const announcementsLocaleConfig: Locale[] = [
  {
    en: "Announcements",
    id: "Pengumuman",
  },
  {
    en: "Title",
    id: "Judul",
  },
  {
    en: "Title is required",
    id: "Judul wajib diisi",
  },
  {
    en: "Action",
    id: "Aksi",
  },
  {
    en: "Add Announcements",
    id: "Tambah Pengumuman",
  },
  {
    en: "Update Announcements",
    id: "Ubah Pengumuman",
  },
  {
    en: "Date",
    id: "Tanggal",
  },
  {
    en: "Images",
    id: "Gambar",
  },
  {
    en: "Images is required.",
    id: "Gambar wajib diisi.",
  },
  {
    en: "Title is required.",
    id: "Judul wajib diisi.",
  },
  {
    en: "Select Date",
    id: "Pilih Tanggal",
  },
  {
    en: "Max size 2 MB.",
    id: "Ukuran Maksimal 2 MB.",
  },
  {
    en: "Content is required.",
    id: "Konten wajib diisi.",
  },
  {
    en: "Date is required.",
    id: "Tanggal wajib diisi.",
  },
  {
    en: "Content",
    id: "Konten",
  },
  {
    en: "Announcements successfully updated",
    id: "Pengumuman berhasil diubah",
  },
  {
    en: "Announcements successfully created",
    id: "Pengumuman berhasil dibuat",
  },
  {
    en: "Failed to update announcements",
    id: "Gagal mengubah pengumuman",
  },
  {
    en: "Failed to create announcements",
    id: "Gagal membuat pengumuman",
  },
  {
    en: "Delete Announcements",
    id: "Hapus Pengumuman",
  },
  {
    en: "Are you sure you want to delete announcements? Deleted Announcements cannot be undone.",
    id: "Anda yakin menghapus pengumuman? Pengumuman yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Announcements Successfully Deleted",
    id: "Pengumuman Berhasil Dihapus",
  },
  {
    en: "Failed to Delete Announcements",
    id: "Gagal Menghapus pengumuman",
  },
  {
    en: "De-activate Announcements",
    id: "Nonaktifkan Pengumuman",
  },
  {
    en: "Are you sure you want to De-activate announcements?",
    id: "Apakah anda yakin akan Menonaktifkan Pengumuman?",
  },
  {
    en: "Activate Announcements",
    id: "Aktifkan Pengumuman",
  },
  {
    en: "Announcements Successfully De-activate",
    id: "Pengumuman berhasil di Nonaktifkan.",
  },
  {
    en: "Announcements Successfully Activate",
    id: "Pengumuman berhasil di Aktifkan.",
  },
  {
    en: "Failed to De-activate Announcements",
    id: "Gagal Menonaktifkan Pengumuman.",
  },
  {
    en: "Failed to Activate Announcements",
    id: "Ggal Mengaktifkan Pengumuman.",
  },
  {
    en: "Activate",
    id: "Aktifkan",
  },
  {
    en: "De-activate",
    id: "Nonaktifkan",
  },
  {
    en: "Are you sure you want to Activate announcements?",
    id: "Apakah anda yakin akan Mengaktifkan Pengumuman?",
  },
];

export default announcementsLocaleConfig;
