import { Locale } from "../locale.config";

const reservationsLocaleConfig: Locale[] = [
  {
    en: "Reservation",
    id: "Peminjaman",
  },
  {
    en: "Location Name",
    id: "Nama Lokasi",
  },
  {
    en: "Add Reservation",
    id: "Tambah Peminjaman",
  },
  {
    en: "Location and Date",
    id: "Lokasi dan Tanggal",
  },
  {
    en: "Reservation Time",
    id: "Waktu Reservasi",
  },
  {
    en: "PIC Name",
    id: "Nama Penanggung Jawab",
  },
  {
    en: "PIC Phone Number",
    id: "Nomor Telepon Penanggung Jawab",
  },
  {
    en: "Update Reservation",
    id: "Ubah Peminjaman",
  },
  {
    en: "Operational Time",
    id: "Jam Operasional",
  },
  {
    en: "Detail Reservation",
    id: "Detail Peminjaman",
  },
  {
    en: "Reservator",
    id: "Identitas Peminjam",
  },
  {
    en: "Reservation Type",
    id: "Tipe Peminjaman",
  },
  {
    en: "Reservation Type is required.",
    id: "Tipe Peminjaman wajib diisi.",
  },
  {
    en: "Select Reservation Type",
    id: "Pilih Tipe Peminjaman",
  },
  {
    en: "Reservation Time",
    id: "Waktu Peminjaman",
  },
  {
    en: "Reservation Reason",
    id: "Alasan Peminjaman",
  },
  {
    en: "Reservation Description",
    id: "Keterangan",
  },
  {
    en: "Notes",
    id: "Catatan",
  },
  {
    en: "Photos",
    id: "Foto",
  },
  {
    en: "Done",
    id: "Selesai",
  },
  {
    en: "Open Time",
    id: "Waktu Buka",
  },
  {
    en: "Open Time - Close Time",
    id: "Waktu Buka - Waktu Tutup",
  },
  {
    en: "Order Status",
    id: "Status Pemesanan",
  },
  {
    en: "Bookable",
    id: "Dapat Dipinjam",
  },
  {
    en: "Not Bookable",
    id: "Tidak Dapat Dipinjam",
  },
  {
    en: "Operational Day (Select All Operational Day)",
    id: "Hari Operasional (Pilih Semua Hari Di mana Peminjaman Beroperasi)",
  },
  {
    en: "Monday",
    id: "Senin",
  },
  {
    en: "Tuesday",
    id: "Selasa",
  },
  {
    en: "Wednesday",
    id: "Rabu",
  },
  {
    en: "Thursday",
    id: "Kamis",
  },
  {
    en: "Friday",
    id: "Jumat",
  },
  {
    en: "Saturday",
    id: "Sabtu",
  },
  {
    en: "Sunday",
    id: "Minggu",
  },
  {
    en: "Google Maps Link",
    id: "Link Google Maps",
  },
  {
    en: "PIC Email",
    id: "Email Penanggung Jawab",
  },
  {
    en: "Reservation successfully updated",
    id: "Peminjaman berhasil diubah",
  },
  {
    en: "Reservation Successfully Approved",
    id: "Peminjaman Berhasil Disetujui",
  },
  {
    en: "Reservation successfully created",
    id: "Peminjaman berhasil dibuat",
  },
  {
    en: "Failed to update reservation",
    id: "Gagal mengubah peminjaman",
  },
  {
    en: "Failed to create reservation",
    id: "Gagal membuat peminjaman",
  },
  {
    en: "Reject Reservation",
    id: "Tolak Pengajuan Peminjaman",
  },
  {
    en: "Approve Reservation",
    id: "Approval Pengajuan Peminjaman",
  },
  {
    en: "Are you sure you want to approve Reservation? Approved Reservation cannot be undone.",
    id: "Anda yakin menyetujui pengajuan Peminjaman? Pengajuan Peminjaman yang disetujui tidak dapat diubah kembali.",
  },
  {
    en: "Are you sure you want to reject Reservation?",
    id: "Anda yakin menolak pengajuan Peminjaman?",
  },
  {
    en: "Rejected Reservation cannot be undone.",
    id: "Pengajuan Peminjaman yang ditolak tidak dapat diubah kembali.",
  },
  {
    en: "Can not input more than 255 characters",
    id: "Max 255 karakter",
  },
  {
    en: "Reservation Successfully Deleted",
    id: "Peminjaman Berhasil Dihapus",
  },
  {
    en: "Failed to Delete Reservation",
    id: "Gagal Menghapus peminjaman",
  },
  {
    en: "Are you sure you want to delete reservation? Deleted Reservation cannot be undone.",
    id: "Anda yakin menghapus peminjaman? Peminjaman yang dihapus tidak dapat diubah kembali.",
  },
];

export default reservationsLocaleConfig;
