import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type AgendaRolesCreateRouteParam = DefaultRouteParam;

export const agendaRolesCreateRoute: RouteConfig = {
  name: "Create Agenda Roles",
  Component: React.lazy(() => import("./AgendaRolesCreate.view")),
  path: "/agenda/roles/create",
  isPrivate: true,
};
