import { coreModuleName } from '@reduxjs/toolkit/dist/query/core/module';
import { reactHooksModuleName } from '@reduxjs/toolkit/dist/query/react/module';
import { Api, createApi } from '@reduxjs/toolkit/query/react';
import generateTagsArray from '../../util/api/helperApi.util';
import baseQuery, { BaseQuery } from './api.baseQuery';
import endpoint, { IEndpoint } from './api.endpoint';
import { authTagTypes } from './endpoint/auth/auth.endpoint';

type ApiModule = Api<
  BaseQuery,
  IEndpoint,
  'api',
  string,
  typeof coreModuleName | typeof reactHooksModuleName
>;

export type EndpointTagTypes<T> = Record<keyof T, string>;

const api: ApiModule = createApi({
  reducerPath: 'api',
  tagTypes: generateTagsArray(
    authTagTypes,
  ),
  baseQuery,
  endpoints: endpoint,
});

export default api;
