import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type PositionCreateRouteParam = DefaultRouteParam;

export const positionCreateRoute: RouteConfig = {
  name: "Create position",
  Component: React.lazy(() => import("./PositionCreate.view")),
  path: "/administration/position/create",
  isPrivate: true,
};
