import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type AnnouncementsRouteParam = DefaultRouteParam;

export const announcementsRoute: RouteConfig = {
  name: "Announcements",
  Component: React.lazy(() => import("./Announcements.view")),
  path: "/master-data/announcements",
  isPrivate: true,

  options: {
    label: "Announcements",
    testID: "MenuAnnouncements",
  },
};
