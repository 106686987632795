import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { ISacramentTNC } from "../../../../model/sacrament/sacramentTNC.model";

export type SacramentTNCEditRouteParam = {
  sacramentTNC: ISacramentTNC;
};

export const sacramentTNCEditRoute: RouteConfig = {
  name: "Edit Sacrament TNC",
  Component: React.lazy(() => import("./SacramentTNCEdit.view")),
  path: "/master-data/sacrament-tnc/edit/:type",
  isPrivate: true,
};
