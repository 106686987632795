import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  viewBox?: string;
};

export default function Dashboard({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = '#4D4D4D',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g mask="url(#mask0_49_2240)">
        <path
          fill={fill}
          d="M12 22a9.676 9.676 0 01-3.875-.788 10.144 10.144 0 01-3.188-2.15 10.142 10.142 0 01-2.15-3.187A9.676 9.676 0 012 12c0-1.383.263-2.68.788-3.887a10.183 10.183 0 012.15-3.175 10.143 10.143 0 013.187-2.15A9.676 9.676 0 0112 2c1.383 0 2.68.263 3.887.788a10.183 10.183 0 013.175 2.15 10.184 10.184 0 012.15 3.175A9.649 9.649 0 0122 12v1.45c0 .983-.337 1.82-1.012 2.513C20.313 16.654 19.483 17 18.5 17c-.6 0-1.158-.133-1.675-.4a3.754 3.754 0 01-1.275-1.05A4.969 4.969 0 0112 17c-1.383 0-2.563-.488-3.537-1.463C7.488 14.563 7 13.383 7 12s.487-2.563 1.463-3.537C9.438 7.488 10.617 7 12 7s2.563.487 3.537 1.463C16.512 9.438 17 10.617 17 12v1.45c0 .483.15.863.45 1.138.3.275.65.412 1.05.412s.75-.137 1.05-.412c.3-.275.45-.655.45-1.138V12c0-2.183-.788-4.063-2.363-5.638C16.063 4.787 14.183 4 12 4c-2.183 0-4.063.787-5.638 2.362C4.787 7.938 4 9.817 4 12s.787 4.063 2.362 5.637C7.938 19.212 9.817 20 12 20h5v2h-5zm0-7c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0015 12c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0012 9c-.833 0-1.542.292-2.125.875A2.893 2.893 0 009 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 0012 15z"
        />
      </g>
    </svg>
  );
}
