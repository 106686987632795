import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type AgendaEventCreateRouteParam = DefaultRouteParam;

export const agendaEventCreateRoute: RouteConfig = {
  name: "Create Agenda Event",
  Component: React.lazy(() => import("./AgendaEventCreate.view")),
  path: "/agenda/event/create",
  isPrivate: true,
};
