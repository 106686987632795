import { useLocation } from "react-router-dom";
import useToggle from "../../../../hook/common/useToggle/useToggle.hook";
import useTranslator from "../../../../hook/common/useTranslator/useTranslator.hook";
import useLocaleUpdate from "../../../../hook/wrapper/useLocaleUpdate/useLocaleUpdate.hook";
import useSession from "../../../../hook/wrapper/useSession/useSession.hook";
import useSidebar from "../../../../hook/wrapper/useSidebar/useSidebar.hook";

export default function useWrapperViewModel() {
  const location = useLocation();
  // #region REQUIRED DATA
  const [isModalSettingShown, toggleModalSetting] = useToggle(false);
  // #endregion

  // #region LOCALE
  const translator = useTranslator();
  useLocaleUpdate();
  // #endregion

  // #region SESSION
  const session = useSession();
  // #endregion

  // #region SIDEBAR
  const sidebar = useSidebar();
  // #endregion

  return {
    sidebar,
    location,
    session,
    translator,
    isModalSettingShown,
    toggleModalSetting,
  };
}
