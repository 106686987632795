import { Locale } from "../locale.config";

const groupLocaleConfig: Locale[] = [
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Name is required.",
    id: "Nama wajib diisi",
  },
  {
    en: "Group",
    id: "Kelompok",
  },
  {
    en: "Group Detail",
    id: "Detail Kelompok",
  },
  {
    en: "Add Group",
    id: "Tambah Kelompok",
  },
  {
    en: "Update Group",
    id: "Ubah Kelompok",
  },
  {
    en: "Delete Group",
    id: "Hapus Kelompok",
  },
  {
    en: "Are you sure you want to delete group? Deleted Group cannot be undone.",
    id: "Anda yakin menghapus kelompok? Kelompok yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Group Successfully Deleted",
    id: "Kelompok Berhasil dihapus",
  },
  {
    en: "Failed to Delete Group",
    id: "Gagal Menghapus Kelompok",
  },
  {
    en: "Group successfully created",
    id: "Kelompok berhasil dibuat",
  },
  {
    en: "Failed to create group",
    id: "Gagal membuat kelompok",
  },
  {
    en: "Group successfully updated",
    id: "Kelompok berhasil diubah",
  },
  {
    en: "Failed to update group",
    id: "Gagal mengubah kelompok",
  },
  {
    en: "Try adding Group by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default groupLocaleConfig;
