import React, { useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import tw, { styled, theme } from "twin.macro";
import { RootState } from "../../../../../../app/store/store.app";
import profileImage from "../../../../../../assets/images/profile.png";
import useTranslator from "../../../../../../hook/common/useTranslator/useTranslator.hook";
import { Button } from "../../../../../atom/Button";
import Logout from "../../../../../atom/Icon/SVG/Logout";
import Settings from "../../../../../atom/Icon/SVG/Settings";
import IconButton from "../../../../../atom/IconButton/IconButton.atom";
import Image from "../../../../../atom/Image/Image.atom";
import OutsideAlerter from "../../../../../atom/OutsideAlerter/OutsideAlerter.atom";
import Popover from "../../../../../atom/Popover/Popover.atom";
import {
  ButtonLarge,
  ButtonSmall,
  Caption,
} from "../../../../../atom/Text/Text.atom";
import Tooltip from "../../../../../molecule/Tooltip/Tooltip.molecule";
import useProfile from "../../hook/useProfile.hook";

// #region STYLES
const Container = tw.div`px-[18px] py-3.5 mt-2 border-t border-t-gray-100`;

const ProfileContainer = styled.div(({ expanded }: { expanded?: boolean }) => [
  tw`w-full flex gap-2.5 items-center  border-t-gray-50 cursor-pointer rounded hover:(bg-primary-300 bg-opacity-25)`,
  !expanded && tw`hover:(bg-transparent)`,
  !expanded &&
    `
  #profile-meta{
    display: none
  }
  `,
]);

const ContentContainer = tw.div` w-full box-border flex items-center justify-between`;
const Content = tw.div`max-w-[120px] box-border`;
const ContentName = tw.div`truncate flex`;
const ContentEmail = tw.div`truncate max-w-[120px]`;

const PopupContent = tw.div`px-3.5 py-2.5 gap-2.5 shadow-card flex flex-col rounded w-60 [height:120px] bg-white`;
const Divider = tw.hr`border-gray-100`;
const ButtonLogout = tw(
  Button.Text
)` pl-3 py-2 flex justify-start w-full hover:(bg-primary-300 bg-opacity-25)`;

// #endregion

export default function ProfileSection({ expanded }: { expanded?: boolean }) {
  const tooltipRef = useRef(null);
  const translator = useTranslator();
  const { name, email } = useSelector(
    (state: RootState) => state.session,
    shallowEqual
  );
  const [visible, setVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { handleClickSetting, handleClickLogout } = useProfile();

  return (
    <Container>
      <div tw="flex flex-1 justify-between gap-2">
        <ProfileContainer
          ref={tooltipRef}
          onMouseEnter={() => {
            if (!expanded) setVisible(true);
          }}
          onMouseLeave={() => {
            if (!expanded) setVisible(false);
          }}
          onClick={() => setShowPopup(true)}
          expanded={expanded}
        >
          <Image
            onClick={() => setShowPopup(true)}
            src={profileImage}
            tw="w-10 h-10 aspect-square p-0.5 rounded-md cursor-pointer hover:(border border-primary-500)"
          />
          {expanded && (
            <>
              <ContentContainer>
                <Content id="profile-meta">
                  <ContentName>
                    <ButtonSmall tw="truncate">{email || "email"}</ButtonSmall>
                  </ContentName>
                  <ContentEmail>
                    <Caption tw="truncate">{name || "name"}</Caption>
                  </ContentEmail>
                </Content>
              </ContentContainer>
              <Tooltip
                containerStyle={[tw`z-[2]`]}
                targetRef={tooltipRef}
                visible={visible}
                contentStyle={tw`p-2 flex`}
                variant="dark"
                placement="right"
                withPortal
              >
                <Caption tw="text-gray-50">Menu Profile</Caption>
              </Tooltip>
            </>
          )}
        </ProfileContainer>
        {expanded && (
          <IconButton onClick={handleClickSetting}>
            <Settings />
          </IconButton>
        )}
      </div>

      <Popover offset={[20, -40]} visible={showPopup} targetRef={tooltipRef}>
        <OutsideAlerter onClickAway={() => setShowPopup(false)}>
          <PopupContent>
            <ContentContainer>
              <div tw="flex gap-2.5">
                <Image
                  src={profileImage}
                  tw="w-10 h-10 aspect-square p-0.5 rounded-md"
                />
                <Content>
                  <ContentName>
                    <ButtonSmall tw="!flex truncate">
                      {email || "email"}
                    </ButtonSmall>
                  </ContentName>
                  <ContentEmail>
                    <Caption tw="!flex truncate">{name || "name"}</Caption>
                  </ContentEmail>
                </Content>
              </div>
            </ContentContainer>
            <Divider />
            <ButtonLogout
              onClick={handleClickLogout}
              icon={<Logout fillPath={theme`colors.gray.500`} />}
            >
              <ButtonLarge tw="text-gray-500 !flex">
                {translator.translate("Logout")}
              </ButtonLarge>
            </ButtonLogout>
          </PopupContent>
        </OutsideAlerter>
      </Popover>
    </Container>
  );
}
