import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/store.app";
import localeConfig, {
  SupportedLanguage,
} from "../../../service/locale/locale.config";

export type UseTranslator = {
  currentLanguage: SupportedLanguage;
  translate(key: string): string;
};

export default function useTranslator(): UseTranslator {
  // #region REQUIRED STATE
  const currentLanguage = useSelector(
    (state: RootState) => state.setting.currentLanguage
  );
  // #endregion

  // #region TRANSLATE
  const translate = useCallback(
    (key: string): string => {
      const translationIndex = localeConfig.findIndex(
        (find) => find.en === key
      );

      if (
        translationIndex >= 0 &&
        localeConfig[translationIndex][currentLanguage] !== null
      ) {
        return localeConfig[translationIndex][currentLanguage] || key;
      }

      return key;
    },
    [currentLanguage]
  );
  // #endregion

  return {
    currentLanguage,
    translate,
  };
}
