import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IReflections } from "../../../../model/reflections/reflections.model";

export type ReflectionsEditRouteParam = {
  reflections: IReflections;
};

export const reflectionsEditRoute: RouteConfig = {
  name: "Edit Reflections",
  Component: React.lazy(() => import("./ReflectionsEdit.view")),
  path: "/master-data/reflections/edit/:id",
  isPrivate: true,
};
