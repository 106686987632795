import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";

export type RegionDetailRouteParam = {
  regionId: string;
};

export const regionDetailRoute: RouteConfig = {
  name: "Region Detail",
  Component: React.lazy(() => import("./RegionDetail.view")),
  path: "/user-management/region/detail/:id",
  isPrivate: true,

  options: {
    label: "RegionDetail",
    testID: "MenuRegionDetail",
  },
};
