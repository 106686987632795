import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IPlaceReservation } from "../../../../model/placeReservation/placeReservation.model";

export type PlaceReservationEditRouteParam = {
  placeReservation: IPlaceReservation;
};

export const placeReservationEditRoute: RouteConfig = {
  name: "Edit Place Reservation",
  Component: React.lazy(() => import("./PlaceReservationEdit.view")),
  path: "/location/reservation/edit/:id",
  isPrivate: true,
};
