import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function ChevronSharp({
  fill = 'none',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  strokeWidth = 2,
  stroke = 'currentColor',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      strokeWidth={strokeWidth}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.5 18.5L15.5 11.5L8.5 4.5" stroke={stroke} />
    </svg>
  );
}
