import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type AgendaAssignmentRouteParam = DefaultRouteParam;

export const agendaAssignmentRoute: RouteConfig = {
  name: "Agenda Assignment",
  Component: React.lazy(() => import("./AgendaAssignment.view")),
  path: "/agenda/assignment/",
  isPrivate: true,

  options: {
    label: "Agenda Assignment",
    testID: "MenuAgendaAssignment",
  },
};
