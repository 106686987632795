// #region IMPORT
import urlcat from "urlcat";
import {
  ICommitteeCreateApiRequest,
  ICommitteeCreateApiResponse,
  ICommitteeDeleteApiRequest,
  ICommitteeDeleteApiResponse,
  ICommitteeListApiRequest,
  ICommitteeListApiResponse,
  ICommitteeUpdateApiRequest,
  ICommitteeUpdateApiResponse,
  ICommitteeUpdateStatusRequest,
  ICommitteeUpdateStatusResponse,
} from "../../../../model/committee/committee.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type CommitteeEndpoint = {
  getCommittee: QD<ICommitteeListApiRequest, ICommitteeListApiResponse>;
  createCommittee: MD<ICommitteeCreateApiRequest, ICommitteeCreateApiResponse>;
  updateCommittee: MD<ICommitteeUpdateApiRequest, ICommitteeUpdateApiResponse>;
  activateCommittee: MD<
    ICommitteeUpdateStatusRequest,
    ICommitteeUpdateStatusResponse
  >;
  inactivateCommittee: MD<
    ICommitteeUpdateStatusRequest,
    ICommitteeUpdateStatusResponse
  >;
  deleteCommittee: MD<ICommitteeDeleteApiRequest, ICommitteeDeleteApiResponse>;
};
// #endregion

// #region ENDPOINT
export const committeeTagTypes: IEndpointTags<CommitteeEndpoint> = {
  getCommittee: "get-committee",
  createCommittee: "create-committee",
  updateCommittee: "update-committee",
  activateCommittee: "activate-committee",
  inactivateCommittee: "inactivate-committee",
  deleteCommittee: "delete-committee",
};

export const committeeEndpoint = (builder: Builder): CommitteeEndpoint => ({
  getCommittee: builder.query({
    query: (param) => urlcat("/committees", param),
    providesTags: [committeeTagTypes.getCommittee],
  }),
  createCommittee: builder.mutation({
    query: (body) => ({
      url: "/committees",
      method: "POST",
      body,
    }),
    invalidatesTags: [committeeTagTypes.getCommittee],
  }),
  updateCommittee: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/committees/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [committeeTagTypes.getCommittee],
  }),
  activateCommittee: builder.mutation({
    query: ({ id: committeeId }) => ({
      url: `/committees/${committeeId}/active`,
      method: "PUT",
    }),
    invalidatesTags: [committeeTagTypes.getCommittee],
  }),
  inactivateCommittee: builder.mutation({
    query: ({ id: committeeId }) => ({
      url: `/committees/${committeeId}/inactive`,
      method: "PUT",
    }),
    invalidatesTags: [committeeTagTypes.getCommittee],
  }),
  deleteCommittee: builder.mutation({
    query: ({ id }) => ({
      url: `/committees/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [committeeTagTypes.getCommittee],
  }),
});
// #endregion
