import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  viewBox?: string;
};

export default function Announcement({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = '#4D4D4D',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g mask="url(#mask0_49_2270)">
        <path
          fill={fill}
          d="M18 13v-2h4v2h-4zm1.2 7L16 17.6l1.2-1.6 3.2 2.4-1.2 1.6zm-2-12L16 6.4 19.2 4l1.2 1.6L17.2 8zM5 19v-4H4c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 012 13v-2c0-.55.196-1.02.587-1.412A1.926 1.926 0 014 9h4l5-3v12l-5-3H7v4H5zm9-3.65v-6.7c.45.4.813.887 1.088 1.463.275.575.412 1.204.412 1.887s-.137 1.313-.412 1.887A4.567 4.567 0 0114 15.35zM4 11v2h4.55L11 14.45v-4.9L8.55 11H4z"
        />
      </g>
    </svg>
  );
}
