import { Locale } from "../locale.config";

const congregationLocaleConfig: Locale[] = [
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Name is required.",
    id: "Nama wajib diisi",
  },
  {
    en: "Congregation",
    id: "Organisasi",
  },
  {
    en: "Congregation Detail",
    id: "Detail Organisasi",
  },
  {
    en: "Add Congregation",
    id: "Tambah Organisasi",
  },
  {
    en: "Update Congregation",
    id: "Ubah Organisasi",
  },
  {
    en: "Delete Congregation",
    id: "Hapus Organisasi",
  },
  {
    en: "Are you sure you want to delete congregation? Deleted Congregation cannot be undone.",
    id: "Anda yakin menghapus organisasi? Organisasi yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Congregation Successfully Deleted",
    id: "Organisasi Berhasil dihapus",
  },
  {
    en: "Failed to Delete Congregation",
    id: "Gagal Menghapus Organisasi",
  },
  {
    en: "Congregation successfully created",
    id: "Organisasi berhasil dibuat",
  },
  {
    en: "Failed to create congregation",
    id: "Gagal membuat organisasi",
  },
  {
    en: "Congregation successfully updated",
    id: "Organisasi berhasil diubah",
  },
  {
    en: "Failed to update congregation",
    id: "Gagal mengubah organisasi",
  },
  {
    en: "Try adding Congregation by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default congregationLocaleConfig;
