import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IAnnouncements } from "../../../../model/announcements/announcements.model";

export type AnnouncementsEditRouteParam = {
  announcements: IAnnouncements;
};

export const announcementsEditRoute: RouteConfig = {
  name: "Edit Announcements",
  Component: React.lazy(() => import("./AnnouncementsEdit.view")),
  path: "/master-data/announcements/edit/:id",
  isPrivate: true,
};
