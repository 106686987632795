import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";

export type CongregationDetailRouteParam = {
  congregationId: string;
};

export const congregationDetailRoute: RouteConfig = {
  name: "Congregation Detail",
  Component: React.lazy(() => import("./CongregationDetail.view")),
  path: "/administration/congregation/detail/:id",
  isPrivate: true,

  options: {
    label: "CongregationDetail",
    testID: "MenuCongregationDetail",
  },
};
