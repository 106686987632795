import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportedLanguage } from '../../../locale/locale.config';

export interface ISettingStore {
  currentLanguage: SupportedLanguage;
}

const initialState: ISettingStore = {
  currentLanguage: SupportedLanguage.id,
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<SupportedLanguage>) => ({
      ...state,
      currentLanguage: action.payload,
    }),
  },
});

export const settingAction = settingSlice.actions;
export const settingReducer = settingSlice.reducer;
