import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { ICongregation } from "../../../../model/congregation/congregation.model";

export type CongregationEditRouteParam = {
  congregation: ICongregation;
};

export const congregationEditRoute: RouteConfig = {
  name: "Edit Congregation",
  Component: React.lazy(() => import("./CongregationEdit.view")),
  path: "/administration/congregation/edit/:id",
  isPrivate: true,
};
