// #region IMPORT
import urlcat from "urlcat";
import {
  IAuthCheckResetPasswordParams,
  IAuthCheckResetPasswordResponse,
  IAuthForgetPasswordParams,
  IAuthForgetPasswordResponse,
  IAuthLoginParams,
  IAuthLoginResponse,
  IAuthResetPasswordParams,
  IAuthResetPasswordResponse,
} from "../../../../model/auth/auth.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type AuthEndpoint = {
  login: MD<IAuthLoginParams, IAuthLoginResponse>;
  checkResetPassword: QD<
    IAuthCheckResetPasswordParams,
    IAuthCheckResetPasswordResponse
  >;
  checkResetPasswordUser: QD<
    IAuthCheckResetPasswordParams,
    IAuthCheckResetPasswordResponse
  >;
  resetPassword: MD<IAuthResetPasswordParams, IAuthResetPasswordResponse>;
  resetPasswordUser: MD<IAuthResetPasswordParams, IAuthResetPasswordResponse>;
  forgetPassword: MD<IAuthForgetPasswordParams, IAuthForgetPasswordResponse>;
};
// #endregion

// #region ENDPOINT
export const authTagTypes: IEndpointTags<AuthEndpoint> = {
  login: "login",
  checkResetPassword: "check-reset-password",
  checkResetPasswordUser: "check-reset-password-user",
  resetPassword: "reset-password",
  resetPasswordUser: "reset-password-user",
  forgetPassword: "forget-password",
};

export const authEndpoint = (builder: Builder): AuthEndpoint => ({
  login: builder.mutation({
    query: (body) => ({
      url: "auth/admins",
      method: "POST",
      body,
    }),
  }),
  resetPassword: builder.mutation({
    query: (body) => ({
      url: "reset-password/admins/change",
      method: "POST",
      body,
    }),
  }),
  resetPasswordUser: builder.mutation({
    query: (body) => ({
      url: "reset-password/users/change",
      method: "POST",
      body,
    }),
  }),
  forgetPassword: builder.mutation({
    query: (body) => ({
      url: "reset-password/admins",
      method: "POST",
      body,
    }),
  }),
  checkResetPassword: builder.query({
    query: (params) => ({
      url: urlcat(`/reset-password/admins/check-token`, params),
    }),
    providesTags: [authTagTypes.checkResetPassword],
  }),
  checkResetPasswordUser: builder.query({
    query: (params) => ({
      url: urlcat(`/reset-password/users/check-token`, params),
    }),
    providesTags: [authTagTypes.checkResetPasswordUser],
  }),
});
// #endregion
