// #region IMPORT
import urlcat from "urlcat";
import {
  IOrganizationAutocompleteListApiRequest,
  IOrganizationAutocompleteListApiResponse,
  IOrganizationCreateApiRequest,
  IOrganizationCreateApiResponse,
  IOrganizationListApiRequest,
  IOrganizationListApiResponse,
  IOrganizationUpdateApiRequest,
  IOrganizationUpdateApiResponse,
} from "../../../../model/organization/organization.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type OrganizationEndpoint = {
  getOrganizations: QD<
    IOrganizationListApiRequest,
    IOrganizationListApiResponse
  >;
  getOrganizationsAutocomplete: QD<
    IOrganizationAutocompleteListApiRequest,
    IOrganizationAutocompleteListApiResponse
  >;
  createOrganization: MD<
    IOrganizationCreateApiRequest,
    IOrganizationCreateApiResponse
  >;
  updateOrganization: MD<
    IOrganizationUpdateApiRequest,
    IOrganizationUpdateApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const organizationTagTypes: IEndpointTags<OrganizationEndpoint> = {
  getOrganizations: "get-organizations",
  getOrganizationsAutocomplete: "get-organizations-autocomplete",
  createOrganization: "create-organization",
  updateOrganization: "update-organization",
};

export const organizationEndpoint = (
  builder: Builder
): OrganizationEndpoint => ({
  getOrganizations: builder.query({
    query: (param) => urlcat("/organizations", param),
    providesTags: [organizationTagTypes.getOrganizations],
  }),
  getOrganizationsAutocomplete: builder.query({
    query: (param) => urlcat("/auto-complete/organizations", param),
    providesTags: [organizationTagTypes.getOrganizationsAutocomplete],
  }),
  createOrganization: builder.mutation({
    query: (body) => ({
      url: "/organizations",
      method: "POST",
      body,
    }),
    invalidatesTags: [organizationTagTypes.getOrganizations],
  }),
  updateOrganization: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/organization/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [organizationTagTypes.getOrganizations],
  }),
});
// #endregion
