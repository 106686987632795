import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { ISubCongregation } from "../../../../model/subCongregation/subCongregation.model";

export type SubCongregationEditRouteParam = {
  subCongregation: ISubCongregation;
};

export const subCongregationEditRoute: RouteConfig = {
  name: "Edit SubCongregation",
  Component: React.lazy(() => import("./SubCongregationEdit.view")),
  path: "/administration/congregation/:congregationId/subCongregation/edit/:id",
  isPrivate: true,
};
