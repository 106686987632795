import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IAgendaRoles } from "../../../../model/agendaRoles/agendaRoles.model";

export type AgendaRolesEditRouteParam = {
  agendaRoles: IAgendaRoles;
};

export const agendaRolesEditRoute: RouteConfig = {
  name: "Edit Agenda Roles",
  Component: React.lazy(() => import("./AgendaRolesEdit.view")),
  path: "/agenda/roles/edit/:id",
  isPrivate: true,
};
