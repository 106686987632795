// #region IMPORT
import urlcat from "urlcat";
import {
  IPositionAutocompleteListApiRequest,
  IPositionAutocompleteListApiResponse,
  IPositionCreateApiRequest,
  IPositionCreateApiResponse,
  IPositionDeleteApiRequest,
  IPositionDeleteApiResponse,
  IPositionDetailApiRequest,
  IPositionDetailApiResponse,
  IPositionListApiRequest,
  IPositionListApiResponse,
  IPositionUpdateApiRequest,
  IPositionUpdateApiResponse,
} from "../../../../model/position/position.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type PositionEndpoint = {
  getPosition: QD<IPositionListApiRequest, IPositionListApiResponse>;
  getPositionsDetail: QD<IPositionDetailApiRequest, IPositionDetailApiResponse>;

  getPositionAutocomplete: QD<
    IPositionAutocompleteListApiRequest,
    IPositionAutocompleteListApiResponse
  >;
  createPosition: MD<IPositionCreateApiRequest, IPositionCreateApiResponse>;
  updatePosition: MD<IPositionUpdateApiRequest, IPositionUpdateApiResponse>;
  deletePosition: MD<IPositionDeleteApiRequest, IPositionDeleteApiResponse>;
};
// #endregion

// #region ENDPOINT
export const positionTagTypes: IEndpointTags<PositionEndpoint> = {
  getPosition: "get-position",
  getPositionsDetail: "get-positions-detail",
  getPositionAutocomplete: "get-position-autocomplete",
  createPosition: "create-position",
  updatePosition: "update-position",
  deletePosition: "delete-position",
};

export const positionEndpoint = (builder: Builder): PositionEndpoint => ({
  getPosition: builder.query({
    query: (param) => urlcat("/positions", param),
    providesTags: [positionTagTypes.getPosition],
  }),
  getPositionAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/positions`, params),
    }),
    providesTags: [positionTagTypes.getPositionAutocomplete],
  }),
  getPositionsDetail: builder.query({
    query: ({ id }) => ({
      url: `/positions/${id}`,
    }),
    providesTags: [positionTagTypes.getPositionsDetail],
  }),
  createPosition: builder.mutation({
    query: (body) => ({
      url: "/positions",
      method: "POST",
      body,
    }),
    invalidatesTags: [positionTagTypes.getPosition],
  }),
  updatePosition: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/positions/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [positionTagTypes.getPosition],
  }),
  deletePosition: builder.mutation({
    query: ({ id }) => ({
      url: `/positions/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [positionTagTypes.getPosition],
  }),
});
// #endregion
