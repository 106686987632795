// #region IMPORT
import urlcat from "urlcat";
import {
  IAnnouncementsCreateApiRequest,
  IAnnouncementsCreateApiResponse,
  IAnnouncementsDeleteApiRequest,
  IAnnouncementsDeleteApiResponse,
  IAnnouncementsDetailApiRequest,
  IAnnouncementsDetailApiResponse,
  IAnnouncementsListApiRequest,
  IAnnouncementsListApiResponse,
  IAnnouncementsUpdateApiRequest,
  IAnnouncementsUpdateApiResponse,
  IAnnouncementsUpdateStatusRequest,
  IAnnouncementsUpdateStatusResponse,
} from "../../../../model/announcements/announcements.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type AnnouncementsEndpoint = {
  getAnnouncements: QD<
    IAnnouncementsListApiRequest,
    IAnnouncementsListApiResponse
  >;
  getAnnouncementsDetail: QD<
    IAnnouncementsDetailApiRequest,
    IAnnouncementsDetailApiResponse
  >;
  createAnnouncements: MD<
    IAnnouncementsCreateApiRequest,
    IAnnouncementsCreateApiResponse
  >;
  updateAnnouncements: MD<
    IAnnouncementsUpdateApiRequest,
    IAnnouncementsUpdateApiResponse
  >;
  deleteAnnouncements: MD<
    IAnnouncementsDeleteApiRequest,
    IAnnouncementsDeleteApiResponse
  >;
  activateAnnouncements: MD<
    IAnnouncementsUpdateStatusRequest,
    IAnnouncementsUpdateStatusResponse
  >;
  inactivateAnnouncements: MD<
    IAnnouncementsUpdateStatusRequest,
    IAnnouncementsUpdateStatusResponse
  >;
};
// #endregion

// #region ENDPOINT
export const announcementsTagTypes: IEndpointTags<AnnouncementsEndpoint> = {
  getAnnouncements: "get-announcements",
  getAnnouncementsDetail: "get-announcements-detail",
  createAnnouncements: "create-announcements",
  updateAnnouncements: "update-announcements",
  deleteAnnouncements: "delete-announcements",
  activateAnnouncements: "activate-announcements",
  inactivateAnnouncements: "inactivate-announcements",
};

export const announcementsEndpoint = (
  builder: Builder
): AnnouncementsEndpoint => ({
  getAnnouncements: builder.query({
    query: (param) => urlcat("/announcement", param),
    providesTags: [announcementsTagTypes.getAnnouncements],
  }),
  getAnnouncementsDetail: builder.query({
    query: ({ id }) => ({
      url: `/announcement/${id}`,
    }),
    providesTags: [announcementsTagTypes.getAnnouncementsDetail],
  }),
  createAnnouncements: builder.mutation({
    query: (body) => ({
      url: "/announcement",
      method: "POST",
      body,
    }),
    invalidatesTags: [announcementsTagTypes.getAnnouncements],
  }),
  updateAnnouncements: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/announcement/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [
      announcementsTagTypes.getAnnouncements,
      announcementsTagTypes.getAnnouncementsDetail,
    ],
  }),
  deleteAnnouncements: builder.mutation({
    query: ({ id }) => ({
      url: `/announcement/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [announcementsTagTypes.getAnnouncements],
  }),
  activateAnnouncements: builder.mutation({
    query: ({ id }) => ({
      url: `/announcement/${id}/active`,
      method: "PUT",
    }),
    invalidatesTags: [announcementsTagTypes.getAnnouncements],
  }),
  inactivateAnnouncements: builder.mutation({
    query: ({ id }) => ({
      url: `/announcement/${id}/inactive`,
      method: "PUT",
    }),
    invalidatesTags: [announcementsTagTypes.getAnnouncements],
  }),
});
// #endregion
