import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IRoom } from "../../../../model/room/room.model";

export type RoomEditRouteParam = {
  room: IRoom;
};

export const roomEditRoute: RouteConfig = {
  name: "Edit Room",
  Component: React.lazy(() => import("./RoomEdit.view")),
  path: "/master-data/room/edit/:id",
  isPrivate: true,
};
