import { Box, Theme } from "@nivo/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LegendProps } from "@nivo/legends";
import { SVGProps } from "react";
import { theme } from "twin.macro";
import { ExtraStyle } from "../../component/Type.component";
import { OptionType } from "../../component/molecule/Select/Select.molecule";
import routerConfig from "../../config/router/router.config";
import {
  CommonLabelValue,
  ICommonApiListParams,
  SortDirection,
} from "../../model/common/common.model";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT_OPTIONS,
  DEFAULT_PAGE_SIZE,
} from "../table/Table.constant";

export const uploaderCSVFormKey = "file";
export const formatDateValues = "yyyyMMdd";

export enum RedirectUrlTarget {
  BLANK = "_blank",
  SELF = "_self",
}

export interface IRedirectUrl {
  target?: RedirectUrlTarget;
  url: string;
}

export const PUBLIC_ROUTE: string[] = routerConfig.reduce(
  (result: string[], config) => {
    if (!config.isPrivate) {
      result.push(config.path);
    }
    return result;
  },
  []
);

export const generateCommonListParams = ({
  page,
  size,
}: {
  page?: string;
  size?: string;
}): ICommonApiListParams => ({
  page: Number(page) || DEFAULT_PAGE,
  size: Number(size) || DEFAULT_PAGE_SIZE,
});

export const sortDirectionLabel = {
  [SortDirection.ASC]: "(A-Z)",
  [SortDirection.DESC]: "(Z-A)",
};

export const sortDirectionLongLabel = {
  [SortDirection.ASC]: "A-Z (Ascending)",
  [SortDirection.DESC]: "Z-A (Descending)",
};

export type FilterGroup = {
  label: string;
  options: CommonLabelValue[];
  handleSelect: (value: string) => void;
  handleSelectExecute: (value: string) => void;
  handleReset: () => void;
};

export interface ICommonGridPanelItem {
  id: string;
  icon?: React.ReactNode;
  title: string;
  subtitle?: string;
  description?: string;
  additionalInfo?: React.ReactNode;
  gridCol: number;
  panelStyle?: ExtraStyle;
}

export type IBreadcrumbs = {
  path?: string;
  title: string;
};

export const chartTheme: Theme = {
  legends: {
    text: {
      fontSize: 14,
      lineHeight: 20,
      fill: theme`colors.black`,
    },
  },
};

export const chartMargin: Box = {
  top: 20,
  right: 20,
  bottom: 60,
  left: 40,
};

export const chartLegend: LegendProps = {
  itemHeight: -80,
  itemWidth: 70,
  itemsSpacing: 10,
  direction: "row",
  anchor: "bottom",
  symbolShape: "circle",
  symbolSize: 10,
};

export const commonPaginationOptions: OptionType[] =
  DEFAULT_PAGE_LIMIT_OPTIONS.map((item) => ({
    label: item,
    value: item,
  }));

export type ICommonSVG = SVGProps<SVGSVGElement> & { fillPath?: string };

export const commonDateFormat = "dd/MM/yyyy";
export const commonDateTimeformat = `${commonDateFormat} HH:mm`;

export const commonDateTimezone = "Asia/Jakarta";
export const youtubeVideoRegex =
  /https?:\/\/(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/gi;
export const zoomLinkRegex = /https:\/\/[\w-]*\.?zoom.us\/(j|my)\/[\d\w?=-]+/gi;

export const googleMapsRegex =
  /https?:\/\/(www\.)?(google\.[a-z]{2,3}(\.[a-z]{2})?\/maps|maps\.app\.goo\.gl\/[^\s]+)/gi;

export const whatsappRegex = /^(\+62|62|0)8[1-9][0-9]{6,9}$/gi;
