import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type ReflectionsRouteParam = DefaultRouteParam;

export const reflectionsRoute: RouteConfig = {
  name: "Reflections",
  Component: React.lazy(() => import("./Reflections.view")),
  path: "/master-data/reflections",
  isPrivate: true,

  options: {
    label: "Reflections",
    testID: "MenuReflections",
  },
};
