import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  viewBox?: string;
};

export default function Warning({
  fill = '#F0F0F0',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
    >
      <path
        fill={fill}
        d="M.666 14L7.999 1.334 15.333 14H.666zm2.3-1.333h10.067L7.999 4l-5.033 8.667zM7.999 12a.645.645 0 00.475-.191.645.645 0 00.192-.475.645.645 0 00-.192-.476.645.645 0 00-.475-.191.645.645 0 00-.475.191.645.645 0 00-.191.476c0 .188.064.347.191.475A.645.645 0 008 12zm-.666-2h1.333V6.667H7.333V10z"
      />
    </svg>
  );
}
