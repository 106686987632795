import { Locale } from "../locale.config";

const subCongregationLocaleConfig: Locale[] = [
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Name is required.",
    id: "Nama wajib diisi",
  },
  {
    en: "Sub Congregation",
    id: "Sub Organisasi",
  },
  {
    en: "Sub Congregation Detail",
    id: "Detail Sub Organisasi",
  },
  {
    en: "Add Sub Congregation",
    id: "Tambah Sub Organisasi",
  },
  {
    en: "Update Sub Congregation",
    id: "Ubah Sub Organisasi",
  },
  {
    en: "Delete Sub Congregation",
    id: "Hapus Sub Organisasi",
  },
  {
    en: "Are you sure you want to delete sub Congregation? Deleted Sub Congregation cannot be undone.",
    id: "Anda yakin menghapus sub Organisasi? Sub Organisasi yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Sub Congregation Successfully Deleted",
    id: "Sub Organisasi Berhasil dihapus",
  },
  {
    en: "Failed to Delete Sub Congregation",
    id: "Gagal Menghapus Sub Organisasi",
  },
  {
    en: "Sub Congregation successfully created",
    id: "Sub Organisasi berhasil dibuat",
  },
  {
    en: "Failed to create sub Congregation",
    id: "Gagal membuat sub Organisasi",
  },
  {
    en: "Sub Congregation successfully updated",
    id: "Sub Organisasi berhasil diubah",
  },
  {
    en: "Failed to update sub Congregation",
    id: "Gagal mengubah sub Organisasi",
  },
  {
    en: "Try adding Sub Congregation by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default subCongregationLocaleConfig;
