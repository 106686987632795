import { Locale } from "../locale.config";

const agendaAttendanceLocaleConfig: Locale[] = [
  {
    en: "Attendance",
    id: "Absensi",
  },
  {
    en: "Attendance Detail",
    id: "Detail Absensi",
  },
  {
    en: "Add Attendance",
    id: "Tambah Absensi",
  },
  {
    en: "Month",
    id: "Bulan",
  },
  {
    en: "Select Month",
    id: "Pilih Bulan",
  },
  {
    en: "Speaker Name",
    id: "Pembawa Firman",
  },
  {
    en: "Theme",
    id: "Tema",
  },
  {
    en: "Author Name",
    id: "Nama Penulis",
  },

  {
    en: "Phase",
    id: "Fase",
  },
  {
    en: "Phase is required.",
    id: "Fase wajib diisi.",
  },

  {
    en: "Delete Agenda Attendance",
    id: "Hapus Absensi",
  },
  {
    en: "Are you sure you want to delete agenda Attendance? Deleted Agenda Attendance cannot be undone.",
    id: "Anda yakin menghapus Absensi? Absensi yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Attendance Successfully Deleted",
    id: "Absensi Berhasil dihapus",
  },
  {
    en: "Failed to Delete Agenda Attendance",
    id: "Gagal Menghapus Absensi",
  },
  {
    en: "Attendance successfully created",
    id: "Absensi berhasil dibuat",
  },
  {
    en: "Failed to create agenda Attendance",
    id: "Gagal membuat Absensi",
  },
  {
    en: "Attendance successfully updated",
    id: "Absensi berhasil diubah",
  },
  {
    en: "Failed to update agenda Attendance",
    id: "Gagal mengubah Absensi",
  },
  {
    en: "Try adding Attendance by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan Absensi, atau klik tombol di bawah",
  },
];

export default agendaAttendanceLocaleConfig;
