import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type PlaceRouteParam = DefaultRouteParam;

export const placeRoute: RouteConfig = {
  name: "Place",
  Component: React.lazy(() => import("./Place.view")),
  path: "/location/place",
  isPrivate: true,

  options: {
    label: "Place",
    testID: "MenuPlace",
  },
};
