import React from "react";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  strokeWidth?: number;
  stroke?: string;
  viewBox?: string;
};

export default function Close({
  fill = "none",
  stroke = "currentColor",
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  strokeWidth = 2,
}: Props) {
  return (
    <svg
      strokeWidth={strokeWidth}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.98959 6.48959L19.0104 18.5104" stroke={stroke} />
      <path d="M19.0104 6.48959L6.98959 18.5104" stroke={stroke} />
    </svg>
  );
}
