import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { ILocationListData } from "../../../../model/location/location.model";

export type PlaceEditRouteParam = {
  place: ILocationListData;
};

export const placeEditRoute: RouteConfig = {
  name: "Edit Place",
  Component: React.lazy(() => import("./PlaceEdit.view")),
  path: "/location/place/edit/:id",
  isPrivate: true,
};
