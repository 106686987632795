import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type SacramentTNCRouteParam = DefaultRouteParam;

export const sacramentTNCRoute: RouteConfig = {
  name: "Sacrament TNC",
  Component: React.lazy(() => import("./SacramentTNC.view")),
  path: "/master-data/tnc-sacrament",
  isPrivate: true,

  options: {
    label: "Sacrament TNC",
    testID: "MenuSacramentTNC",
  },
};
