// #region IMPORT
import urlcat from "urlcat";
import {
  IAgendaScheduleAutocompleteListApiRequest,
  IAgendaScheduleAutocompleteListApiResponse,
  IAgendaScheduleCreateApiRequest,
  IAgendaScheduleCreateApiResponse,
  IAgendaScheduleDeleteApiRequest,
  IAgendaScheduleDeleteApiResponse,
  IAgendaScheduleDetailApiRequest,
  IAgendaScheduleDetailApiResponse,
  IAgendaScheduleListApiRequest,
  IAgendaScheduleListApiResponse,
  IAgendaScheduleUpdateApiRequest,
  IAgendaScheduleUpdateApiResponse,
} from "../../../../model/agendaSchedule/agendaSchedule.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type AgendaScheduleEndpoint = {
  getAgendaSchedule: QD<
    IAgendaScheduleListApiRequest,
    IAgendaScheduleListApiResponse
  >;
  getAgendaScheduleDetail: QD<
    IAgendaScheduleDetailApiRequest,
    IAgendaScheduleDetailApiResponse
  >;
  getAgendaScheduleAutocomplete: QD<
    IAgendaScheduleAutocompleteListApiRequest,
    IAgendaScheduleAutocompleteListApiResponse
  >;
  createAgendaSchedule: MD<
    IAgendaScheduleCreateApiRequest,
    IAgendaScheduleCreateApiResponse
  >;
  updateAgendaSchedule: MD<
    IAgendaScheduleUpdateApiRequest,
    IAgendaScheduleUpdateApiResponse
  >;
  deleteAgendaSchedule: MD<
    IAgendaScheduleDeleteApiRequest,
    IAgendaScheduleDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const agendaScheduleTagTypes: IEndpointTags<AgendaScheduleEndpoint> = {
  getAgendaSchedule: "get-agenda-schedule",
  getAgendaScheduleDetail: "get-agenda-schedule-detail",
  getAgendaScheduleAutocomplete: "get-agenda-schedule-autocomplete",
  createAgendaSchedule: "create-agenda-schedule",
  updateAgendaSchedule: "update-agenda-schedule",
  deleteAgendaSchedule: "delete-agenda-schedule",
};

export const agendaScheduleEndpoint = (
  builder: Builder
): AgendaScheduleEndpoint => ({
  getAgendaSchedule: builder.query({
    query: (param) => urlcat("/agenda-schedules", param),
    providesTags: [agendaScheduleTagTypes.getAgendaSchedule],
  }),
  getAgendaScheduleDetail: builder.query({
    query: ({ id }) => ({ url: `/agenda-schedules/${id}` }),
    providesTags: [agendaScheduleTagTypes.getAgendaScheduleDetail],
  }),
  getAgendaScheduleAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/agenda-schedules`, params),
    }),
    providesTags: [agendaScheduleTagTypes.getAgendaScheduleAutocomplete],
  }),
  createAgendaSchedule: builder.mutation({
    query: (body) => ({
      url: "/agenda-schedules",
      method: "POST",
      body,
    }),
    invalidatesTags: [agendaScheduleTagTypes.getAgendaSchedule],
  }),
  updateAgendaSchedule: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/agenda-schedules/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [agendaScheduleTagTypes.getAgendaSchedule],
  }),
  deleteAgendaSchedule: builder.mutation({
    query: ({ id }) => ({
      url: `/agenda-schedules/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [agendaScheduleTagTypes.getAgendaSchedule],
  }),
});
// #endregion
