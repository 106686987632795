import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type AgendaAssignmentCreateRouteParam = DefaultRouteParam;

export const agendaAssignmentCreateRoute: RouteConfig = {
  name: "Create Agenda Assignment",
  Component: React.lazy(() => import("./AgendaAssignmentCreate.view")),
  path: "/agenda/assignment/create",
  isPrivate: true,
};
