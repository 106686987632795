import { FilePondFile, FilePondInitialFile } from "filepond";
import { FormikContextType, FormikErrors } from "formik";
import * as yup from "yup";
import { ExtraStyle } from "../../component/Type.component";

export type ICommonYupValidationSchema<T> = {
  [key in keyof T]: yup.Schema<string | number>;
};
export type ICommonUploaderFile = FilePondInitialFile | FilePondFile;
export interface CommonLabelValue {
  label: string;
  value: string;
}

export type CommonData = {
  label: string;
};

export type Period = {
  start: number;
  end: number;
};

export type PeriodString = {
  start: string;
  end: string;
};

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export interface ICommonWrapperStyle {
  containerStyle?: ExtraStyle;
  wrapperStyle?: ExtraStyle;
}

export enum ERRORCODES {
  "AUTHENTICATION_FAILED",
}

export type IAutocompleteOptionsData<T> = {
  labelKey: keyof T;
  valueKey: keyof T;
  extraLabelKey?: keyof T;
};

export interface IBaseErrorDataResponse {
  code?: string;
  status?: number;
  timestamp?: number;
  details?: string[];
  traceId?: string;
  info?: string;
  description?: string;
}

export interface IBaseResponse<T> {
  success?: boolean;
  message: string;
  payload: T;
}

export type IBaseErrorResponse = IBaseResponse<IBaseErrorDataResponse>;

export type ICommonApiAutocompleteParams = {
  keyword?: string;
};

export type ICommonApiAutocomplete = {
  key: string;
  value: string;
};

export type ICommonApiListParams = {
  page?: number;
  size?: number;
  keyword?: string;
};

export type ICommonPageInfo = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  first: boolean;
  last: boolean;
};

export type ICommonApiAutocompleteResponse<T> = IBaseResponse<T[]>;

export type ICommonApiListPayload<T> = {
  records: T[];
  pageInfo: ICommonPageInfo;
};

export type ICommonApiListResponse<T> = IBaseResponse<ICommonApiListPayload<T>>;

export interface ICommonActionLabelLoading {
  label: string;
  action: () => void;
  loading?: boolean;
}
export interface IUploadFileResponse {
  urls?: string[];
}

export interface ICommonPhoto {
  id: string;
  fileName: string;
  mimeType: string;
  accessUrl: string;
  size: number;
  createdAt: number;
}

export type FormikHookProps<T> = Omit<
  FormikContextType<T>,
  "setValues" | "setFieldValues" | "submitForm"
> & {
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<T>> | Promise<unknown>;
  setValues: (
    values: React.SetStateAction<T>,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<T>> | Promise<unknown>;
  submitForm: () => Promise<unknown>;
};

export enum ArrayAutocompleteEnum {
  REGION = "region",
  AGENDA_ROLES = "agenda-roles",
  AGENDA_TYPES = "agenda-types",
  CONGREGATION = "congregation",
  USER = "user",
  POSITION = "position",
}
