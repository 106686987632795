import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";

export type AgendaScheduleDetailRouteParam = {
  agendaScheduleId: string;
};

export const agendaScheduleDetailRoute: RouteConfig = {
  name: "Agenda Schedule Detail",
  Component: React.lazy(() => import("./AgendaScheduleDetail.view")),
  path: "/agenda/schedule/detail/:agendaScheduleId",
  isPrivate: true,

  options: {
    label: "AgendaScheduleDetail",
    testID: "MenuAgendaScheduleDetail",
  },
};
