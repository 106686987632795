import { Placement } from "@popperjs/core";
import React, { MutableRefObject } from "react";
import tw, { theme } from "twin.macro";
import { ExtraStyle } from "../../Type.component";
import Popover from "../../atom/Popover/Popover.atom";

type Props = {
  targetRef: MutableRefObject<null>;
  placement?: Placement;
  visible?: boolean;
  variant?: "dark" | "light";
  children?: React.ReactNode;
  offset?: [number, number];
  withPortal?: boolean;
  addArrow?: boolean;
  containerStyle?: React.CSSProperties | ExtraStyle | null | undefined;
  contentStyle?: ExtraStyle;
};

const Content = tw.div`rounded shadow-card py-3 px-4 font-sans`;

export default function Tooltip({
  visible = false,
  targetRef,
  placement = "top",
  variant = "dark",
  children,
  offset,
  withPortal = false,
  addArrow = true,
  containerStyle,
  contentStyle,
}: Props) {
  const styles = {
    dark: {
      backgroundColor: theme`colors.black`,
      color: theme`colors.white`,
    },
    light: {
      backgroundColor: theme`colors.white`,
      color: theme`colors.black`,
    },
  };

  return (
    <Popover
      tw="z-10"
      addArrow={addArrow}
      visible={visible}
      targetRef={targetRef}
      placement={placement}
      offset={offset ?? [0, 20]}
      css={containerStyle}
      arrowColor={styles[variant].backgroundColor}
      withPortal={withPortal}
    >
      <Content
        style={styles[variant]}
        tw="[max-width:600px]"
        css={contentStyle}
      >
        {children}
      </Content>
    </Popover>
  );
}
