import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type AgendaRolesRouteParam = DefaultRouteParam;

export const agendaRolesRoute: RouteConfig = {
  name: "Agenda Roles",
  Component: React.lazy(() => import("./AgendaRoles.view")),
  path: "/agenda/roles",
  isPrivate: true,

  options: {
    label: "Agenda Roles",
    testID: "MenuAgendaRoles",
  },
};
