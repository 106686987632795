import React from "react";
import Calendar from "../../component/atom/Icon/SVG/Calendar";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";
import { agendaAssignmentRoute } from "../agendaAssignment/AgendaAssignment.route";
import { agendaEventRoute } from "../agendaEvent/AgendaEvent.route";
import { agendaRolesRoute } from "../agendaRoles/AgendaRoles.route";
import { agendaScheduleRoute } from "../agendaSchedule/AgendaSchedule.route";
import { agendaSubjectRoute } from "../agendaSubject/AgendaSubject.route";

export type AgendaRouteParam = DefaultRouteParam;

export const agendaRoute: RouteConfig = {
  name: "Agenda",
  Component: React.lazy(() => import("./Agenda.view")),
  path: "/agenda",
  isPrivate: false,
  breadcrumb: "Agenda",
  children: [
    {
      ...agendaRolesRoute,
      path: "roles",
    },
    {
      ...agendaEventRoute,
      path: "event",
    },
    {
      ...agendaSubjectRoute,
      path: "subject",
    },
    {
      ...agendaScheduleRoute,
      path: "schedule",
    },
    {
      ...agendaAssignmentRoute,
      path: "assignment",
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    icon: <Calendar />,
    label: "Agenda",
    testID: "MenuAgenda",
    hasDivider: true,
    hasSub: [
      agendaRolesRoute,
      agendaEventRoute,
      agendaSubjectRoute,
      agendaScheduleRoute,
      agendaAssignmentRoute,
    ],
  },
};
