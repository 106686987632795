/* eslint-disable react/jsx-props-no-spreading */
import { Placement } from "@popperjs/core";
import React, { MutableRefObject, useState } from "react";
import { usePopper } from "react-popper";
import tw, { styled } from "twin.macro";
import RootPortal from "../RootPortal/RootPortal.atom";

type Props = React.ComponentProps<"div"> & {
  targetRef: MutableRefObject<null>;
  variableRefHeight?: string | number;
  visible: boolean;
  placement?: Placement;
  offset?: [number | null | undefined, number | null | undefined];
  addArrow?: boolean;
  arrowColor?: string;
  style?: React.CSSProperties;
  withPortal?: boolean;
};

const Container = styled.div`
  &[data-popper-placement="bottom"] > #arrow {
    top: 0;
  }
  &[data-popper-placement="bottom"] > #arrow:after {
    box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.07);
  }
  &[data-popper-placement="top"] > #arrow:after {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.07);
  }
`;
const Arrow = styled.div((props: { color: string }) => [
  tw`after:(content-[' '] absolute [border-width:6px] border-solid [transform:translate(-50%,_-50%)_rotate(45deg)])`,
  `&:after {
    border-color: ${props.color};
  }`,
]);

export default function Popover({
  targetRef,
  variableRefHeight,
  visible,
  placement,
  offset = [0, 0],
  addArrow,
  arrowColor = "white",
  children,
  style = {},
  withPortal = false,
  ...props
}: Props) {
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(targetRef.current, popperRef, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset,
        },
      },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
        },
      },
      {
        name: "arrow",
        options: {
          element: addArrow ? arrowRef : null,
        },
      },
    ],
  });

  if (!visible) return null;

  const variableRefHeightStyle = {
    transform: `translate(0px, ${
      attributes.popper?.["data-popper-placement"] === "top" ? "-" : ""
    }${variableRefHeight ? String(variableRefHeight) : ""}px)`,
  };

  if (withPortal)
    return (
      <RootPortal wrapperId="popover-portal">
        <Container
          ref={setPopperRef}
          style={{
            ...styles.popper,
            ...(variableRefHeight && variableRefHeightStyle),
            ...style,
          }}
          {...attributes.popper}
          {...props}
        >
          {children}
          {!!addArrow && (
            <Arrow
              id="arrow"
              ref={setArrowRef}
              style={styles.arrow}
              color={arrowColor}
            />
          )}
        </Container>
      </RootPortal>
    );

  return (
    <Container
      ref={setPopperRef}
      style={{
        ...styles.popper,
        ...(variableRefHeight && variableRefHeightStyle),
        ...style,
      }}
      {...attributes.popper}
      {...props}
    >
      {children}
      {!!addArrow && (
        <Arrow
          id="arrow"
          ref={setArrowRef}
          style={styles.arrow}
          color={arrowColor}
        />
      )}
    </Container>
  );
}
