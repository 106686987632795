import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type AgendaScheduleRouteParam = DefaultRouteParam;

export const agendaScheduleRoute: RouteConfig = {
  name: "Agenda Schedule",
  Component: React.lazy(() => import("./AgendaSchedule.view")),
  path: "/agenda/schedule/",
  isPrivate: true,

  options: {
    label: "Agenda Schedule",
    testID: "MenuAgendaSchedule",
  },
};
