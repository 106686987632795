import { combineReducers } from '@reduxjs/toolkit';
import api from '../api/api.service';
import { sessionReducer } from './store/session/session.store';
import { settingReducer } from './store/setting/setting.store';
import { snackbarReducer } from './store/snackbar/snackbar.store';

export const reducers = {
  setting: settingReducer,
  session: sessionReducer,
  snackbar: snackbarReducer,
  [api.reducerPath]: api.reducer,
};

const reducerConfig = combineReducers(reducers);

export default reducerConfig;
