import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IAgendaAttendance } from "../../../../model/agendaAttendance/agendaAttendance.model";

export type AgendaAttendanceEditRouteParam = {
  agendaAttendance?: IAgendaAttendance;
};

export const agendaAttendanceEditRoute: RouteConfig = {
  name: "Edit Attendance",
  Component: React.lazy(() => import("./AgendaAttendanceEdit.view")),
  path: "/agenda/schedule/detail/:agendaScheduleId/edit/:id",
  isPrivate: true,
};
