import { Locale } from "../locale.config";

const agendaSubjectLocaleConfig: Locale[] = [
  {
    en: "Agenda Subject",
    id: "Materi",
  },
  {
    en: "Agenda Subject Detail",
    id: "Detail Materi",
  },
  {
    en: "Add Agenda Subject",
    id: "Tambah Materi",
  },
  {
    en: "Month",
    id: "Bulan",
  },
  {
    en: "Select Month",
    id: "Pilih Bulan",
  },
  {
    en: "Month is required.",
    id: "Bulan wajib diisi.",
  },
  {
    en: "Speaker Name",
    id: "Pembawa Firman",
  },
  {
    en: "Theme",
    id: "Tema",
  },
  {
    en: "Image",
    id: "Gambar",
  },
  {
    en: "Author Name",
    id: "Nama Penulis",
  },
  {
    en: "Agenda Type is required.",
    id: "Jenis Materi wajib diisi.",
  },
  {
    en: "Update Agenda Subject",
    id: "Ubah Materi",
  },
  {
    en: "Select Agenda Types",
    id: "Pilih Jenis Materi",
  },
  {
    en: "Delete Agenda Subject",
    id: "Hapus Materi",
  },
  {
    en: "Are you sure you want to delete agenda Subject? Deleted Agenda Subject cannot be undone.",
    id: "Anda yakin menghapus Materi? Materi yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Agenda Subject Successfully Deleted",
    id: "Materi Berhasil dihapus",
  },
  {
    en: "Failed to Delete Agenda Subject",
    id: "Gagal Menghapus Materi",
  },
  {
    en: "Agenda Subject successfully created",
    id: "Materi berhasil dibuat",
  },
  {
    en: "Failed to create agenda Subject",
    id: "Gagal membuat Materi",
  },
  {
    en: "Agenda Subject successfully updated",
    id: "Materi berhasil diubah",
  },
  {
    en: "Failed to update agenda Subject",
    id: "Gagal mengubah Materi",
  },
  {
    en: "Try adding Agenda Subject by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default agendaSubjectLocaleConfig;
