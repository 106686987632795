// #region IMPORT
import urlcat from "urlcat";
import {
  IAgendaAttendanceAutocompleteListApiRequest,
  IAgendaAttendanceAutocompleteListApiResponse,
  IAgendaAttendanceCreateApiRequest,
  IAgendaAttendanceCreateApiResponse,
  IAgendaAttendanceDeleteApiRequest,
  IAgendaAttendanceDeleteApiResponse,
  IAgendaAttendanceListApiRequest,
  IAgendaAttendanceListApiResponse,
  IAgendaAttendanceUpdateApiRequest,
  IAgendaAttendanceUpdateApiResponse,
} from "../../../../model/agendaAttendance/agendaAttendance.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type AgendaAttendanceEndpoint = {
  getAgendaAttendance: QD<
    IAgendaAttendanceListApiRequest,
    IAgendaAttendanceListApiResponse
  >;
  getAgendaAttendanceAutocomplete: QD<
    IAgendaAttendanceAutocompleteListApiRequest,
    IAgendaAttendanceAutocompleteListApiResponse
  >;
  createAgendaAttendance: MD<
    IAgendaAttendanceCreateApiRequest,
    IAgendaAttendanceCreateApiResponse
  >;
  updateAgendaAttendance: MD<
    IAgendaAttendanceUpdateApiRequest,
    IAgendaAttendanceUpdateApiResponse
  >;
  deleteAgendaAttendance: MD<
    IAgendaAttendanceDeleteApiRequest,
    IAgendaAttendanceDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const agendaAttendanceTagTypes: IEndpointTags<AgendaAttendanceEndpoint> =
  {
    getAgendaAttendance: "get-agenda-attendance",
    getAgendaAttendanceAutocomplete: "get-agenda-attendance-autocomplete",
    createAgendaAttendance: "create-agenda-attendance",
    updateAgendaAttendance: "update-agenda-attendance",
    deleteAgendaAttendance: "delete-agenda-attendance",
  };

export const agendaAttendanceEndpoint = (
  builder: Builder
): AgendaAttendanceEndpoint => ({
  getAgendaAttendance: builder.query({
    query: ({ agendaScheduleId, ...params }) => ({
      url: urlcat(`/agenda-schedules/${agendaScheduleId}/attendances`, params),
    }),
    providesTags: [agendaAttendanceTagTypes.getAgendaAttendance],
  }),
  getAgendaAttendanceAutocomplete: builder.query({
    query: ({ agendaScheduleId, ...params }) => ({
      url: urlcat(
        `/auto-complete/agenda-schedules/${agendaScheduleId}/attendances`,
        params
      ),
    }),
    providesTags: [agendaAttendanceTagTypes.getAgendaAttendanceAutocomplete],
  }),
  createAgendaAttendance: builder.mutation({
    query: (body) => ({
      url: `/attendances`,
      method: "POST",
      body,
    }),
    invalidatesTags: [agendaAttendanceTagTypes.getAgendaAttendance],
  }),
  updateAgendaAttendance: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/attendances/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [agendaAttendanceTagTypes.getAgendaAttendance],
  }),
  deleteAgendaAttendance: builder.mutation({
    query: ({ id }) => ({
      url: `/attendances/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [agendaAttendanceTagTypes.getAgendaAttendance],
  }),
});
// #endregion
