import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  viewBox?: string;
};

export default function Merchant({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = '#4D4D4D',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
    >
      <g mask="url(#mask0_35_146)">
        <path
          fill={fill}
          d="M21.025 11.05V19c0 .55-.196 1.02-.587 1.413a1.926 1.926 0 01-1.413.587h-14c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 013.026 19v-7.95c-.383-.35-.68-.8-.887-1.35-.209-.55-.213-1.15-.013-1.8l1.05-3.4a2.21 2.21 0 01.713-1.075A1.805 1.805 0 015.075 3h13.9c.45 0 .842.138 1.175.413.333.275.575.637.725 1.087l1.05 3.4c.2.65.196 1.242-.012 1.775a3.951 3.951 0 01-.888 1.375zm-6.8-1.05c.45 0 .792-.154 1.025-.463.233-.308.325-.654.275-1.037l-.55-3.5h-1.95v3.7c0 .35.117.654.35.913.233.258.517.387.85.387zm-4.5 0c.383 0 .696-.13.938-.387.241-.259.362-.563.362-.913V5h-1.95l-.55 3.5c-.067.4.02.75.262 1.05.242.3.555.45.938.45zm-4.45 0c.3 0 .563-.108.787-.325.225-.217.363-.492.413-.825L7.025 5h-1.95l-1 3.35c-.1.333-.046.692.162 1.075.209.383.555.575 1.038.575zm13.5 0c.483 0 .833-.192 1.05-.575.217-.383.267-.742.15-1.075L18.925 5h-1.9l.55 3.85c.05.333.188.608.413.825a1.1 1.1 0 00.787.325zm-13.75 9h14v-7.05a.605.605 0 01-.162.05h-.088c-.45 0-.846-.075-1.188-.225-.341-.15-.679-.392-1.012-.725a3.3 3.3 0 01-1.025.7c-.383.167-.792.25-1.225.25-.45 0-.87-.083-1.262-.25a3.258 3.258 0 01-1.038-.7 3.05 3.05 0 01-.988.7 2.95 2.95 0 01-1.212.25 3.32 3.32 0 01-1.313-.25 3.258 3.258 0 01-1.037-.7c-.35.35-.696.596-1.037.737A3.01 3.01 0 015.274 12h-.113a.254.254 0 01-.137-.05V19z"
        />
      </g>
    </svg>
  );
}
