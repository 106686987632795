import React from 'react';
import { DefaultRouteParam, RouteConfig } from '../../../config/router/router.config';

export type PlaygroundRouteParam = DefaultRouteParam;

export const homePlaygroundRoute: RouteConfig = {
  name: 'Playground',
  Component: React.lazy(() => import('./HomePlayground.view')),
  path: '/playground',
  isPrivate: true,

  options: {
    label: 'Playground',
    testID: 'MenuPlayground',
  },
};
