import { useEffect, useState } from "react";

export type UseSession = ReturnType<typeof useSession>;

function useSession() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingTimeOut = setTimeout(() => setIsLoading(false), 2000);
    return () => clearTimeout(loadingTimeOut);
  }, []);
  return {
    isLoading,
  };
}

export default useSession;
