import {
  Action,
  CaseReducer,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ISession } from "../../../../model/auth/auth.model";

// #region INTERFACES
export type ISessionStore = Partial<ISession> & {
  isLoggedIn: boolean;
};

export enum LogoutReason {
  REVOKE = "REVOKE",
  CLEAR = "CLEAR",
  USER_UNAUTHORIZED = "USER_UNAUTHORIZED",
}

type ILoginActionPayload = PayloadAction<ISessionStore>;

type IRefreshActionPayload = PayloadAction<
  Omit<ISessionStore, "isFirstLogin" | "isLoggedIn">
>;

type ILogoutActionPayload = PayloadAction<{
  type: LogoutReason;
  token?: string;
}>;
// #endregion

const initialState: ISessionStore = {
  accessToken: "",
  refreshToken: "",
  type: "",
  expiresIn: undefined,
  isFirstLogin: undefined,
  isLoggedIn: false,
  email: "",
  name: "",
};

const logout: CaseReducer<ISessionStore, ILogoutActionPayload> = () => ({
  ...initialState,
});

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    login: (state, action: ILoginActionPayload) => ({
      ...state,
      ...action.payload,
      isLoggedIn: true,
    }),
    changeSession: (state, action: IRefreshActionPayload) => ({
      ...state,
      ...action.payload,
    }),
    logout,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action: Action) => String(action.type).includes("/logout"),
      () => {}
    );
  },
});

export const sessionAction = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
