import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type CongregationRouteParam = DefaultRouteParam;

export const congregationRoute: RouteConfig = {
  name: "Congregation",
  Component: React.lazy(() => import("./Congregation.view")),
  path: "/administration/congregation",
  isPrivate: true,

  options: {
    label: "Congregation",
    testID: "MenuCongregation",
  },
};
