import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginRoute } from "../../../../../module/auth/Login/Login.route";
import { settingsRoute } from "../../../../../module/settings/Settings.route";
import {
  LogoutReason,
  sessionAction,
} from "../../../../../service/reducer/store/session/session.store";

export default function useProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // #region CALLBACK
  const handleClickSetting = useCallback(() => {
    navigate(settingsRoute.path);
  }, [navigate]);
  const handleClickLogout = useCallback(() => {
    dispatch(sessionAction.logout({ type: LogoutReason.CLEAR, token: "" }));
    navigate(loginRoute.path);
  }, [dispatch, navigate]);

  // #endregion

  return {
    handleClickSetting,
    handleClickLogout,
  };
}
