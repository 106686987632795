import { Locale } from "../locale.config";

const usersLocaleConfig: Locale[] = [
  {
    en: "User",
    id: "Umat",
  },
  {
    en: "Can not input more than 255 characters.",
    id: "Max 255 karakter.",
  },
  {
    en: "Can not input more than 50 characters.",
    id: "Max 50 karakter.",
  },
  {
    en: "Add User",
    id: "Tambah Umat",
  },
  {
    en: "Update User",
    id: "Ubah Umat",
  },
  {
    en: "Address",
    id: "Alamat",
  },
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Last Login at",
    id: "Terakhir Login",
  },
  {
    en: "Gender",
    id: "Jenis Kelamin",
  },
  {
    en: "Male",
    id: "Pria",
  },
  {
    en: "Female",
    id: "Wanita",
  },
  {
    en: "Single",
    id: "Lajang",
  },
  {
    en: "Married",
    id: "Menikah",
  },
  {
    en: "Divorced",
    id: "Bercerai",
  },
  {
    en: "Widowed",
    id: "Janda/Randa",
  },
  {
    en: "Birthday",
    id: "Tanggal Lahir",
  },
  {
    en: "Birth Place",
    id: "Tempat Lahir",
  },
  {
    en: "Baptism Date",
    id: "Tanggal Baptis",
  },
  {
    en: "Baptism Place",
    id: "Tempat Baptis",
  },
  {
    en: "Sidi Date",
    id: "Tanggal Sidi",
  },
  {
    en: "Sidi Place",
    id: "Tempat Sidi",
  },
  {
    en: "Marital Status",
    id: "Status Pernikahan",
  },
  {
    en: "Name is required",
    id: "Nama wajib diisi",
  },
  {
    en: "Region is required.",
    id: "Wilayah wajib diisi",
  },
  {
    en: "User is required",
    id: "Umat wajib diisi",
  },
  {
    en: "Select Region",
    id: "Pilih Wilayah",
  },
  {
    en: "Select User",
    id: "Pilih Umat",
  },
  {
    en: "User",
    id: "Umat",
  },
  {
    en: "Photo",
    id: "Foto",
  },
  {
    en: "NIW is required",
    id: "NIW wajib diisi",
  },
  {
    en: "Password is required",
    id: "Password wajib diisi",
  },
  {
    en: "Minimum 8 characters",
    id: "Minimal 8 karakter",
  },
  {
    en: "Invalid Email",
    id: "Email tidak valid",
  },
  {
    en: "General Information",
    id: "Informasi Umum",
  },
  {
    en: "Blood Type",
    id: "Golongan Darah",
  },
  {
    en: "Blood Type RH",
    id: "RH Golongan Darah",
  },
  {
    en: "Register Date",
    id: "Tanggal Daftar",
  },
  {
    en: "Death Date",
    id: "Tanggal Kematian",
  },
  {
    en: "Phone Number",
    id: "Nomor Telepon",
  },
  {
    en: "Father Name",
    id: "Nama Ayah",
  },
  {
    en: "Mother Name",
    id: "Nama Ibu",
  },
  {
    en: "Partner Name",
    id: "Nama Pasangan",
  },
  {
    en: "Business Field",
    id: "Bidang Usaha",
  },
  {
    en: "Marriage Date",
    id: "Tanggal Menikah",
  },
  {
    en: "Business Field",
    id: "Bidang Usaha",
  },
  {
    en: "Occupation",
    id: "Pekerjaan",
  },
  {
    en: "Personal Information",
    id: "Informasi Personal",
  },
  {
    en: "Business Information",
    id: "Informasi Bisnis",
  },
  {
    en: "Family Information",
    id: "Informasi Keluarga",
  },
  {
    en: "Religious Information",
    id: "Informasi Keagamaan",
  },
  {
    en: "Address and Contact Information",
    id: "Informasi Alamat dan Kontak",
  },
  {
    en: "Hide Optional Form",
    id: "Sembunyikan Form Opsional",
  },
  {
    en: "Group is required.",
    id: "Kelompok wajib diisi",
  },
  {
    en: "Select Group",
    id: "Pilih Kelompok",
  },
  {
    en: "Show Optional Form",
    id: "Tampilkan Form Opsional",
  },
  {
    en: "Delete User",
    id: "Hapus Umat",
  },
  {
    en: "Are you sure you want to delete user? Deleted User cannot be undone.",
    id: "Anda yakin menghapus umat? Umat yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "User Successfully Deleted",
    id: "Umat Berhasil dihapus",
  },
  {
    en: "Failed to Delete User",
    id: "Gagal Menghapus Umat",
  },
  {
    en: "User successfully created",
    id: "Umat berhasil dibuat",
  },
  {
    en: "Failed to create user",
    id: "Gagal membuat umat",
  },
  {
    en: "User successfully updated",
    id: "Umat berhasil diubah",
  },
  {
    en: "Failed to update user",
    id: "Gagal mengubah umat",
  },
  {
    en: "Try adding User by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default usersLocaleConfig;
