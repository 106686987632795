import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type RoomCreateRouteParam = DefaultRouteParam;

export const roomCreateRoute: RouteConfig = {
  name: "Create room",
  Component: React.lazy(() => import("./RoomCreate.view")),
  path: "/master-data/room/create",
  isPrivate: true,
};
