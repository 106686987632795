import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type AnnouncementsCreateRouteParam = DefaultRouteParam;

export const announcementsCreateRoute: RouteConfig = {
  name: "Create Announcements",
  Component: React.lazy(() => import("./AnnouncementsCreate.view")),
  path: "/master-data/announcements/create",
  isPrivate: true,
};
