import React from "react";
import Customer from "../../component/atom/Icon/SVG/Customer";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";
import { familyRoute } from "../family/Family.route";
import { regionRoute } from "../region/Region.route";
import { userRoute } from "../user/User.route";

export type UserManagementRouteParam = DefaultRouteParam;

export const userManagementRoute: RouteConfig = {
  name: "Data Warga",
  Component: React.lazy(() => import("./UserManagement.view")),
  path: "/user-management",
  isPrivate: false,
  breadcrumb: "Data Warga",
  children: [
    {
      ...userRoute,
      path: "user",
    },
    {
      ...regionRoute,
      path: "region",
    },
    {
      ...familyRoute,
      path: "family",
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    icon: <Customer />,
    label: "Data Warga",
    testID: "MenuUserManagement",
    hasDivider: true,
    hasSub: [userRoute, regionRoute, familyRoute],
  },
};
