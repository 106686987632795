import { Locale } from "../locale.config";

const commonLocaleConfig: Locale[] = [
  {
    en: "A-Z (Ascending)",
    id: "A-Z (Meningkat)",
  },
  {
    en: "Z-A (Descending)",
    id: "Z-A (Menurun)",
  },
  {
    en: "Next",
    id: "Selanjutnya",
  },
  {
    en: "No Options",
    id: "Belum ada Pilihan",
  },
  {
    en: "Showing",
    id: "Tampilkan",
  },
  {
    en: "Edit",
    id: "Ubah",
  },
  {
    en: "Add",
    id: "Tambah",
  },
  {
    en: "Links",
    id: "Tautan",
  },
  {
    en: "of",
    id: "dari",
  },
  {
    en: "From",
    id: "Dari",
  },
  {
    en: "More Filter",
    id: "Filter Lainnya",
  },
  {
    en: "Type",
    id: "Tipe",
  },
  {
    en: "Show Media 1:1",
    id: "Lihat Media 1:1",
  },
  {
    en: "Show Media 4:3",
    id: "Lihat Media 4:3",
  },
  {
    en: "Select Start date and End",
    id: "Pilih Tanggal Mulai dan Akhir",
  },
  {
    en: "No Result Found",
    id: "Tidak menemukan hasil yang sesuai",
  },
  {
    en: "Try a different search terms",
    id: "Coba ubah kata kunci yang digunakan",
  },
  {
    en: "There is nothing here",
    id: "Belum ada apa-apa di sini",
  },
  {
    en: `Let's start by clicking the button on the top right, or you can click the button below:`,
    id: "Ayo kita mulai isi dengan klik tombol di bawah:",
  },
  {
    en: "Day",
    id: "Hari",
  },
  {
    en: "Completed",
    id: "Selesai",
  },
  {
    en: "Misc",
    id: "Lainnya",
  },
  {
    en: "Try again later",
    id: "Coba kembali lagi beberapa saat nanti",
  },
  {
    en: "Set Back to Default List",
    id: "Atur kembali ke default",
  },
  {
    en: "Apply",
    id: "Terapkan",
  },
  {
    en: "Select",
    id: "Pilih",
  },
  {
    en: "Save Changes",
    id: "Simpan Perubahan",
  },
  {
    en: "All",
    id: "Semua",
  },
  {
    en: "Drag and Drop files or",
    id: "Seret dan Lepas file atau",
  },
  {
    en: "Browse",
    id: "Telusuri",
  },
  {
    en: "Times",
    id: "Kali",
  },
  {
    en: "Item",
    id: "Barang",
  },
  {
    en: "Item Photo 1:1",
    id: "Foto Barang 1:1",
  },
  {
    en: "Max size 5 MB.",
    id: "Size maksimal 5 MB.",
  },
  {
    en: "Banner 1:1 used for Lorem Ipsum Dolor Sit Amet.",
    id: "Banner 1:1 digunakan untuk Lorem Ipsum Dolor Sit Amet.",
  },
  {
    en: "Banner 4:3 used for Lorem Ipsum Dolor Sit Amet.",
    id: "Banner 4:3 digunakan untuk Lorem Ipsum Dolor Sit Amet.",
  },
  {
    en: "Banner 2:1 used for Lorem Ipsum Dolor Sit Amet.",
    id: "Banner 2:1 digunakan untuk Lorem Ipsum Dolor Sit Amet.",
  },
  {
    en: "Cancel",
    id: "Batalkan",
  },
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Days",
    id: "Hari",
  },
  {
    en: "Cancel it",
    id: "Batal",
  },
  {
    en: "Apply it",
    id: "Aplikasikan",
  },
  {
    en: "Merchant List",
    id: "Daftar Merchant",
  },
  {
    en: "Description and Media",
    id: "Deskripsi dan Media",
  },
  {
    en: "Show Media",
    id: "Lihat Media",
  },
  {
    en: "Delete",
    id: "Hapus",
  },
  {
    en: "Pending",
    id: "Tertunda",
  },
  {
    en: "Success",
    id: "Berhasil",
  },
  {
    en: "Cancelled",
    id: "Dibatalkan",
  },
  {
    en: "Time",
    id: "Waktu",
  },
  {
    en: "Active",
    id: "Aktif",
  },
  {
    en: "Year",
    id: "Tahun",
  },
  {
    en: "Inactive",
    id: "Inaktif",
  },
  {
    en: "Deleted",
    id: "Dihapus",
  },
  {
    en: "Depleted",
    id: "Habis",
  },
  {
    en: "Health",
    id: "Kesehatan",
  },
  {
    en: "Entertainment",
    id: "Hiburan",
  },
  {
    en: "Food",
    id: "Makanan",
  },
  {
    en: "Travel",
    id: "Perjalanan",
  },
  {
    en: "Fashion",
    id: "Pakaian",
  },
  {
    en: "Misc",
    id: "Lainnya",
  },
  {
    en: "Failed",
    id: "Gagal",
  },
  {
    en: "Logout",
    id: "Keluar",
  },
  {
    en: "Data already exists",
    id: "Data sudah ada",
  },
  {
    en: "already exists",
    id: "sudah ada",
  },
  {
    en: "Update",
    id: "Perbarui",
  },
  {
    en: "Reset",
    id: "Reset",
  },
  {
    en: "Administration",
    id: "Administrasi",
  },
  {
    en: "January",
    id: "Januari",
  },
  {
    en: "February",
    id: "Februari",
  },
  {
    en: "March",
    id: "Maret",
  },
  {
    en: "April",
    id: "April",
  },
  {
    en: "May",
    id: "Mei",
  },
  {
    en: "June",
    id: "Juni",
  },
  {
    en: "July",
    id: "Juli",
  },
  {
    en: "August",
    id: "Agustus",
  },
  {
    en: "September",
    id: "September",
  },
  {
    en: "October",
    id: "Oktober",
  },
  {
    en: "November",
    id: "November",
  },
  {
    en: "December",
    id: "Desember",
  },
];

export default commonLocaleConfig;
