import React, { PropsWithChildren } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import tw, { css, styled } from "twin.macro";
import LoadingIndicator from "../../../../component/atom/LoadingIndicator/LoadingIndicator.atom";
import SnackbarAdapter from "../../../../component/molecule/Snackbar/SnackbarAdapter.molecule";
import Sidebar from "../../../../component/organism/main/Sidebar/Sidebar.organism";
import { sidebarConfig } from "../../../../config/router/router.config";
import useWrapperViewModel from "./Wrapper.viewModel";

// #region STYLED
const Container = styled.div(() => [
  tw`min-h-screen min-w-min bg-secondary-50 flex items-stretch`,
  css`
    ::-webkit-scrollbar {
      ${tw`[width:7px] [height:7px] [border-radius:10px]`}
    }
    ::-webkit-scrollbar-track {
      ${tw`[border-radius:10px]`}
    }
    ::-webkit-scrollbar-thumb {
      ${tw`[border-radius:10px] bg-gray-200`}
    }
  `,
]);
const ContentWrapper = styled.div(() => [
  tw`flex-1 bg-backgroundColor relative [width:calc(100vw_-_320px)] overflow-x-auto whitespace-nowrap`,
]);
const SidebarWrapper = styled.div((props: { expanded: boolean }) => [
  tw`z-30 bg-white border-r border-gray-100 inline-block [width:84px] duration-200 [height:100vh] shrink-0 relative`,
  props.expanded && tw`w-[256px]`,
  !props.expanded &&
    `ul, span {
      display: none;
    }`,
]);

const ContentArea = tw.div`w-full h-full overflow-y-hidden`;
const AuthenticationContainer = tw.div`flex items-center justify-center h-screen w-full relative flex-col`;
// #endregion

export default function WrapperView({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const { sidebar, location, session } = useWrapperViewModel();

  if (session.isLoading) {
    return (
      <Container>
        <AuthenticationContainer>
          <LoadingIndicator />
        </AuthenticationContainer>
      </Container>
    );
  }

  return (
    <Container id="rootContainer">
      <ToastContainer
        theme="light"
        position="bottom-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        hideProgressBar
      />

      {!sidebar.hideSidebar && (
        <>
          <SidebarWrapper expanded={sidebar.isExpanded} />
          <SidebarWrapper expanded={sidebar.isExpanded} tw="fixed">
            <Sidebar
              onBurgerClick={() => {
                sidebar.setExpandSidebar((v) => !v);
              }}
              currentPath={location.pathname}
              onItemClick={sidebar.onSidebarItemClick}
              items={sidebarConfig}
              expandedSidebar={sidebar.isExpanded}
            />
          </SidebarWrapper>
        </>
      )}
      <ContentWrapper>
        <ContentArea>{children}</ContentArea>
        <SnackbarAdapter />
      </ContentWrapper>
    </Container>
  );
}
