// #region IMPORT
import urlcat from "urlcat";
import {
  IAgendaTypesAutocompleteListApiRequest,
  IAgendaTypesAutocompleteListApiResponse,
  IAgendaTypesCreateApiRequest,
  IAgendaTypesCreateApiResponse,
  IAgendaTypesDeleteApiRequest,
  IAgendaTypesDeleteApiResponse,
  IAgendaTypesListApiRequest,
  IAgendaTypesListApiResponse,
  IAgendaTypesUpdateApiRequest,
  IAgendaTypesUpdateApiResponse,
} from "../../../../model/agendaTypes/agendaTypes.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type AgendaTypesEndpoint = {
  getAgendaTypes: QD<IAgendaTypesListApiRequest, IAgendaTypesListApiResponse>;
  getAgendaTypesAutocomplete: QD<
    IAgendaTypesAutocompleteListApiRequest,
    IAgendaTypesAutocompleteListApiResponse
  >;
  createAgendaTypes: MD<
    IAgendaTypesCreateApiRequest,
    IAgendaTypesCreateApiResponse
  >;
  updateAgendaTypes: MD<
    IAgendaTypesUpdateApiRequest,
    IAgendaTypesUpdateApiResponse
  >;
  deleteAgendaTypes: MD<
    IAgendaTypesDeleteApiRequest,
    IAgendaTypesDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const agendaTypesTagTypes: IEndpointTags<AgendaTypesEndpoint> = {
  getAgendaTypes: "get-agenda-types",
  getAgendaTypesAutocomplete: "get-agenda-types-autocomplete",
  createAgendaTypes: "create-agenda-types",
  updateAgendaTypes: "update-agenda-types",
  deleteAgendaTypes: "delete-agenda-types",
};

export const agendaTypesEndpoint = (builder: Builder): AgendaTypesEndpoint => ({
  getAgendaTypes: builder.query({
    query: (param) => urlcat("/agenda-types", param),
    providesTags: [agendaTypesTagTypes.getAgendaTypes],
  }),
  getAgendaTypesAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/agenda-types`, params),
    }),
    providesTags: [agendaTypesTagTypes.getAgendaTypesAutocomplete],
  }),
  createAgendaTypes: builder.mutation({
    query: (body) => ({
      url: "/agenda-types",
      method: "POST",
      body,
    }),
    invalidatesTags: [agendaTypesTagTypes.getAgendaTypes],
  }),
  updateAgendaTypes: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/agenda-types/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [agendaTypesTagTypes.getAgendaTypes],
  }),
  deleteAgendaTypes: builder.mutation({
    query: ({ id }) => ({
      url: `/agenda-types/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [agendaTypesTagTypes.getAgendaTypes],
  }),
});
// #endregion
