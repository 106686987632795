import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type FAQRouteParam = DefaultRouteParam;

export const faqRoute: RouteConfig = {
  name: "FAQ",
  Component: React.lazy(() => import("./FAQ.view")),
  path: "/master-data/faq",
  isPrivate: true,

  options: {
    label: "FAQ",
    testID: "MenuFAQ",
  },
};
