// #region IMPORT
import urlcat from "urlcat";
import {
  ISacramentApprovalRequest,
  ISacramentApprovalResponse,
  ISacramentDeleteRequest,
  ISacramentDeleteResponse,
  ISacramentDetailRequest,
  ISacramentDetailResponse,
  ISacramentListApiRequest,
  ISacramentListApiResponse,
  ISacramentRejectionRequest,
  ISacramentRejectionResponse,
} from "../../../../model/sacrament/sacrament.model";
import {
  ISacramentTNCDetailRequest,
  ISacramentTNCDetailResponse,
  ISacramentTNCListApiRequest,
  ISacramentTNCListApiResponse,
  ISacramentTNCUpdateApiRequest,
  ISacramentTNCUpdateApiResponse,
} from "../../../../model/sacrament/sacramentTNC.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type SacramentEndpoint = {
  getSacraments: QD<ISacramentListApiRequest, ISacramentListApiResponse>;
  getSacramentTNCs: QD<
    ISacramentTNCListApiRequest,
    ISacramentTNCListApiResponse
  >;
  getSacramentDetail: QD<ISacramentDetailRequest, ISacramentDetailResponse>;
  getSacramentTNCDetail: QD<
    ISacramentTNCDetailRequest,
    ISacramentTNCDetailResponse
  >;
  updateSacrament: MD<ISacramentApprovalRequest, ISacramentApprovalResponse>;
  updateSacramentTNC: MD<
    ISacramentTNCUpdateApiRequest,
    ISacramentTNCUpdateApiResponse
  >;
  approveSacrament: MD<ISacramentApprovalRequest, ISacramentApprovalResponse>;
  rejectSacrament: MD<ISacramentRejectionRequest, ISacramentRejectionResponse>;
  deleteSacrament: MD<ISacramentDeleteRequest, ISacramentDeleteResponse>;
};
// #endregion

// #region ENDPOINT
export const sacramentTagTypes: IEndpointTags<SacramentEndpoint> = {
  getSacraments: "get-sacraments",
  getSacramentTNCs: "get-sacrament-tnc-list",
  getSacramentDetail: "get-sacrament-detail",
  getSacramentTNCDetail: "get-sacrament-tnc-detail",
  approveSacrament: "approve-sacrament",
  rejectSacrament: "reject-sacrament",
  deleteSacrament: "delete-sacrament",
  updateSacrament: "update-sacrament",
  updateSacramentTNC: "update-sacrament-tnc",
};

export const sacramentEndpoint = (builder: Builder): SacramentEndpoint => ({
  getSacramentDetail: builder.query({
    query: ({ id }) => ({
      url: `/sacraments/${id}`,
    }),
    providesTags: [sacramentTagTypes.getSacramentDetail],
  }),
  getSacramentTNCDetail: builder.query({
    query: ({ type }) => ({
      url: `/sacraments-tnc/${type}`,
    }),
    providesTags: [sacramentTagTypes.getSacramentTNCDetail],
  }),
  getSacraments: builder.query({
    query: (param) => urlcat("/sacraments", param),
    providesTags: [sacramentTagTypes.getSacraments],
  }),
  getSacramentTNCs: builder.query({
    query: () => "/sacraments-tnc",
    providesTags: [sacramentTagTypes.getSacramentTNCs],
  }),
  approveSacrament: builder.mutation({
    query: ({ id }) => ({
      url: `/sacraments/${id}/accept`,
      method: "PUT",
    }),
    invalidatesTags: [sacramentTagTypes.getSacraments],
  }),
  rejectSacrament: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/sacraments/${id}/reject`,
      method: "PUT",
      body,
    }),
    invalidatesTags: [sacramentTagTypes.getSacraments],
  }),
  updateSacrament: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/sacraments/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [
      sacramentTagTypes.getSacraments,
      sacramentTagTypes.getSacramentDetail,
    ],
  }),
  updateSacramentTNC: builder.mutation({
    query: (body) => ({
      url: `/sacraments-tnc`,
      method: "POST",
      body,
    }),
    invalidatesTags: [
      sacramentTagTypes.getSacramentTNCs,
      sacramentTagTypes.getSacramentTNCDetail,
    ],
  }),
  deleteSacrament: builder.mutation({
    query: ({ id }) => ({
      url: `/sacraments/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [sacramentTagTypes.getSacraments],
  }),
});
// #endregion
