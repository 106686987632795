import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type GroupCreateRouteParam = DefaultRouteParam;

export const groupCreateRoute: RouteConfig = {
  name: "Create Group",
  Component: React.lazy(() => import("./GroupCreate.view")),
  path: "/user-management/region/:regionId/group/create",
  isPrivate: true,
};
