import React from "react";
import Report from "../../component/atom/Icon/SVG/Report";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";
import { logUserRoute } from "../logUser/LogUser.route";

export type LogRouteParam = DefaultRouteParam;

export const logRoute: RouteConfig = {
  name: "Pengelolaan Konten",
  Component: React.lazy(() => import("./log.view")),
  path: "/log",
  isPrivate: false,
  breadcrumb: "Log",
  children: [
    {
      ...logUserRoute,
      path: "news",
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    icon: <Report />,
    label: "Log",
    testID: "MenuLog",
    hasDivider: true,
    hasSub: [logUserRoute],
  },
};
