import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IUser } from "../../../../model/user/user.model";

export type UserEditRouteParam = {
  user: IUser;
};

export const userEditRoute: RouteConfig = {
  name: "Edit User",
  Component: React.lazy(() => import("./UserEdit.view")),
  path: "/user-management/user/edit/:id",
  isPrivate: true,
};
