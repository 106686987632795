// #faq IMPORT
import urlcat from "urlcat";
import {
  IFAQCreateApiRequest,
  IFAQCreateApiResponse,
  IFAQDeleteApiRequest,
  IFAQDeleteApiResponse,
  IFAQListApiRequest,
  IFAQListApiResponse,
  IFAQUpdateApiRequest,
  IFAQUpdateApiResponse,
} from "../../../../model/faq/faq.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endfaq

// #faq INTERFACES
export type FAQEndpoint = {
  getFAQ: QD<IFAQListApiRequest, IFAQListApiResponse>;
  createFAQ: MD<IFAQCreateApiRequest, IFAQCreateApiResponse>;
  updateFAQ: MD<IFAQUpdateApiRequest, IFAQUpdateApiResponse>;
  deleteFAQ: MD<IFAQDeleteApiRequest, IFAQDeleteApiResponse>;
};
// #endfaq

// #faq ENDPOINT
export const faqTagTypes: IEndpointTags<FAQEndpoint> = {
  getFAQ: "get-faq",
  createFAQ: "create-faq",
  updateFAQ: "update-faq",
  deleteFAQ: "delete-faq",
};

export const faqEndpoint = (builder: Builder): FAQEndpoint => ({
  getFAQ: builder.query({
    query: (params) => ({
      url: urlcat(`/faqs/paged`, params),
    }),
    providesTags: [faqTagTypes.getFAQ],
  }),
  createFAQ: builder.mutation({
    query: (body) => ({
      url: `/faqs`,
      method: "POST",
      body,
    }),
    invalidatesTags: [faqTagTypes.getFAQ],
  }),
  updateFAQ: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/faqs/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [faqTagTypes.getFAQ],
  }),
  deleteFAQ: builder.mutation({
    query: ({ id }) => ({
      url: `/faqs/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [faqTagTypes.getFAQ],
  }),
});
// #endfaq
