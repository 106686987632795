import React from "react";
import Announcement from "../../component/atom/Icon/SVG/Announcement";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";
import { committeeRoute } from "../commitee/Committee.route";
import { congregationRoute } from "../congregation/Congregation.route";
import { councilRoute } from "../council/Council.route";
import { positionRoute } from "../position/Position.route";

export type AdministrationRouteParam = DefaultRouteParam;

export const administrationRoute: RouteConfig = {
  name: "Organisasi",
  Component: React.lazy(() => import("./Administration.view")),
  path: "/administration",
  isPrivate: false,
  breadcrumb: "Organisasi",
  children: [
    {
      ...congregationRoute,
      path: "congregation",
    },
    {
      ...positionRoute,
      path: "position",
    },
    {
      ...committeeRoute,
      path: "committee",
    },
    {
      ...councilRoute,
      path: "council",
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    icon: <Announcement />,
    label: "Organisasi",
    testID: "MenuAdministration",
    hasDivider: true,
    hasSub: [congregationRoute, positionRoute, committeeRoute, councilRoute],
  },
};
