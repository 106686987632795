import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PUBLIC_ROUTE } from "../../../constant/common/common.constant";

export type UseSidebar = ReturnType<typeof useSidebar>;
function useSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [expandSidebar, setExpandSidebar] = useState<boolean>(true);

  const hideSidebar = useMemo(
    () => PUBLIC_ROUTE.indexOf(location.pathname) > -1,
    [location.pathname]
  );

  const onSidebarItemClick = useCallback(
    (path: string, customNavigation?: () => void) => {
      if (customNavigation) {
        customNavigation();
      } else {
        navigate(path);
      }
    },
    [navigate]
  );

  return {
    expandSidebar,
    setExpandSidebar,
    onSidebarItemClick,
    hideSidebar,
    isExpanded: expandSidebar,
  };
}

export default useSidebar;
