// #region IMPORT
import urlcat from "urlcat";
import {
  IRoomAutocompleteListApiRequest,
  IRoomAutocompleteListApiResponse,
  IRoomCreateApiRequest,
  IRoomCreateApiResponse,
  IRoomDeleteApiRequest,
  IRoomDeleteApiResponse,
  IRoomListApiRequest,
  IRoomListApiResponse,
  IRoomUpdateApiRequest,
  IRoomUpdateApiResponse,
} from "../../../../model/room/room.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type RoomEndpoint = {
  getRoom: QD<IRoomListApiRequest, IRoomListApiResponse>;
  getRoomAutocomplete: QD<
    IRoomAutocompleteListApiRequest,
    IRoomAutocompleteListApiResponse
  >;
  createRoom: MD<IRoomCreateApiRequest, IRoomCreateApiResponse>;
  updateRoom: MD<IRoomUpdateApiRequest, IRoomUpdateApiResponse>;
  deleteRoom: MD<IRoomDeleteApiRequest, IRoomDeleteApiResponse>;
};
// #endregion

// #region ENDPOINT
export const roomTagTypes: IEndpointTags<RoomEndpoint> = {
  getRoom: "get-room",
  getRoomAutocomplete: "get-room-autocomplete",
  createRoom: "create-room",
  updateRoom: "update-room",
  deleteRoom: "delete-room",
};

export const roomEndpoint = (builder: Builder): RoomEndpoint => ({
  getRoom: builder.query({
    query: (param) => urlcat("/rooms", param),
    providesTags: [roomTagTypes.getRoom],
  }),
  getRoomAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/rooms`, params),
    }),
    providesTags: [roomTagTypes.getRoomAutocomplete],
  }),
  createRoom: builder.mutation({
    query: (body) => ({
      url: "/rooms",
      method: "POST",
      body,
    }),
    invalidatesTags: [roomTagTypes.getRoom],
  }),
  updateRoom: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/rooms/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [roomTagTypes.getRoom],
  }),
  deleteRoom: builder.mutation({
    query: ({ id }) => ({
      url: `/rooms/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [roomTagTypes.getRoom],
  }),
});
// #endregion
