import React from "react";
import { Params } from "react-router-dom";
import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import { administrationRoute } from "../../module/administration/Administration.route";
import { agendaRoute } from "../../module/agenda/Agenda.route";
import { agendaAssignmentRoute } from "../../module/agendaAssignment/AgendaAssignment.route";
import { agendaAssignmentCreateRoute } from "../../module/agendaAssignment/page/create/AgendaAssignmentCreate.route";
import { agendaAssignmentEditRoute } from "../../module/agendaAssignment/page/edit/AgendaAssignmentEdit.route";
import { agendaAttendanceCreateRoute } from "../../module/agendaAttendance/page/create/AgendaAttendanceCreate.route";
import { agendaAttendanceEditRoute } from "../../module/agendaAttendance/page/edit/AgendaAttendanceEdit.route";
import { agendaEventRoute } from "../../module/agendaEvent/AgendaEvent.route";
import { agendaEventCreateRoute } from "../../module/agendaEvent/page/create/AgendaEventCreate.route";
import { agendaEventEditRoute } from "../../module/agendaEvent/page/edit/AgendaEventEdit.route";
import { agendaRolesRoute } from "../../module/agendaRoles/AgendaRoles.route";
import { agendaRolesCreateRoute } from "../../module/agendaRoles/page/create/AgendaRolesCreate.route";
import { agendaRolesEditRoute } from "../../module/agendaRoles/page/edit/AgendaRolesEdit.route";
import { agendaScheduleRoute } from "../../module/agendaSchedule/AgendaSchedule.route";
import { agendaScheduleCreateRoute } from "../../module/agendaSchedule/page/create/AgendaScheduleCreate.route";
import { agendaScheduleDetailRoute } from "../../module/agendaSchedule/page/detail/AgendaScheduleDetail.route";
import { agendaScheduleEditRoute } from "../../module/agendaSchedule/page/edit/AgendaScheduleEdit.route";
import { agendaSubjectRoute } from "../../module/agendaSubject/AgendaSubject.route";
import { agendaSubjectCreateRoute } from "../../module/agendaSubject/page/create/AgendaSubjectCreate.route";
import { agendaSubjectEditRoute } from "../../module/agendaSubject/page/edit/AgendaSubjectEdit.route";
import { announcementsCreateRoute } from "../../module/announcements/page/create/AnnouncementsCreate.route";
import { announcementsEditRoute } from "../../module/announcements/page/edit/AnnouncementsEdit.route";
import { forgetPasswordRoute } from "../../module/auth/ForgetPassword/ForgetPassword.route";
import { loginRoute } from "../../module/auth/Login/Login.route";
import { resetPasswordRoute } from "../../module/auth/ResetPassword/ResetPassword.route";
import { resetPasswordUserRoute } from "../../module/auth/ResetPasswordUser/ResetPasswordUser.route";
import { committeeRoute } from "../../module/commitee/Committee.route";
import { committeeCreateRoute } from "../../module/commitee/page/create/CommitteeCreate.route";
import { committeeEditRoute } from "../../module/commitee/page/edit/CommitteeEdit.route";
import { congregationRoute } from "../../module/congregation/Congregation.route";
import { congregationCreateRoute } from "../../module/congregation/page/create/CongregationCreate.route";
import { congregationDetailRoute } from "../../module/congregation/page/detail/CongregationDetail.route";
import { congregationEditRoute } from "../../module/congregation/page/edit/CongregationEdit.route";
import { councilRoute } from "../../module/council/Council.route";
import { councilCreateRoute } from "../../module/council/page/create/CouncilCreate.route";
import { councilEditRoute } from "../../module/council/page/edit/CouncilEdit.route";
import { familyCreateRoute } from "../../module/family/page/create/FamilyCreate.route";
import { familyEditRoute } from "../../module/family/page/edit/FamilyEdit.route";
import { faqRoute } from "../../module/faq/FAQ.route";
import { faqCreateRoute } from "../../module/faq/page/create/FAQCreate.route";
import { faqEditRoute } from "../../module/faq/page/edit/FAQEdit.route";
import { groupCreateRoute } from "../../module/group/page/create/GroupCreate.route";
import { groupEditRoute } from "../../module/group/page/edit/GroupEdit.route";
import { homePlaygroundRoute } from "../../module/home/playground/HomePlayground.route";
import { locationRoute } from "../../module/location/Location.route";
import { logRoute } from "../../module/log/log.route";
import { logUserRoute } from "../../module/logUser/LogUser.route";
import { notFoundRoute } from "../../module/main/page/NotFound/NotFound.route";
import { masterDataRoute } from "../../module/masterData/MasterData.route";
import { newsRoute } from "../../module/news/News.route";
import { newsCreateRoute } from "../../module/news/page/create/NewsCreate.route";
import { newsEditRoute } from "../../module/news/page/edit/NewsEdit.route";
import { placeRoute } from "../../module/place/Place.route";
import { placeCreateRoute } from "../../module/place/page/create/PlaceCreate.route";
import { placeEditRoute } from "../../module/place/page/edit/PlaceEdit.route";
import { placeReservationDetailRoute } from "../../module/placeReservation/page/detail/PlaceReservationDetail.route";
import { placeReservationEditRoute } from "../../module/placeReservation/page/edit/PlaceReservationEdit.route";
import { positionRoute } from "../../module/position/Position.route";
import { positionCreateRoute } from "../../module/position/page/create/PositionCreate.route";
import { positionEditRoute } from "../../module/position/page/edit/PositionEdit.route";
import { reflectionsRoute } from "../../module/reflections/Reflections.route";
import { reflectionsCreateRoute } from "../../module/reflections/page/create/ReflectionsCreate.route";
import { reflectionsEditRoute } from "../../module/reflections/page/edit/ReflectionsEdit.route";
import { regionRoute } from "../../module/region/Region.route";
import { regionCreateRoute } from "../../module/region/page/create/RegionCreate.route";
import { regionDetailRoute } from "../../module/region/page/detail/RegionDetail.route";
import { regionEditRoute } from "../../module/region/page/edit/RegionEdit.route";
import { roomRoute } from "../../module/room/Room.route";
import { roomCreateRoute } from "../../module/room/page/create/RoomCreate.route";
import { roomEditRoute } from "../../module/room/page/edit/RoomEdit.route";
import { sacramentRoute } from "../../module/sacrament/Sacrament.route";
import { sacramentDetailRoute } from "../../module/sacrament/page/detail/SacramentDetail.route";
import { sacramentEditRoute } from "../../module/sacrament/page/edit/SacramentEdit.route";
import { sacramentTNCEditRoute } from "../../module/sacramentTNC/page/edit/SacramentTNCEdit.route";
import { settingsRoute } from "../../module/settings/Settings.route";
import { subCongregationCreateRoute } from "../../module/subCongregation/page/create/SubCongregationCreate.route";
import { subCongregationEditRoute } from "../../module/subCongregation/page/edit/SubCongregationEdit.route";
import { userRoute } from "../../module/user/User.route";
import { userCreateRoute } from "../../module/user/page/create/UserCreate.route";
import { userEditRoute } from "../../module/user/page/edit/UserEdit.route";
import { userManagementRoute } from "../../module/userManagement/userManagement.route";

type RouteOptions = {
  label: string;
  testID: string;
  icon?: React.ReactNode;
  hasDivider?: boolean;
  hasSub?: RouteConfig[];
};

export type RouteConfig = BreadcrumbsRoute & {
  path: string;
  name: string;
  Component: ReturnType<typeof React.lazy> | undefined;
  NormalComponent?: JSX.Element;
  isPrivate: boolean;
  options?: RouteOptions;
  activePath?: string[];
  customNavigation?: () => void;
};

export type DefaultRouteParam = Params<string>;

export const locationSubConfig: RouteConfig[] = [placeRoute];

export const masterDataSubConfig: RouteConfig[] = [
  newsRoute,
  reflectionsRoute,
  roomRoute,
  sacramentRoute,
  faqRoute,
];

export const logSubConfig: RouteConfig[] = [logUserRoute];

export const userManagementSubConfig: RouteConfig[] = [userRoute, regionRoute];

export const agendaSubConfig: RouteConfig[] = [
  agendaRolesRoute,
  agendaEventRoute,
  agendaSubjectRoute,
  agendaScheduleRoute,
  agendaAssignmentRoute,
];

export const administrationSubConfig: RouteConfig[] = [
  congregationRoute,
  positionRoute,
  committeeRoute,
  councilRoute,
];

export const sidebarConfig: RouteConfig[] = [
  userManagementRoute,
  masterDataRoute,
  locationRoute,
  administrationRoute,
  agendaRoute,
  logRoute,
];

export default [
  loginRoute,
  resetPasswordRoute,
  resetPasswordUserRoute,
  forgetPasswordRoute,
  notFoundRoute,
  homePlaygroundRoute,
  announcementsCreateRoute,
  announcementsEditRoute,
  newsCreateRoute,
  newsEditRoute,
  reflectionsEditRoute,
  reflectionsCreateRoute,
  userCreateRoute,
  userEditRoute,
  regionCreateRoute,
  regionEditRoute,
  regionDetailRoute,
  groupCreateRoute,
  groupEditRoute,
  sacramentEditRoute,
  sacramentDetailRoute,
  sacramentTNCEditRoute,
  faqCreateRoute,
  faqEditRoute,
  settingsRoute,
  familyCreateRoute,
  familyEditRoute,
  congregationCreateRoute,
  congregationEditRoute,
  congregationDetailRoute,
  subCongregationCreateRoute,
  subCongregationEditRoute,
  positionCreateRoute,
  positionEditRoute,
  committeeEditRoute,
  committeeCreateRoute,
  councilEditRoute,
  councilCreateRoute,
  roomEditRoute,
  roomCreateRoute,
  agendaRolesEditRoute,
  agendaRolesCreateRoute,
  agendaEventCreateRoute,
  agendaEventEditRoute,
  agendaSubjectCreateRoute,
  agendaSubjectEditRoute,
  agendaScheduleCreateRoute,
  agendaScheduleDetailRoute,
  agendaScheduleEditRoute,
  agendaAttendanceCreateRoute,
  agendaAttendanceEditRoute,
  agendaAssignmentCreateRoute,
  agendaAssignmentEditRoute,
  placeCreateRoute,
  placeEditRoute,
  placeReservationDetailRoute,
  placeReservationEditRoute,
  ...locationSubConfig,
  ...userManagementSubConfig,
  ...masterDataSubConfig,
  ...administrationSubConfig,
  ...agendaSubConfig,
  ...logSubConfig,
  ...sidebarConfig,
] as RouteConfig[];
