import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  viewBox?: string;
};

export default function Error({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = 'currentColor',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.6382 18.3288L13.5282 4.10287C13.3937 3.79756 13.1809 3.53426 12.9118 3.34032C12.6428 3.14638 12.3273 3.02887 11.9982 3C11.6708 3.0306 11.3575 3.14891 11.0903 3.34275C10.8231 3.5366 10.6119 3.79897 10.4782 4.10287L2.3582 18.3288C1.5182 19.796 2.2082 21 3.8782 21H20.1182C21.7882 21 22.4782 19.796 21.6382 18.3288ZM12.9982 17.9646H10.9982V15.941H12.9982V17.9646ZM12.9982 13.9174H10.9982V7.84654H12.9982V13.9174Z" />
    </svg>
  );
}
