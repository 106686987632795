// #region IMPORT
import urlcat from "urlcat";
import {
  IAgendaSubjectAutocompleteListApiRequest,
  IAgendaSubjectAutocompleteListApiResponse,
  IAgendaSubjectCreateApiRequest,
  IAgendaSubjectCreateApiResponse,
  IAgendaSubjectDeleteApiRequest,
  IAgendaSubjectDeleteApiResponse,
  IAgendaSubjectDetailApiRequest,
  IAgendaSubjectDetailApiResponse,
  IAgendaSubjectListApiRequest,
  IAgendaSubjectListApiResponse,
  IAgendaSubjectUpdateApiRequest,
  IAgendaSubjectUpdateApiResponse,
} from "../../../../model/agendaSubject/agendaSubject.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type AgendaSubjectEndpoint = {
  getAgendaSubject: QD<
    IAgendaSubjectListApiRequest,
    IAgendaSubjectListApiResponse
  >;
  getAgendaSubjectDetail: QD<
    IAgendaSubjectDetailApiRequest,
    IAgendaSubjectDetailApiResponse
  >;
  getAgendaSubjectAutocomplete: QD<
    IAgendaSubjectAutocompleteListApiRequest,
    IAgendaSubjectAutocompleteListApiResponse
  >;
  createAgendaSubject: MD<
    IAgendaSubjectCreateApiRequest,
    IAgendaSubjectCreateApiResponse
  >;
  updateAgendaSubject: MD<
    IAgendaSubjectUpdateApiRequest,
    IAgendaSubjectUpdateApiResponse
  >;
  deleteAgendaSubject: MD<
    IAgendaSubjectDeleteApiRequest,
    IAgendaSubjectDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const agendaSubjectTagTypes: IEndpointTags<AgendaSubjectEndpoint> = {
  getAgendaSubject: "get-agenda-subject",
  getAgendaSubjectDetail: "get-agenda-subject-detail",
  getAgendaSubjectAutocomplete: "get-agenda-subject-autocomplete",
  createAgendaSubject: "create-agenda-subject",
  updateAgendaSubject: "update-agenda-subject",
  deleteAgendaSubject: "delete-agenda-subject",
};

export const agendaSubjectEndpoint = (
  builder: Builder
): AgendaSubjectEndpoint => ({
  getAgendaSubject: builder.query({
    query: (param) => urlcat("/agenda-subjects", param),
    providesTags: [agendaSubjectTagTypes.getAgendaSubject],
  }),
  getAgendaSubjectDetail: builder.query({
    query: ({ id }) => ({ url: `/agenda-subjects/${id}` }),
    providesTags: [agendaSubjectTagTypes.getAgendaSubjectDetail],
  }),
  getAgendaSubjectAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/agenda-subjects`, params),
    }),
    providesTags: [agendaSubjectTagTypes.getAgendaSubjectAutocomplete],
  }),
  createAgendaSubject: builder.mutation({
    query: (body) => ({
      url: "/agenda-subjects",
      method: "POST",
      body,
    }),
    invalidatesTags: [agendaSubjectTagTypes.getAgendaSubject],
  }),
  updateAgendaSubject: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/agenda-subjects/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [agendaSubjectTagTypes.getAgendaSubject],
  }),
  deleteAgendaSubject: builder.mutation({
    query: ({ id }) => ({
      url: `/agenda-subjects/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [agendaSubjectTagTypes.getAgendaSubject],
  }),
});
// #endregion
