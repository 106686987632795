import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IRegion } from "../../../../model/region/region.model";

export type RegionEditRouteParam = {
  region: IRegion;
};

export const regionEditRoute: RouteConfig = {
  name: "Edit Region",
  Component: React.lazy(() => import("./RegionEdit.view")),
  path: "/user-management/region/edit/:id",
  isPrivate: true,
};
