import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type CouncilCreateRouteParam = DefaultRouteParam;

export const councilCreateRoute: RouteConfig = {
  name: "Create council",
  Component: React.lazy(() => import("./CouncilCreate.view")),
  path: "/administration/council/create",
  isPrivate: true,
};
