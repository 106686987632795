import { Locale } from "../locale.config";

const settingsLocaleConfig: Locale[] = [
  {
    en: "Settings",
    id: "Pengaturan",
  },
  {
    en: "Submit",
    id: "Kirim",
  },
  {
    en: "Password",
    id: "Kata Sandi",
  },
  {
    en: "Mailing Services",
    id: "Layanan Email",
  },
  {
    en: "Invalid email format",
    id: "Format email tidak valid",
  },
  {
    en: "This email address will be used for receiving sacraments and place reservations.",
    id: "Alamat email ini akan digunakan untuk menerima pengajuan pelayanan dan reservasi tempat.",
  },
  {
    en: "Back to Login",
    id: "Kembali ke Halaman Login",
  },
  {
    en: "Reset Password",
    id: "Reset Kata Sandi",
  },
  {
    en: "Reset Password User",
    id: "Reset Kata Sandi Umat",
  },
  {
    en: "Forget Password",
    id: "Lupa Kata Sandi",
  },
  {
    en: "Forget Password?",
    id: "Lupa Kata Sandi?",
  },
  {
    en: "Reset Password Success.",
    id: "Reset Kata Sandi Berhasil.",
  },
  {
    en: "Failed to Reset Password.",
    id: "Gagal Mereset Kata Sandi.",
  },
  {
    en: "Password must be at least 8 characters.",
    id: "Kata Sandi minimal 8 karakter.",
  },
  {
    en: "Password not match",
    id: "Kata Sandi tidak cocok",
  },
  {
    en: "Confirm Password is required.",
    id: "Konfirmasi Kata Sandi wajib diisi.",
  },
  {
    en: "Confirm Password",
    id: "Konfirmasi Kata Sandi",
  },
  {
    en: "Invalid email format.",
    id: "Format email tidak valid.",
  },
  {
    en: "Setting",
    id: "Pengaturan",
  },
  {
    en: "Forget Password success, please check your email inbox.",
    id: "lupa kata sandi berhasil, silahkan cek kotak masuk email anda.",
  },
  {
    en: "Church Information",
    id: "Informasi Gereja",
  },
  {
    en: "Application",
    id: "Aplikasi",
  },
  {
    en: "Church Contact",
    id: "Kontak Gereja",
  },
  {
    en: "Vision",
    id: "Visi",
  },
  {
    en: "Mission",
    id: "Misi",
  },
  {
    en: "Greeting",
    id: "Sambutan",
  },
  {
    en: "Greeting is required.",
    id: "Sambutan wajib diisi.",
  },
  {
    en: "Phone Number is required.",
    id: "Nomor Telepon wajib diisi.",
  },
  {
    en: "Website Link is required.",
    id: "Alamat Website wajib diisi.",
  },
  {
    en: "Map",
    id: "Peta",
  },
  {
    en: "Address is required.",
    id: "Alamat wajib diisi.",
  },
  {
    en: "Map (Embed Url From Google Maps)",
    id: "Peta (Embed Url dari Google Maps)",
  },
  {
    en: "Map is required.",
    id: "Peta wajib diisi",
  },
  {
    en: "Annual Theme",
    id: "Tema Tahunan",
  },
  {
    en: "Vision is required.",
    id: "Visi wajib diisi.",
  },
  {
    en: "Mission is required.",
    id: "Misi wajib diisi.",
  },
  {
    en: "Annual Theme is required.",
    id: "Tema Tahunan wajib diisi.",
  },
  {
    en: "Settings successfully updated",
    id: "Pengaturan berhasil diubah",
  },
  {
    en: "Whatsapp Number",
    id: "Nomor Whatsapp",
  },
  {
    en: "Whatsapp Number is required.",
    id: "Nomor Whatsapp wajib diisi.",
  },
  {
    en: "Latitude",
    id: "Garis Lintang",
  },
  {
    en: "Latitude is required.",
    id: "Garis Lintang wajib diisi.",
  },
  {
    en: "Latitude must be a number.",
    id: "Garis Lintang harus berisi angka.",
  },
  {
    en: "Latitude must be valid.",
    id: "Garis Lintang harus sesuai.",
  },
  {
    en: "Longitude",
    id: "Garis Bujur",
  },
  {
    en: "Longitude is required.",
    id: "Garis Bujur wajib diisi.",
  },
  {
    en: "Longitude must be a number.",
    id: "Garis Bujur harus berisi angka.",
  },
  {
    en: "Longitude must be valid.",
    id: "Garis Bujur harus sesuai.",
  },
  {
    en: "Token is not valid or already expired.",
    id: "Token tidak valid atau sudah kadaluarsa.",
  },
];

export default settingsLocaleConfig;
