// #region IMPORT
import urlcat from "urlcat";
import {
  IReflectionsCreateApiRequest,
  IReflectionsCreateApiResponse,
  IReflectionsDeleteApiRequest,
  IReflectionsDeleteApiResponse,
  IReflectionsDetailApiRequest,
  IReflectionsDetailApiResponse,
  IReflectionsListApiRequest,
  IReflectionsListApiResponse,
  IReflectionsUpdateApiRequest,
  IReflectionsUpdateApiResponse,
} from "../../../../model/reflections/reflections.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type ReflectionsEndpoint = {
  getReflections: QD<IReflectionsListApiRequest, IReflectionsListApiResponse>;
  getReflectionsDetail: QD<
    IReflectionsDetailApiRequest,
    IReflectionsDetailApiResponse
  >;
  createReflections: MD<
    IReflectionsCreateApiRequest,
    IReflectionsCreateApiResponse
  >;
  updateReflections: MD<
    IReflectionsUpdateApiRequest,
    IReflectionsUpdateApiResponse
  >;
  deleteReflections: MD<
    IReflectionsDeleteApiRequest,
    IReflectionsDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const reflectionsTagTypes: IEndpointTags<ReflectionsEndpoint> = {
  getReflections: "get-reflections",
  getReflectionsDetail: "get-reflections-detail",
  createReflections: "create-reflections",
  updateReflections: "update-reflections",
  deleteReflections: "delete-reflections",
};

export const reflectionsEndpoint = (builder: Builder): ReflectionsEndpoint => ({
  getReflections: builder.query({
    query: (param) => urlcat("/reflections", param),
    providesTags: [reflectionsTagTypes.getReflections],
  }),
  getReflectionsDetail: builder.query({
    query: ({ id }) => ({
      url: `/reflections/${id}`,
    }),
    providesTags: [reflectionsTagTypes.getReflectionsDetail],
  }),
  createReflections: builder.mutation({
    query: (body) => ({
      url: "/reflections",
      method: "POST",
      body,
    }),
    invalidatesTags: [reflectionsTagTypes.getReflections],
  }),
  updateReflections: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/reflections/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [
      reflectionsTagTypes.getReflections,
      reflectionsTagTypes.getReflectionsDetail,
    ],
  }),
  deleteReflections: builder.mutation({
    query: ({ id }) => ({
      url: `/reflections/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [reflectionsTagTypes.getReflections],
  }),
});
// #endregion
