// #region IMPORT
import urlcat from "urlcat";
import {
  INewsCreateApiRequest,
  INewsCreateApiResponse,
  INewsDeleteApiRequest,
  INewsDeleteApiResponse,
  INewsDetailApiRequest,
  INewsDetailApiResponse,
  INewsListApiRequest,
  INewsListApiResponse,
  INewsUpdateApiRequest,
  INewsUpdateApiResponse,
} from "../../../../model/news/news.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type NewsEndpoint = {
  getNews: QD<INewsListApiRequest, INewsListApiResponse>;
  getNewsDetail: QD<INewsDetailApiRequest, INewsDetailApiResponse>;
  createNews: MD<INewsCreateApiRequest, INewsCreateApiResponse>;
  updateNews: MD<INewsUpdateApiRequest, INewsUpdateApiResponse>;
  deleteNews: MD<INewsDeleteApiRequest, INewsDeleteApiResponse>;
};
// #endregion

// #region ENDPOINT
export const newsTagTypes: IEndpointTags<NewsEndpoint> = {
  getNews: "get-news",
  getNewsDetail: "get-news-detail",
  createNews: "create-news",
  updateNews: "update-news",
  deleteNews: "delete-news",
};

export const newsEndpoint = (builder: Builder): NewsEndpoint => ({
  getNews: builder.query({
    query: (param) => urlcat("/news", param),
    providesTags: [newsTagTypes.getNews],
  }),
  getNewsDetail: builder.query({
    query: ({ id }) => ({
      url: `/news/${id}`,
    }),
    providesTags: [newsTagTypes.getNewsDetail],
  }),
  createNews: builder.mutation({
    query: (body) => ({
      url: "/news",
      method: "POST",
      body,
    }),
    invalidatesTags: [newsTagTypes.getNews],
  }),
  updateNews: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/news/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [newsTagTypes.getNews, newsTagTypes.getNewsDetail],
  }),
  deleteNews: builder.mutation({
    query: ({ id }) => ({
      url: `/news/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [newsTagTypes.getNews],
  }),
});
// #endregion
