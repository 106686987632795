import { setDefaultOptions } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import id from 'date-fns/locale/id';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store/store.app';
import { SupportedLanguage } from '../../../service/locale/locale.config';

export default function useLocaleUpdate() {
  const currentLangData = useSelector((state: RootState) => state.setting.currentLanguage);

  useEffect(() => {
    setDefaultOptions({
      locale: currentLangData === SupportedLanguage.en ? enUS : id,
    });
  }, [currentLangData]);
}
