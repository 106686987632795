import { Locale } from "../locale.config";

const agendaEventLocaleConfig: Locale[] = [
  {
    en: "Agenda Event",
    id: "Kegiatan",
  },
  {
    en: "Agenda Event Detail",
    id: "Detail Kegiatan",
  },
  {
    en: "Add Agenda Event",
    id: "Tambah Kegiatan",
  },
  {
    en: "Speaker Name",
    id: "Pembawa Firman",
  },
  {
    en: "Theme",
    id: "Tema",
  },
  {
    en: "Agenda Type is required.",
    id: "Jenis Kegiatan wajib diisi.",
  },
  {
    en: "Update Agenda Event",
    id: "Ubah Kegiatan",
  },
  {
    en: "Select Agenda Types",
    id: "Pilih Jenis Kegiatan",
  },
  {
    en: "Delete Agenda Event",
    id: "Hapus Kegiatan",
  },
  {
    en: "Are you sure you want to delete agenda Event? Deleted Agenda Event cannot be undone.",
    id: "Anda yakin menghapus Kegiatan? Kegiatan yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Agenda Event Successfully Deleted",
    id: "Kegiatan Berhasil dihapus",
  },
  {
    en: "Failed to Delete Agenda Event",
    id: "Gagal Menghapus Kegiatan",
  },
  {
    en: "Agenda Event successfully created",
    id: "Kegiatan berhasil dibuat",
  },
  {
    en: "Failed to create agenda Event",
    id: "Gagal membuat Kegiatan",
  },
  {
    en: "Agenda Event successfully updated",
    id: "Kegiatan berhasil diubah",
  },
  {
    en: "Failed to update agenda Event",
    id: "Gagal mengubah Kegiatan",
  },
  {
    en: "Try adding Agenda Event by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default agendaEventLocaleConfig;
