import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type SacramentRouteParam = DefaultRouteParam;

export const sacramentRoute: RouteConfig = {
  name: "Sacrament",
  Component: React.lazy(() => import("./Sacrament.view")),
  path: "/master-data/sacrament",
  isPrivate: true,

  options: {
    label: "Sacrament",
    testID: "MenuSacrament",
  },
};
