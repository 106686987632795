import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import tw from "twin.macro";
import LoadingIndicator from "../../component/atom/LoadingIndicator/LoadingIndicator.atom";
import ErrorBoundaryFallback from "../../component/organism/ErrorBoundaryFallback/ErrorBoundaryFallback.organism";
import WrapperView from "../../module/main/page/Wrapper/Wrapper.view";
import { captureError } from "../../service/errorMonitoring/errorMonitoring.service";
import RouterApp from "../router/router.app";
import store, { persistor } from "../store/store.app";
import ErrorBoundary from "./ErrorBoundary.app";

// #region STYLED
const SuspenseFallbackContainer = tw.div`bg-secondary-50 flex justify-center items-center min-h-screen min-w-full`;
// #endregion

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Suspense
            fallback={
              <SuspenseFallbackContainer>
                <div>
                  <LoadingIndicator size="large" />
                </div>
              </SuspenseFallbackContainer>
            }
          >
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onError={(error, info) => {
                captureError(
                  `Uncaught error: ${error.name}`,
                  {},
                  { error, info }
                );
              }}
            >
              <WrapperView>
                <RouterApp />
              </WrapperView>
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
