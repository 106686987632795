import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type LogUserRouteParam = DefaultRouteParam;

export const logUserRoute: RouteConfig = {
  name: "LogUser",
  Component: React.lazy(() => import("./LogUser.view")),
  path: "/log/user",
  isPrivate: true,

  options: {
    label: "Log Umat",
    testID: "MenuLogUser",
  },
};
