import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { RootState } from "../../app/store/store.app";
import { SnackbarTheme } from "../../component/molecule/Snackbar/Snackbar.molecule";
import envConfig from "../../config/env/env.config";
import { IAuthRefreshResponse } from "../../model/auth/auth.model";
import { toCamelCase } from "../../util/api/helperApi.util";
import { toTranslate } from "../../util/common/helperCommon.util";
import { captureError } from "../errorMonitoring/errorMonitoring.service";
import {
  LogoutReason,
  sessionAction,
} from "../reducer/store/session/session.store";
import { snackbarAction } from "../reducer/store/snackbar/snackbar.store";

export type BaseQuery = BaseQueryFn<
  string | FetchArgs | undefined,
  unknown,
  FetchBaseQueryError
>;
export type QD<R, S> = QueryDefinition<R, BaseQuery, string, S, "api">;
export type MD<R, S> = MutationDefinition<R, BaseQuery, string, S, "api">;
export type Builder = EndpointBuilder<BaseQuery, string, "api">;

/**
 * HTTP headers must be adjusted based on the API requirements.
 * @param headers
 * @param api
 * @returns
 */
export const prepareHeaders = (
  headers: Headers,
  api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">
): Headers => {
  const { accessToken } = (api.getState() as RootState).session;
  headers.set("X-Org-Key", envConfig.apiKey);

  if (accessToken) {
    headers.set("authorization", `Bearer ${accessToken}`);
  }
  return headers;
};

/**
 * Adjust API_BASE_URL in environment variable file
 */
export const base = fetchBaseQuery({
  baseUrl: envConfig.apiBaseUrl,
  prepareHeaders,
});

/**
 * Base query of the API service.
 * If response is 401 It will try to get new token and refetch the query.
 * To avoid multiple failed request, it used mutex to lock the process and unlock it after renew token completed.
 * @param args
 * @param api
 * @param extraOptions
 * @returns
 */
const mutex = new Mutex();
const baseQuery: BaseQuery = async (args, api, extraOptions) => {
  let result = await base(args || "", api, extraOptions);

  if (result.error && result?.error?.status !== 401) {
    captureError(`API error: ${api.endpoint}`, args, result);
  }

  if (!window.navigator.onLine) {
    const { currentLanguage } = (api.getState() as RootState).setting;

    api.dispatch(
      snackbarAction.show({
        type: SnackbarTheme.warning,
        message: toTranslate("No internet connection", currentLanguage),
      })
    );
  }
  const sessionData = (api.getState() as RootState)?.session;

  if (result?.error?.status === 401 && sessionData.isLoggedIn) {
    if (mutex.isLocked()) {
      await mutex.waitForUnlock();
      result = await base(args || "", api, extraOptions);
    } else {
      const release = await mutex.acquire();
      try {
        const req = await fetch(`${envConfig.apiBaseUrl}auth/refresh`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionData.refreshToken || ""}`,
            "X-Org-Key": envConfig.apiKey,
          },
        });
        const reqJSON: IAuthRefreshResponse = toCamelCase(await req.json());

        if (reqJSON.success && reqJSON.payload) {
          api.dispatch(
            sessionAction.changeSession({
              ...reqJSON.payload,
            })
          );
          result = await base(args || "", api, extraOptions);
        } else {
          api.dispatch(
            sessionAction.logout({ type: LogoutReason.REVOKE, token: "" })
          );
        }
      } catch (err) {
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: "Session Expired, Please Re-Login.",
        });
        api.dispatch(
          sessionAction.logout({ type: LogoutReason.REVOKE, token: "" })
        );
      } finally {
        release();
      }
    }
  }

  return result;
};

export default baseQuery;
