import { Locale } from "../locale.config";

const placesLocaleConfig: Locale[] = [
  {
    en: "Place",
    id: "Tempat",
  },
  {
    en: "Add Place",
    id: "Tambah Tempat",
  },
  {
    en: "Update Place",
    id: "Ubah Tempat",
  },
  {
    en: "Operational Time",
    id: "Jam Operasional",
  },
  {
    en: "Place Type",
    id: "Tipe Tempat",
  },
  {
    en: "Place Type is required.",
    id: "Tipe Tempat wajib diisi.",
  },
  {
    en: "Select Place Type",
    id: "Pilih Tipe Tempat",
  },
  {
    en: "Photos",
    id: "Foto",
  },
  {
    en: "Optional Form",
    id: "Form Opsional",
  },
  {
    en: "Open Time",
    id: "Waktu Buka",
  },
  {
    en: "Open Time - Close Time",
    id: "Waktu Buka - Waktu Tutup",
  },
  {
    en: "Order Status",
    id: "Status Pemesanan",
  },
  {
    en: "Bookable",
    id: "Dapat Dipinjam",
  },
  {
    en: "Not Bookable",
    id: "Tidak Dapat Dipinjam",
  },
  {
    en: "Operational Day (Select All Operational Day)",
    id: "Hari Operasional (Pilih Semua Hari Di mana Tempat Beroperasi)",
  },
  {
    en: "Monday",
    id: "Senin",
  },
  {
    en: "Tuesday",
    id: "Selasa",
  },
  {
    en: "Wednesday",
    id: "Rabu",
  },
  {
    en: "Thursday",
    id: "Kamis",
  },
  {
    en: "Friday",
    id: "Jumat",
  },
  {
    en: "Saturday",
    id: "Sabtu",
  },
  {
    en: "Sunday",
    id: "Minggu",
  },
  {
    en: "Google Maps Link",
    id: "Link Google Maps",
  },
  {
    en: "PIC Email",
    id: "Email Penanggung Jawab",
  },
  {
    en: "Place successfully updated",
    id: "Tempat berhasil diubah",
  },
  {
    en: "Place successfully created",
    id: "Tempat berhasil dibuat",
  },
  {
    en: "Failed to update place",
    id: "Gagal mengubah tempat",
  },
  {
    en: "Failed to create place",
    id: "Gagal membuat tempat",
  },
  {
    en: "Can not input more than 255 characters",
    id: "Max 255 karakter",
  },
  {
    en: "Place Successfully Deleted",
    id: "Tempat Berhasil Dihapus",
  },
  {
    en: "Failed to Delete Place",
    id: "Gagal Menghapus tempat",
  },
  {
    en: "Are you sure you want to delete place? Deleted Place cannot be undone.",
    id: "Anda yakin menghapus tempat? Tempat yang dihapus tidak dapat diubah kembali.",
  },
];

export default placesLocaleConfig;
