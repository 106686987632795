import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IPosition } from "../../../../model/position/position.model";

export type PositionEditRouteParam = {
  position: IPosition;
};

export const positionEditRoute: RouteConfig = {
  name: "Edit Position",
  Component: React.lazy(() => import("./PositionEdit.view")),
  path: "/administration/position/edit/:id",
  isPrivate: true,
};
