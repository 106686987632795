import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarTheme } from '../../../../component/molecule/Snackbar/Snackbar.molecule';

// #region INTERFACES
interface ISnackbarData {
  type?: SnackbarTheme;
  message: string;
}

interface ISnackbarOptions {
  autoHide?: boolean;
  withClose?: boolean;
}

export interface ISnackbarStore {
  options: ISnackbarOptions;
  data?: ISnackbarData;
}

interface SnackbarShowAction extends ISnackbarData, ISnackbarOptions {}
// #endregion

const initialState: ISnackbarStore = {
  options: {
    autoHide: true,
    withClose: true,
  },
  data: undefined,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    show: (_state, { payload }: PayloadAction<SnackbarShowAction>) => ({
      options: {
        autoHide: payload.autoHide,
        withClose: payload.withClose,
      },
      data: { type: payload.type, message: payload.message },
    }),
    hide: (state) => ({
      ...state,
      data: undefined,
    }),
  },
});

export const snackbarAction = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;
