import { Locale } from "../locale.config";

const committeeLocaleConfig: Locale[] = [
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Name is required.",
    id: "Nama wajib diisi",
  },
  {
    en: "Committee",
    id: "Pengurus Organisasi",
  },
  {
    en: "Committee Detail",
    id: "Detail Pengurus Organisasi",
  },
  {
    en: "Add Committee",
    id: "Tambah Pengurus Organisasi",
  },
  {
    en: "Update Committee",
    id: "Ubah Pengurus Organisasi",
  },
  {
    en: "Delete Committee",
    id: "Hapus Pengurus Organisasi",
  },
  {
    en: "Are you sure you want to delete committee? Deleted Committee cannot be undone.",
    id: "Anda yakin menghapus pengurus Organisasi? Pengurus Organisasi yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Committee Successfully Deleted",
    id: "Pengurus Organisasi Berhasil dihapus",
  },
  {
    en: "Failed to Delete Committee",
    id: "Gagal Menghapus Pengurus Organisasi",
  },
  {
    en: "Committee successfully created",
    id: "Pengurus Organisasi berhasil dibuat",
  },
  {
    en: "Failed to create committee",
    id: "Gagal membuat pengurus Organisasi",
  },
  {
    en: "Committee successfully updated",
    id: "Pengurus Organisasi berhasil diubah",
  },
  {
    en: "Failed to update committee",
    id: "Gagal mengubah pengurus Organisasi",
  },
  {
    en: "Congregation is required.",
    id: "Organisasi wajib diisi.",
  },
  {
    en: "Sub Congregation is required.",
    id: "Sub Organisasi wajib diisi.",
  },
  {
    en: "Select Position",
    id: "Pilih Jabatan",
  },
  {
    en: "Decree",
    id: "Surat Keputusan",
  },
  {
    en: "Start Date",
    id: "Tanggal Mulai",
  },
  {
    en: "End Date",
    id: "Tanggal Selesai",
  },
  {
    en: "Period",
    id: "Periode",
  },
  {
    en: "Period is required.",
    id: "Periode wajib diisi.",
  },
  {
    en: "Position is required.",
    id: "Jabatan wajib diisi.",
  },
  {
    en: "User is required.",
    id: "Jamaah wajib diisi.",
  },
  {
    en: "Start Date is required.",
    id: "Tanggal Mulai wajib diisi.",
  },
  {
    en: "End Date is required.",
    id: "Tanggal Selesai wajib diisi.",
  },
  {
    en: "Try adding Committee by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },

  {
    en: "De-activate Committee",
    id: "Nonaktifkan Pengurus Organisasi",
  },
  {
    en: "Are you sure you want to De-activate committee?",
    id: "Apakah anda yakin akan Menonaktifkan Pengurus Organisasi?",
  },
  {
    en: "Activate Committee",
    id: "Aktifkan Pengurus Organisasi",
  },
  {
    en: "Committee Successfully De-activate",
    id: "Pengurus Organisasi berhasil di Nonaktifkan.",
  },
  {
    en: "Committee Successfully Activate",
    id: "Pengurus Organisasi berhasil di Aktifkan.",
  },
  {
    en: "Failed to De-activate Committee",
    id: "Gagal Menonaktifkan Pengurus Organisasi.",
  },
  {
    en: "Failed to Activate Committee",
    id: "Ggal Mengaktifkan Pengurus Organisasi.",
  },
  {
    en: "Activate",
    id: "Aktifkan",
  },
  {
    en: "De-activate",
    id: "Nonaktifkan",
  },
  {
    en: "Are you sure you want to Activate committee?",
    id: "Apakah anda yakin akan Mengaktifkan Pengurus Organisasi?",
  },
];

export default committeeLocaleConfig;
