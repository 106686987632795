import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type AgendaScheduleCreateRouteParam = DefaultRouteParam;

export const agendaScheduleCreateRoute: RouteConfig = {
  name: "Create Agenda Schedule",
  Component: React.lazy(() => import("./AgendaScheduleCreate.view")),
  path: "/agenda/schedule/create",
  isPrivate: true,
};
