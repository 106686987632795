import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type FamilyCreateRouteParam = DefaultRouteParam;

export const familyCreateRoute: RouteConfig = {
  name: "Create Family",
  Component: React.lazy(() => import("./FamilyCreate.view")),
  path: "/user-management/family/create",
  isPrivate: true,
};
