import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type AgendaSubjectCreateRouteParam = DefaultRouteParam;

export const agendaSubjectCreateRoute: RouteConfig = {
  name: "Create Agenda Subject",
  Component: React.lazy(() => import("./AgendaSubjectCreate.view")),
  path: "/agenda/subject/create",
  isPrivate: true,
};
