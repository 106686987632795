import { Locale } from "../locale.config";

const agendaTypesLocaleConfig: Locale[] = [
  {
    en: "Agenda Types",
    id: "Jenis Kegiatan",
  },
  {
    en: "Agenda Types Detail",
    id: "Detail Jenis Kegiatan",
  },
  {
    en: "Add Agenda Types",
    id: "Tambah Jenis Kegiatan",
  },
  {
    en: "Update Agenda Types",
    id: "Ubah Jenis Kegiatan",
  },
  {
    en: "Delete Agenda Types",
    id: "Hapus Jenis Kegiatan",
  },
  {
    en: "Are you sure you want to delete agenda Types? Deleted Agenda Types cannot be undone.",
    id: "Anda yakin menghapus jenis Kegiatan? Jenis Kegiatan yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Agenda Types Successfully Deleted",
    id: "Jenis Kegiatan Berhasil dihapus",
  },
  {
    en: "Failed to Delete Agenda Types",
    id: "Gagal Menghapus Jenis Kegiatan",
  },
  {
    en: "Agenda Types successfully created",
    id: "Jenis Kegiatan berhasil dibuat",
  },
  {
    en: "Failed to create agenda Types",
    id: "Gagal membuat jenis Kegiatan",
  },
  {
    en: "Agenda Types successfully updated",
    id: "Jenis Kegiatan berhasil diubah",
  },
  {
    en: "Failed to update agenda Types",
    id: "Gagal mengubah jenis Kegiatan",
  },
  {
    en: "Try adding Agenda Types by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default agendaTypesLocaleConfig;
