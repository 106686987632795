export enum ErrorCodes {
  FILEPOND_PROGRESS_ERROR = 'FILEPOND_PROGRESS_ERROR',
  BAD_CREDENTIAL = 'BAD_CREDENTIAL',
  MAX_CONCURRENT_LOGIN = 'MAX_CONCURRENT_LOGIN',
  MAX_ATTEMPT_REACHED = 'MAX_ATTEMPT_REACHED',
  INVALID_REQUEST_PARAM = 'INVALID_REQUEST_PARAM',
  CONFLICT = 'CONFLICT',
}

export const ErrorMessages = {
  [ErrorCodes.FILEPOND_PROGRESS_ERROR]: 'Internal Filepond Progress Error. Here we go again...',
  [ErrorCodes.BAD_CREDENTIAL]: 'Invalid username or password',
  [ErrorCodes.MAX_CONCURRENT_LOGIN]: 'Your account already logged in',
  [ErrorCodes.MAX_ATTEMPT_REACHED]: 'Too many login attempts',
  [ErrorCodes.INVALID_REQUEST_PARAM]: 'Invalid request param',
  [ErrorCodes.CONFLICT]: 'Data already exists',
};
