import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { ICommittee } from "../../../../model/committee/committee.model";

export type CommitteeEditRouteParam = {
  committee: ICommittee;
};

export const committeeEditRoute: RouteConfig = {
  name: "Edit Committee",
  Component: React.lazy(() => import("./CommitteeEdit.view")),
  path: "/administration/committee/edit/:id",
  isPrivate: true,
};
