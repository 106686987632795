import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type RoomRouteParam = DefaultRouteParam;

export const roomRoute: RouteConfig = {
  name: "Room",
  Component: React.lazy(() => import("./Room.view")),
  path: "/master-data/room",
  isPrivate: true,

  options: {
    label: "Room",
    testID: "MenuRoom",
  },
};
