import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { INews } from "../../../../model/news/news.model";

export type NewsEditRouteParam = {
  news: INews;
};

export const newsEditRoute: RouteConfig = {
  name: "Edit News",
  Component: React.lazy(() => import("./NewsEdit.view")),
  path: "/master-data/news/edit/:id",
  isPrivate: true,
};
