import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IAgendaSubject } from "../../../../model/agendaSubject/agendaSubject.model";

export type AgendaSubjectEditRouteParam = {
  agendaSubject: IAgendaSubject;
};

export const agendaSubjectEditRoute: RouteConfig = {
  name: "Edit Agenda Subject",
  Component: React.lazy(() => import("./AgendaSubjectEdit.view")),
  path: "/agenda/subject/edit/:id",
  isPrivate: true,
};
