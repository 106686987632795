import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../config/router/router.config";

export type ResetPasswordUserRouteParam = DefaultRouteParam;

export const resetPasswordUserRoute: RouteConfig = {
  name: "Reset Password User",
  path: "/reset-password-user",
  Component: React.lazy(() => import("./ResetPasswordUser.view")),
  isPrivate: false,
  options: {
    label: "resetPasswordUser",
    testID: "MenuResetPasswordUser",
  },
};
