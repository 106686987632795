import { configureStore } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createLogger } from 'redux-logger';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PersistedState,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import persistConfig from '../../config/persist/persist.config';
import api from '../../service/api/api.service';
import reducerConfig from '../../service/reducer/reducer.config';

export const devTools = process.env.NODE_ENV !== 'production';
export const persistedReducer = persistReducer(persistConfig, reducerConfig);
export const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  api.middleware,
  ...(devTools ? [createLogger({ collapsed: true })] : []),
];

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof reducerConfig> & PersistedState;
export const persistor = persistStore(store);
export default store;
