import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  viewBox?: string;
};

export default function Burger({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = '#4D4D4D',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
    >
      <g mask="url(#mask0_49_2230)">
        <path fill={fill} d="M3 18v-2h18v2H3zm0-5v-2h18v2H3zm0-5V6h18v2H3z" />
      </g>
    </svg>
  );
}
