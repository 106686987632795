import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type PlaceReservationRouteParam = DefaultRouteParam;

export const placeReservationRoute: RouteConfig = {
  name: "Reservation",
  Component: React.lazy(() => import("./PlaceReservation.view")),
  path: "/location/reservation",
  isPrivate: true,

  options: {
    label: "Reservation",
    testID: "MenuPlaceReservation",
  },
};
