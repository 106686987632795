import { Locale } from "../locale.config";

const errorsLocaleConfig: Locale[] = [
  {
    en: "Copy",
    id: "Salin",
  },
  {
    en: "Sorry, we have encountered a problem.",
    id: "Maaf, kami mengalami masalah.",
  },
  {
    en: "Refresh App",
    id: "Muat Ulang Aplikasi",
  },
  {
    en: "Reload App",
    id: "Muat Ulang Aplikasi",
  },
  {
    en: "Invalid username or password",
    id: "Username atau password salah",
  },
  {
    en: "Your account already logged in",
    id: "Akun Anda sedang digunakan",
  },
  {
    en: "Too many login attempts",
    id: "Terlalu banyak gagal login",
  },
  {
    en: "Email is required.",
    id: "Email harus diisi.",
  },
  {
    en: "Password is required.",
    id: "Kata Sandi harus diisi.",
  },
  {
    en: "Please fill in input name",
    id: "Mohon diisi input nama",
  },
  {
    en: "Please fill in input description",
    id: "Mohon diisi input deskripsi",
  },
  {
    en: "Please choose the 1:1 image",
    id: "Banner 1:1 wajib diisi",
  },
  {
    en: "Please upload the 1:1 image",
    id: "Banner 1:1 wajib diunggah",
  },
  {
    en: "Please choose the 4:3 image",
    id: "Banner 4:3 wajib diisi",
  },
  {
    en: "Please upload the 4:3 image",
    id: "Banner 4:3 wajib diunggah",
  },
  {
    en: "Please choose min 1 merchant",
    id: "Mohon pilih minimal 1 merchant",
  },
  {
    en: "Please choose the point expired date",
    id: "Mohon pilih tanggal expired penggunaan point",
  },
  {
    en: "Please fill in input value",
    id: "Mohon isi input",
  },
  {
    en: "Please input a valid number",
    id: "Mohon input angka valid",
  },
  {
    en: "Min start date should be today",
    id: "Minimal tanggal mulai dari hari ini",
  },
  {
    en: "Max 255 characters",
    id: "Maksimal 255 karakter",
  },
  {
    en: "Max 5000 characters",
    id: "Maksimal 5000 karakter",
  },
  {
    en: "Can not input less than equal 0",
    id: "Tidak bisa input kurang dari sama dengan 0",
  },
  {
    en: "Can not input less than equal 0%",
    id: "Tidak bisa input kurang dari sama dengan0%",
  },
  {
    en: "Can not input more than",
    id: "Tidak bisa input lebih dari",
  },
  {
    en: "Can not input more than 100%",
    id: "Tidak bisa input lebih dari 100%",
  },
  {
    en: "Can not input more than 1.000.000",
    id: "Tidak bisa input lebih dari 1.000.000",
  },
  {
    en: "Can not input more than 1.000.000.000",
    id: "Tidak bisa input lebih dari 1.000.000.000",
  },
  {
    en: "Can not input more than 1.000.000.000.000",
    id: "Tidak bisa input lebih dari 1.000.000.000.000",
  },
  {
    en: "Event name is required",
    id: "Nama event wajib diisi",
  },
  {
    en: "Description is required",
    id: "Deskripsi wajib diisi",
  },
  {
    en: "Stock is required",
    id: "Stok wajib diisi",
  },
  {
    en: "Price is required",
    id: "Harga wajib diisi",
  },
  {
    en: "Expired in days is required",
    id: "Expired dalam hari wajib diisi",
  },
  {
    en: "Please choose the 1:1 goods image",
    id: "Foto barang 1:1 wajib diisi",
  },
  {
    en: "Please upload the 1:1 goods image",
    id: "Foto barang 1:1 wajib diunggah",
  },
  {
    en: "Page Not Found",
    id: "Halaman Tidak Ditemukan",
  },
  {
    en: "Either the URL you requested was not found on this server or you don't have permission to access this page.",
    id: "URL yang Anda minta tidak ditemukan di server ini atau Anda tidak memiliki izin untuk mengakses halaman ini.",
  },
  {
    en: "Go back to Customer List",
    id: "Kembali ke Daftar Customer",
  },
  {
    en: "Go back to Event List",
    id: "Kembali ke Daftar Event",
  },
  {
    en: "Go back to Merchant List",
    id: "Kembali ke Daftar Merchant",
  },
  {
    en: "Go back to Reward List",
    id: "Kembali ke Daftar Reward",
  },
  {
    en: "Can not input less than",
    id: "Tidak bisa input kurang dari",
  },
  {
    en: "Can not input more than",
    id: "Tidak bisa input lebih dari",
  },
  {
    en: "characters",
    id: "karakter",
  },
  {
    en: "character",
    id: "karakter",
  },
  {
    en: "Transaction Code is required",
    id: "Transaction Code wajib diisi",
  },
  {
    en: "Label is required",
    id: "Label wajib diisi",
  },
  {
    en: "Transaction Code is required",
    id: "Transaction Code wajib diisi",
  },
  {
    en: "Failed to reject reward approval",
    id: "Gagal menolak persetujuan reward",
  },
  {
    en: "Failed to update reward approval",
    id: "Gagal memperbarui persetujuan reward",
  },
  {
    en: "No internet connection",
    id: "Tidak ada koneksi internet",
  },
  {
    en: "Sorry, we have encountered a problem.",
    id: "Maaf, kami mengalami masalah.",
  },
  {
    en: "Network error",
    id: "Terjadi kesalahan pada jaringan",
  },
  {
    en: "Mobile number is invalid.",
    id: "Nomor Telepon tidak valid",
  },
  {
    en: "Phone number is invalid.",
    id: "Nomor Telepon tidak valid",
  },
  {
    en: "Phone number maximum 12 digits.",
    id: "Nomor Telepon maksimal 12 digit",
  },
  {
    en: "Phone number must be at least 9 digits.",
    id: "Nomor Telepon paling sedikit 9 digit",
  },
  {
    en: "Phone number start with 0.",
    id: "Nomor Telepon dimulai dengan angka 0.",
  },
  {
    en: "Telephone number is invalid.",
    id: "Nomor Telepon tidak valid",
  },
];

export default errorsLocaleConfig;
