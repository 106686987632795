// #region IMPORT

import {
  ISettingAppGetApiRequest,
  ISettingAppGetApiResponse,
  ISettingAppUpdateApiRequest,
  ISettingAppUpdateApiResponse,
  ISettingContactGetApiRequest,
  ISettingContactGetApiResponse,
  ISettingContactUpdateApiRequest,
  ISettingContactUpdateApiResponse,
  ISettingEmailGetApiRequest,
  ISettingEmailGetApiResponse,
  ISettingEmailUpdateApiRequest,
  ISettingEmailUpdateApiResponse,
  ISettingInfoGetApiRequest,
  ISettingInfoGetApiResponse,
  ISettingInfoUpdateApiRequest,
  ISettingInfoUpdateApiResponse,
} from "../../../../model/settings/settings.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type SettingsEndpoint = {
  getSettingInfo: QD<ISettingInfoGetApiRequest, ISettingInfoGetApiResponse>;
  getSettingEmail: QD<ISettingEmailGetApiRequest, ISettingEmailGetApiResponse>;
  getSettingApp: QD<ISettingAppGetApiRequest, ISettingAppGetApiResponse>;
  getSettingContact: QD<
    ISettingContactGetApiRequest,
    ISettingContactGetApiResponse
  >;
  updateSettingInfo: MD<
    ISettingInfoUpdateApiRequest,
    ISettingInfoUpdateApiResponse
  >;
  updateSettingApp: MD<
    ISettingAppUpdateApiRequest,
    ISettingAppUpdateApiResponse
  >;
  updateSettingContact: MD<
    ISettingContactUpdateApiRequest,
    ISettingContactUpdateApiResponse
  >;
  updateSettingEmail: MD<
    ISettingEmailUpdateApiRequest,
    ISettingEmailUpdateApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const settingsTagTypes: IEndpointTags<SettingsEndpoint> = {
  getSettingInfo: "get-setting-info",
  getSettingApp: "get-setting-app",
  getSettingEmail: "get-setting-email",
  getSettingContact: "get-setting-contact",
  updateSettingInfo: "update-setting-info",
  updateSettingApp: "update-setting-app",
  updateSettingContact: "update-setting-contact",
  updateSettingEmail: "update-setting-email",
};

export const settingsEndpoint = (builder: Builder): SettingsEndpoint => ({
  getSettingInfo: builder.query({
    query: () => "/settings/info",
    providesTags: [settingsTagTypes.getSettingInfo],
  }),
  getSettingApp: builder.query({
    query: () => "/settings/app",
    providesTags: [settingsTagTypes.getSettingApp],
  }),
  getSettingEmail: builder.query({
    query: () => "/settings/emails",
    providesTags: [settingsTagTypes.getSettingEmail],
  }),
  getSettingContact: builder.query({
    query: () => "/settings/contact",
    providesTags: [settingsTagTypes.getSettingContact],
  }),
  updateSettingInfo: builder.mutation({
    query: (body) => ({
      url: "/settings/info",
      method: "PUT",
      body,
    }),
    invalidatesTags: [settingsTagTypes.getSettingInfo],
  }),
  updateSettingApp: builder.mutation({
    query: (body) => ({
      url: "/settings/app",
      method: "PUT",
      body,
    }),
    invalidatesTags: [settingsTagTypes.getSettingApp],
  }),
  updateSettingContact: builder.mutation({
    query: (body) => ({
      url: "/settings/contact",
      method: "PUT",
      body,
    }),
    invalidatesTags: [settingsTagTypes.getSettingContact],
  }),
  updateSettingEmail: builder.mutation({
    query: (body) => ({
      url: "/settings/emails",
      method: "PUT",
      body,
    }),
    invalidatesTags: [settingsTagTypes.getSettingEmail],
  }),
});
// #endregion
