import agendaAssignmentLocaleConfig from "./translation/agendaAssignment.locale.config";
import agendaAttendanceLocaleConfig from "./translation/agendaAttendance.locale.config";
import agendaEventLocaleConfig from "./translation/agendaEvent.locale.config";
import agendaRolesLocaleConfig from "./translation/agendaRoles.locale.config";
import agendaScheduleLocaleConfig from "./translation/agendaSchedule.locale.config";
import agendaSubjectLocaleConfig from "./translation/agendaSubject.locale.config";
import agendaTypesLocaleConfig from "./translation/agendaTypes.locale.config";
import announcementsLocaleConfig from "./translation/announcements.locale.config";
import committeeLocaleConfig from "./translation/committee.locale.config";
import commonLocaleConfig from "./translation/common.locale.config";
import congregationLocaleConfig from "./translation/congregation.locale.config";
import councilLocaleConfig from "./translation/council.locale.config";
import errorsLocaleConfig from "./translation/errors.locale.config";
import familyLocaleConfig from "./translation/family.locale.config";
import faqLocaleConfig from "./translation/faq.locale.config";
import groupLocaleConfig from "./translation/group.locale.config";
import locationsLocaleConfig from "./translation/locations.locale.config";
import newsLocaleConfig from "./translation/news.locale.config";
import placesLocaleConfig from "./translation/places.locale.config";
import positionLocaleConfig from "./translation/position.locale.config";
import reflectionsLocaleConfig from "./translation/reflections.locale.config";
import regionLocaleConfig from "./translation/region.locale.config";
import reservationsLocaleConfig from "./translation/reservation.locale.config";
import roomLocaleConfig from "./translation/room.locale.config";
import sacramentLocaleConfig from "./translation/sacrament.locale.config";
import settingsLocaleConfig from "./translation/settings.locale.config";
import subCongregationLocaleConfig from "./translation/subCongregation.locale.config";
import usersLocaleConfig from "./translation/users.locale.config";

export enum SupportedLanguage {
  en = "en",
  id = "id",
}

export type Locale = {
  [key in SupportedLanguage]: string;
};

const localeConfig: Locale[] = [
  ...commonLocaleConfig,
  ...errorsLocaleConfig,
  ...newsLocaleConfig,
  ...reflectionsLocaleConfig,
  ...usersLocaleConfig,
  ...regionLocaleConfig,
  ...groupLocaleConfig,
  ...sacramentLocaleConfig,
  ...faqLocaleConfig,
  ...settingsLocaleConfig,
  ...familyLocaleConfig,
  ...positionLocaleConfig,
  ...congregationLocaleConfig,
  ...subCongregationLocaleConfig,
  ...committeeLocaleConfig,
  ...councilLocaleConfig,
  ...roomLocaleConfig,
  ...agendaTypesLocaleConfig,
  ...agendaRolesLocaleConfig,
  ...agendaEventLocaleConfig,
  ...agendaSubjectLocaleConfig,
  ...agendaScheduleLocaleConfig,
  ...agendaAttendanceLocaleConfig,
  ...agendaAssignmentLocaleConfig,
  ...locationsLocaleConfig,
  ...placesLocaleConfig,
  ...announcementsLocaleConfig,
  ...reservationsLocaleConfig,
];

export default localeConfig;
