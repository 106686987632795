import { Locale } from "../locale.config";

const councilLocaleConfig: Locale[] = [
  {
    en: "Name",
    id: "Nama",
  },
  {
    en: "Name is required.",
    id: "Nama wajib diisi",
  },
  {
    en: "Council",
    id: "Pengurus Majelis",
  },
  {
    en: "Council Detail",
    id: "Detail Pengurus Majelis",
  },
  {
    en: "Add Council",
    id: "Tambah Pengurus Majelis",
  },
  {
    en: "Update Council",
    id: "Ubah Pengurus Majelis",
  },
  {
    en: "Delete Council",
    id: "Hapus Pengurus Majelis",
  },
  {
    en: "Are you sure you want to delete council? Deleted Council cannot be undone.",
    id: "Anda yakin menghapus pengurus Majelis? Pengurus Majelis yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Council Successfully Deleted",
    id: "Pengurus Majelis Berhasil dihapus",
  },
  {
    en: "Failed to Delete Council",
    id: "Gagal Menghapus Pengurus Majelis",
  },
  {
    en: "Council successfully created",
    id: "Pengurus Majelis berhasil dibuat",
  },
  {
    en: "Failed to create council",
    id: "Gagal membuat pengurus Majelis",
  },
  {
    en: "Council successfully updated",
    id: "Pengurus Majelis berhasil diubah",
  },
  {
    en: "Failed to update council",
    id: "Gagal mengubah pengurus Majelis",
  },
  {
    en: "Congregation is required.",
    id: "Majelis wajib diisi.",
  },
  {
    en: "Sub Congregation is required.",
    id: "Sub Majelis wajib diisi.",
  },
  {
    en: "Select Position",
    id: "Pilih Jabatan",
  },
  {
    en: "Decree",
    id: "Surat Keputusan",
  },
  {
    en: "Start Date",
    id: "Tanggal Mulai",
  },
  {
    en: "End Date",
    id: "Tanggal Selesai",
  },
  {
    en: "Period",
    id: "Periode",
  },
  {
    en: "Period is required.",
    id: "Periode wajib diisi.",
  },
  {
    en: "Position is required.",
    id: "Jabatan wajib diisi.",
  },
  {
    en: "User is required.",
    id: "Jamaah wajib diisi.",
  },
  {
    en: "Start Date is required.",
    id: "Tanggal Mulai wajib diisi.",
  },
  {
    en: "De-activate Council",
    id: "Nonaktifkan Pengurus Majelis",
  },
  {
    en: "Are you sure you want to De-activate council?",
    id: "Apakah anda yakin akan Menonaktifkan Pengurus Majelis?",
  },
  {
    en: "Activate Council",
    id: "Aktifkan Pengurus Majelis",
  },
  {
    en: "Council Successfully De-activate",
    id: "Pengurus Majelis berhasil di Nonaktifkan.",
  },
  {
    en: "Council Successfully Activate",
    id: "Pengurus Majelis berhasil di Aktifkan.",
  },
  {
    en: "Failed to De-activate Council",
    id: "Gagal Menonaktifkan Pengurus Majelis.",
  },
  {
    en: "Failed to Activate Council",
    id: "Gagal Mengaktifkan Pengurus Majelis.",
  },
  {
    en: "Activate",
    id: "Aktifkan",
  },
  {
    en: "De-activate",
    id: "Nonaktifkan",
  },
  {
    en: "Are you sure you want to Activate council?",
    id: "Apakah anda yakin akan Mengaktifkan Pengurus Majelis?",
  },
  {
    en: "End Date is required.",
    id: "Tanggal Selesai wajib diisi.",
  },
  {
    en: "Try adding Council by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default councilLocaleConfig;
