import tw, { styled } from 'twin.macro';

export const baseSans = tw`font-normal text-black font-sans`;
export const baseInter = tw`font-normal text-black font-inter`;
export const buttonLarge = tw`text-sm font-bold`;
export const buttonSmall = tw`text-xs leading-5 font-bold`;

export const NumberLarge = styled.span(() => [baseSans, tw`text-[28px] leading-9`]);
export const DisplayLarge = styled.span(() => [baseSans, tw`text-8xl leading-[115px]`]);
export const DisplayMedium = styled.span(() => [baseSans, tw`text-6xl leading-[72px]`]);
export const DisplaySmall = styled.span(() => [baseSans, tw`text-5xl leading-[57px]`]);
export const HeadlineLarge = styled.span(() => [baseSans, tw`text-[34px] leading-[40px]`]);
export const HeadlineMedium = styled.span(() => [baseSans, tw`text-2xl leading-8`]);
export const HeadlineSmall = styled.span(() => [baseSans, tw`text-xl leading-7`]);
export const SubtitleLarge = styled.span(() => [baseSans, tw`text-base leading-[22px]`]);
export const SubtitleSmall = styled.span(() => [baseSans, tw`text-sm font-bold leading-[22px]`]);
export const BodyLarge = styled.span(() => [baseInter, tw`text-base`]);
export const BodySmall = styled.span(() => [baseInter, tw`text-sm`]);
export const ButtonLarge = styled.span(() => [baseInter, buttonLarge]);
export const ButtonSmall = styled.span(() => [baseInter, buttonSmall]);
export const Caption = styled.span(() => [baseInter, tw`text-xs`]);
export const Overline = styled.span(() => [baseInter, tw`text-xs font-medium tracking-[2px]`]);
export const NumberTable = styled.span(() => [baseSans], tw`text-sm leading-5`);

// TODO: [deprecated] Change all component using these style below with the new style above
export const HeadingThree = tw.span`font-sans font-semibold text-black [font-size:21px] leading-9`;
export const HeadingFour = tw.span`font-sans font-semibold text-black text-base`;
export const HeadingFive = tw.span`font-sans font-semibold text-black text-sm leading-6`;
export const BodyOne = tw.span`font-sans font-normal text-black text-base [line-height:26px] [letter-spacing:0.2px]`;
export const BodyFourteen = tw.span`text-black font-normal text-sm leading-6`;
export const BodyTwelve = tw.span`text-black font-normal text-xs leading-5 [letter-spacing:0.2px]`;
export const BodySixteen = tw.span`text-black font-normal text-base [line-height:26px] [letter-spacing:0.2px]`;
export const BodyTen = tw.span`font-sans font-normal [font-size:10px] [line-height:18px] [letter-spacing:0.5px] text-black`;
export const Label = tw.span`font-sans font-normal text-sm leading-6 text-gray-600`;
export const LabelPrimaryBold = tw.span`font-sans font-semibold text-sm leading-6 text-primary-500`;
export const Paragraph = tw.p`font-sans font-normal text-sm leading-6 text-gray-600`;
export const TableParagraph = tw.p`font-sans font-normal text-base leading-5 text-black`;
export const TableParagraphPrimary = tw.p`font-sans font-semibold text-sm leading-6 text-primary-500`;
