// #region IMPORT
import urlcat from "urlcat";
import {
  IAgendaRolesAutocompleteListApiRequest,
  IAgendaRolesAutocompleteListApiResponse,
  IAgendaRolesCreateApiRequest,
  IAgendaRolesCreateApiResponse,
  IAgendaRolesDeleteApiRequest,
  IAgendaRolesDeleteApiResponse,
  IAgendaRolesListApiRequest,
  IAgendaRolesListApiResponse,
  IAgendaRolesUpdateApiRequest,
  IAgendaRolesUpdateApiResponse,
} from "../../../../model/agendaRoles/agendaRoles.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type AgendaRolesEndpoint = {
  getAgendaRoles: QD<IAgendaRolesListApiRequest, IAgendaRolesListApiResponse>;
  getAgendaRolesAutocomplete: QD<
    IAgendaRolesAutocompleteListApiRequest,
    IAgendaRolesAutocompleteListApiResponse
  >;
  createAgendaRoles: MD<
    IAgendaRolesCreateApiRequest,
    IAgendaRolesCreateApiResponse
  >;
  updateAgendaRoles: MD<
    IAgendaRolesUpdateApiRequest,
    IAgendaRolesUpdateApiResponse
  >;
  deleteAgendaRoles: MD<
    IAgendaRolesDeleteApiRequest,
    IAgendaRolesDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const agendaRolesTagRoles: IEndpointTags<AgendaRolesEndpoint> = {
  getAgendaRoles: "get-agenda-roles",
  getAgendaRolesAutocomplete: "get-agenda-roles-autocomplete",
  createAgendaRoles: "create-agenda-roles",
  updateAgendaRoles: "update-agenda-roles",
  deleteAgendaRoles: "delete-agenda-roles",
};

export const agendaRolesEndpoint = (builder: Builder): AgendaRolesEndpoint => ({
  getAgendaRoles: builder.query({
    query: (param) => urlcat("/agenda-roles", param),
    providesTags: [agendaRolesTagRoles.getAgendaRoles],
  }),
  getAgendaRolesAutocomplete: builder.query({
    query: (params) => ({
      url: urlcat(`/auto-complete/agenda-roles`, params),
    }),
    providesTags: [agendaRolesTagRoles.getAgendaRolesAutocomplete],
  }),
  createAgendaRoles: builder.mutation({
    query: (body) => ({
      url: "/agenda-roles",
      method: "POST",
      body,
    }),
    invalidatesTags: [agendaRolesTagRoles.getAgendaRoles],
  }),
  updateAgendaRoles: builder.mutation({
    query: ({ id, ...body }) => ({
      url: `/agenda-roles/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [agendaRolesTagRoles.getAgendaRoles],
  }),
  deleteAgendaRoles: builder.mutation({
    query: ({ id }) => ({
      url: `/agenda-roles/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [agendaRolesTagRoles.getAgendaRoles],
  }),
});
// #endregion
