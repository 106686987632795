import { Locale } from "../locale.config";

const agendaAssignmentLocaleConfig: Locale[] = [
  {
    en: "Agenda Assignment",
    id: "Penugasan",
  },
  {
    en: "Agenda Assignment Detail",
    id: "Detail Penugasan",
  },
  {
    en: "Add Agenda Assignment",
    id: "Tambah Penugasan",
  },
  {
    en: "Speaker Name",
    id: "Pembawa Firman",
  },
  {
    en: "Theme",
    id: "Tema",
  },
  {
    en: "Role",
    id: "Peran",
  },
  {
    en: "Select Users",
    id: "Pilih Umat",
  },
  {
    en: "Select Agenda Roles",
    id: "Pilih Peran Kegiatan",
  },
  {
    en: "Agenda Type is required.",
    id: "Jenis Penugasan wajib diisi.",
  },
  {
    en: "Agenda Schedule is required.",
    id: "Jadwal wajib diisi.",
  },
  {
    en: "Role is required.",
    id: "Peran wajib diisi.",
  },
  {
    en: "Select Agenda Schedule",
    id: "Pilih Jadwal",
  },
  {
    en: "Update Agenda Assignment",
    id: "Ubah Penugasan",
  },
  {
    en: "Select Agenda Types",
    id: "Pilih Jenis Penugasan",
  },
  {
    en: "Delete Agenda Assignment",
    id: "Hapus Penugasan",
  },
  {
    en: "Are you sure you want to delete agenda Assignment? Deleted Agenda Assignment cannot be undone.",
    id: "Anda yakin menghapus Penugasan? Penugasan yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Agenda Assignment Successfully Deleted",
    id: "Penugasan Berhasil dihapus",
  },
  {
    en: "Failed to Delete Agenda Assignment",
    id: "Gagal Menghapus Penugasan",
  },
  {
    en: "Agenda Assignment successfully created",
    id: "Penugasan berhasil dibuat",
  },
  {
    en: "Failed to create agenda Assignment",
    id: "Gagal membuat Penugasan",
  },
  {
    en: "Agenda Assignment successfully updated",
    id: "Penugasan berhasil diubah",
  },
  {
    en: "Failed to update agenda Assignment",
    id: "Gagal mengubah Penugasan",
  },
  {
    en: "Try adding Agenda Assignment by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan Penugasan, atau klik tombol di bawah",
  },
];

export default agendaAssignmentLocaleConfig;
