import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type AgendaAttendanceCreateRouteParam = DefaultRouteParam;

export const agendaAttendanceCreateRoute: RouteConfig = {
  name: "Create Attendance",
  Component: React.lazy(() => import("./AgendaAttendanceCreate.view")),
  path: "/agenda/schedule/detail/:agendaScheduleId/create",
  isPrivate: true,
};
