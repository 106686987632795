import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type CommitteeRouteParam = DefaultRouteParam;

export const committeeRoute: RouteConfig = {
  name: "Committee",
  Component: React.lazy(() => import("./Committee.view")),
  path: "/administration/committee",
  isPrivate: true,

  options: {
    label: "Committee",
    testID: "MenuCommittee",
  },
};
