import React, { useRef, useState } from "react";
import tw, { styled } from "twin.macro";
import useTranslator from "../../../hook/common/useTranslator/useTranslator.hook";
import Divider from "../../atom/Divider/Divider.atom";
import ChevronSharp from "../../atom/Icon/SVG/ChevronSharp";
import { Text } from "../../atom/Text";
import Tooltip from "../Tooltip/Tooltip.molecule";

export interface ItemProps {
  onClick(path: string): void;
  path: string;
  isSelected: boolean;
  label?: string;
  icon?: React.ReactNode;
  testID?: string;
}

interface Props extends ItemProps {
  expandedSidebar: boolean;
  hasSub?: ItemProps[] | undefined;
  noIcon?: boolean;
}

// #region STYLE
const WrapperContainer = styled.div(
  ({
    isSelected,
    isSidebarExpanded,
  }: {
    isSelected?: boolean;
    isSidebarExpanded?: boolean;
  }) => [
    tw`mx-4`,
    !isSidebarExpanded && tw`w-[50px] `,
    isSelected &&
      tw`bg-primary-500 rounded-tl-lg rounded-bl-lg rounded-tr-xl rounded-br-xl`,
    !isSelected && tw`hover:bg-accent-50 rounded`,
  ]
);
const Container = styled.div(
  ({
    isSelected,
    isSidebarExpanded,
    hasSub,
  }: {
    isSelected?: boolean;
    isSidebarExpanded?: boolean;
    hasSub?: boolean;
  }) => [
    tw`relative pl-2.5 ml-1 text-gray-600 rounded py-1`,
    !isSidebarExpanded && tw`rounded`,
    tw`hover:(bg-accent-50)`,
    `div > div > svg > g > path{
    fill:#4D4D4D
  }`,
    isSelected && !hasSub && tw`bg-accent-50`,
    isSelected &&
      `div > div > svg > g > path{
      fill:#175882
    }`,
  ]
);
const SubContainer = styled.ul(
  ({ expanded, numItems }: { expanded: boolean; numItems: number }) => [
    tw`h-0 origin-top duration-200 overflow-hidden hidden`,
    expanded && `height: calc(${numItems * 48}px)`,
    expanded && tw`block`,
  ]
);

const ItemContainer = tw.div`flex items-center [min-height:40px] gap-3.5 cursor-pointer overflow-hidden`;
const ItemIcon = tw.div`w-6 shrink-0 items-center h-full`;
const ItemLabel = tw(
  Text.ButtonLarge
)`text-current flex-auto [flex-basis:0] overflow-hidden flex-wrap [min-width:150px]`;
const DividerContainer = styled.div(
  ({ expandedSidebar }: { expandedSidebar?: boolean }) => [
    tw`pl-1 `,
    expandedSidebar && tw`pl-16`,
  ]
);
const ItemSubIcon = styled.span(({ expanded }: { expanded: boolean }) => [
  tw`absolute right-2 [top:calc(50%_-_7px)] rotate-90 `,
  expanded && tw`duration-200 [transform:rotate(270deg)]`,
]);

// #endregion

// #region RENDER ITEM
function Item({
  isSelected,
  hasSub,
  path,
  testID,
  icon,
  label,
  onClick,
  expandSubMenu,
  expandedMenu,
  noIcon,
  setExpandSubMenu,
}: Omit<Props, "expandedSidebar"> & {
  expandedMenu?: boolean;
  expandSubMenu?: boolean;
  noIcon?: boolean;
  setExpandSubMenu?(expanded: boolean): void;
}) {
  const tooltipRef = useRef(null);
  const [visible, setVisible] = useState(false);

  return (
    <WrapperContainer
      ref={tooltipRef}
      onMouseEnter={() => {
        if (!expandedMenu) setVisible(true);
      }}
      onMouseLeave={() => {
        if (!expandedMenu) setVisible(false);
      }}
      isSidebarExpanded={expandedMenu}
      isSelected={isSelected && !hasSub}
    >
      <Container
        key={`NavItemContainer_${testID as string}`}
        isSelected={isSelected}
        css={[isSelected && !hasSub && tw`bg-accent-50`]}
        data-testid={testID}
        hasSub={!!hasSub?.length}
        onClick={() =>
          hasSub && typeof setExpandSubMenu === "function"
            ? setExpandSubMenu(!expandSubMenu)
            : onClick(path)
        }
      >
        <ItemContainer>
          {!noIcon && <ItemIcon>{icon}</ItemIcon>}
          {label && (
            <ItemLabel css={[isSelected && tw`text-primary-500`]}>
              {label}
            </ItemLabel>
          )}
        </ItemContainer>

        {hasSub?.length && (
          <ItemSubIcon expanded={!!expandSubMenu}>
            <ChevronSharp
              fill="none"
              width={16}
              height={16}
              stroke={isSelected ? "#175882" : "#4D4D4D"}
            />
          </ItemSubIcon>
        )}
      </Container>
      <Tooltip
        containerStyle={[tw`z-30`]}
        targetRef={tooltipRef}
        visible={visible}
        contentStyle={tw`p-2 flex`}
        variant="dark"
        placement="right"
        withPortal
      >
        <Text.Caption tw="text-gray-50">{label}</Text.Caption>
      </Tooltip>
    </WrapperContainer>
  );
}
// #endregion

// #region RENDER DIVIDER
function DividerItem({
  testID,
  hasDivider,
  expandedSidebar,
}: {
  testID?: string;
  hasDivider?: boolean;
  expandedSidebar?: boolean;
}) {
  return hasDivider && testID ? (
    <DividerContainer
      key={`SidebarItemDivider-${testID}`}
      expandedSidebar={expandedSidebar}
    >
      <Divider />
    </DividerContainer>
  ) : null;
}
// #endregion

export default function SidebarItem({
  onClick,
  isSelected,
  icon,
  path,
  label,
  testID,
  hasSub,
  expandedSidebar,
  noIcon = false,
}: Props) {
  const [expandSubMenu, setExpandSubMenu] = useState<boolean>(false);
  const translator = useTranslator();
  return (
    <>
      <Item
        onClick={onClick}
        expandedMenu={expandedSidebar}
        expandSubMenu={expandSubMenu}
        isSelected={isSelected}
        hasSub={hasSub}
        noIcon={noIcon}
        icon={icon}
        path={path}
        testID={testID}
        setExpandSubMenu={setExpandSubMenu}
        label={label ? translator.translate(label) : undefined}
      />

      {hasSub?.length && (
        <SubContainer expanded={expandSubMenu} numItems={hasSub.length}>
          {hasSub.map((item) => (
            <Item
              expandedMenu={expandedSidebar}
              key={`sidebarSubItem-${testID || "sidebarID"}-${
                item.testID || "sidebarSubID"
              }`}
              onClick={onClick}
              isSelected={item.isSelected}
              icon={item.icon}
              path={item.path}
              label={item.label ? translator.translate(item.label) : undefined}
              testID={item.testID}
            />
          ))}
        </SubContainer>
      )}

      <DividerItem testID={testID} expandedSidebar={expandedSidebar} />
    </>
  );
}
