import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { ISacrament } from "../../../../model/sacrament/sacrament.model";

export type SacramentDetailRouteParam = {
  sacrament: ISacrament;
};

export const sacramentDetailRoute: RouteConfig = {
  name: "Detail Sacrament",
  Component: React.lazy(() => import("./SacramentDetail.view")),
  path: "/master-data/sacrament/:id",
  isPrivate: true,
};
