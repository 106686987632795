import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type AgendaSubjectRouteParam = DefaultRouteParam;

export const agendaSubjectRoute: RouteConfig = {
  name: "Agenda Subject",
  Component: React.lazy(() => import("./AgendaSubject.view")),
  path: "/agenda/subject/",
  isPrivate: true,

  options: {
    label: "Agenda Subject",
    testID: "MenuAgendaSubject",
  },
};
