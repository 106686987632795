// #region IMPORT
import urlcat from "urlcat";
import {
  IPlaceReservationApprovalRequest,
  IPlaceReservationApprovalResponse,
  IPlaceReservationDeleteRequest,
  IPlaceReservationDeleteResponse,
  IPlaceReservationListApiRequest,
  IPlaceReservationListApiResponse,
  IPlaceReservationRejectionRequest,
  IPlaceReservationRejectionResponse,
  IPlaceReservationUpdateApiRequest,
  IPlaceReservationUpdateApiResponse,
} from "../../../../model/placeReservation/placeReservation.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type PlaceReservationEndpoint = {
  getPlaceReservations: QD<
    IPlaceReservationListApiRequest,
    IPlaceReservationListApiResponse
  >;
  updatePlaceReservation: MD<
    IPlaceReservationUpdateApiRequest,
    IPlaceReservationUpdateApiResponse
  >;
  approvePlaceReservation: MD<
    IPlaceReservationApprovalRequest,
    IPlaceReservationApprovalResponse
  >;
  rejectPlaceReservation: MD<
    IPlaceReservationRejectionRequest,
    IPlaceReservationRejectionResponse
  >;
  deletePlaceReservation: MD<
    IPlaceReservationDeleteRequest,
    IPlaceReservationDeleteResponse
  >;
};
// #endregion

// #region ENDPOINT
export const placeReservationTagTypes: IEndpointTags<PlaceReservationEndpoint> =
  {
    getPlaceReservations: "get-placeReservations",
    approvePlaceReservation: "approve-placeReservation",
    rejectPlaceReservation: "reject-placeReservation",
    deletePlaceReservation: "delete-placeReservation",
    updatePlaceReservation: "update-placeReservation",
  };

export const placeReservationEndpoint = (
  builder: Builder
): PlaceReservationEndpoint => ({
  getPlaceReservations: builder.query({
    query: (param) => urlcat("/locations/all/rents", param),
    providesTags: [placeReservationTagTypes.getPlaceReservations],
  }),
  approvePlaceReservation: builder.mutation({
    query: ({ id, locationId }) => ({
      url: `/locations/${locationId}/rents/${id}/accept`,
      method: "PUT",
    }),
    invalidatesTags: [placeReservationTagTypes.getPlaceReservations],
  }),
  rejectPlaceReservation: builder.mutation({
    query: ({ id, locationId, ...body }) => ({
      url: `/locations/${locationId}/rents/${id}/reject`,
      method: "PUT",
      body,
    }),
    invalidatesTags: [placeReservationTagTypes.getPlaceReservations],
  }),
  updatePlaceReservation: builder.mutation({
    query: ({ id, locationId, ...body }) => ({
      url: `/locations/${locationId}/rents/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [placeReservationTagTypes.getPlaceReservations],
  }),
  deletePlaceReservation: builder.mutation({
    query: ({ id, locationId }) => ({
      url: `/locations/${locationId}/rents/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [placeReservationTagTypes.getPlaceReservations],
  }),
});
// #endregion
