import React, { useCallback, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import tw from "twin.macro";
import { RouteConfig } from "../../../../config/router/router.config";
import Burger from "../../../atom/Icon/SVG/Burger";
import { ButtonLarge, Caption } from "../../../atom/Text/Text.atom";
import SidebarItem from "../../../molecule/SidebarItem/SidebarItem.molecule";
import Tooltip from "../../../molecule/Tooltip/Tooltip.molecule";
import ProfileSection from "./section/SidebarProfile/SidebarProfile.section";

type Props = {
  items: RouteConfig[];
  currentPath: string;
  onItemClick(path: string, externalLink?: () => void): void;
  expandedSidebar: boolean;
  onBurgerClick(): void;
};

// #region STYLED
// sidebar
const Container = tw.div`flex flex-col flex-1 h-full`;
const Content = tw.div`gap-2 flex-col flex`;
const Header = tw.div`relative border-b border-b-gray-50 text-gray-600`;
const SidebarItemWrapper = tw.div`w-full rounded-lg my-2 flex-1`;
const ItemContainer = tw.div`flex mx-4 my-2 pl-3.5 items-center [min-height:48px] gap-3.5 cursor-pointer overflow-hidden hover:bg-accent-50 rounded`;
const ItemIcon = tw.div`w-6 shrink-0 items-center h-full`;
const ItemLabel = tw(
  ButtonLarge
)`text-current flex-auto [flex-basis:0] overflow-hidden flex-wrap [min-width:150px]`;

// profile section
// #endregion

export default function Sidebar({
  items,
  currentPath,
  onItemClick,
  onBurgerClick,
  expandedSidebar,
}: Props) {
  const tooltipRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const generateSubMenu = useCallback(
    (subItems: RouteConfig[]) =>
      subItems.map((item: RouteConfig) => ({
        onClick: (path: string) => onItemClick(path, item.customNavigation),
        path: item.path,
        icon: item.options?.icon,
        label: item.options?.label,
        testID: item.options?.testID,
        isSelected: currentPath.includes(item.path),
        hasDivider: item.options?.hasDivider,
      })),
    [currentPath, onItemClick]
  );

  const checkIsSelected = (item: RouteConfig) => {
    if ((!!item.customNavigation && !item.path) || !item.path) return false;
    const currentPathArr = currentPath.split("/");
    const itemPathArr = item.path.split("/");
    return item.activePath
      ? item.activePath?.includes(currentPath)
      : currentPathArr[1] === itemPathArr[1];
  };

  return (
    <Container>
      <Header
        onClick={() => {
          setVisible(false);
          onBurgerClick();
        }}
      >
        <ItemContainer
          ref={tooltipRef}
          onMouseEnter={() => {
            if (!expandedSidebar) setVisible(true);
          }}
          onMouseLeave={() => {
            if (!expandedSidebar) setVisible(false);
          }}
        >
          <ItemIcon>
            <Burger />
          </ItemIcon>
          <ItemLabel>GKJ Joglo</ItemLabel>
        </ItemContainer>

        <Tooltip
          containerStyle={[tw`z-30`]}
          targetRef={tooltipRef}
          visible={visible}
          contentStyle={tw`p-2 flex`}
          variant="dark"
          placement="right"
          withPortal
        >
          <Caption tw="text-gray-50">Buka Menu</Caption>
        </Tooltip>
      </Header>
      <SidebarItemWrapper>
        <Scrollbars
          className="group scrollbarStyle"
          style={{ overflow: "hidden" }}
        >
          <Content>
            {items.map((item: RouteConfig) =>
              item.options ? (
                <SidebarItem
                  key={`SidebarItem-${item.options.testID}`}
                  onClick={(path: string) =>
                    onItemClick(path, item.customNavigation)
                  }
                  path={item.path}
                  label={item.options.label}
                  icon={item.options.icon}
                  testID={item.options.testID}
                  isSelected={checkIsSelected(item)}
                  hasSub={
                    item.options?.hasSub && generateSubMenu(item.options.hasSub)
                  }
                  expandedSidebar={expandedSidebar}
                />
              ) : null
            )}
          </Content>
        </Scrollbars>
      </SidebarItemWrapper>
      <ProfileSection expanded={expandedSidebar} />
    </Container>
  );
}
