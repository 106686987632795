import { Locale } from "../locale.config";

const locationsLocaleConfig: Locale[] = [
  {
    en: "Location",
    id: "Lokasi",
  },
  {
    en: "Add Location",
    id: "Tambah Lokasi",
  },
  {
    en: "Update Location",
    id: "Ubah Lokasi",
  },
  {
    en: "Operational Time",
    id: "Jam Operasional",
  },
  {
    en: "Location Type",
    id: "Tipe Lokasi",
  },
  {
    en: "Location Type is required.",
    id: "Tipe Lokasi wajib diisi.",
  },
  {
    en: "Select Location Type",
    id: "Pilih Tipe Lokasi",
  },
  {
    en: "Photos",
    id: "Foto",
  },
  {
    en: "Open Time",
    id: "Waktu Buka",
  },
  {
    en: "Open Time - Close Time",
    id: "Waktu Buka - Waktu Tutup",
  },
  {
    en: "Order Status",
    id: "Status Pemesanan",
  },
  {
    en: "Bookable",
    id: "Dapat Dipinjam",
  },
  {
    en: "Not Bookable",
    id: "Tidak Dapat Dipinjam",
  },
  {
    en: "Operational Day (Select All Operational Day)",
    id: "Hari Operasional (Pilih Semua Hari Di mana Lokasi Beroperasi)",
  },
  {
    en: "Monday",
    id: "Senin",
  },
  {
    en: "Tuesday",
    id: "Selasa",
  },
  {
    en: "Wednesday",
    id: "Rabu",
  },
  {
    en: "Thursday",
    id: "Kamis",
  },
  {
    en: "Friday",
    id: "Jumat",
  },
  {
    en: "Saturday",
    id: "Sabtu",
  },
  {
    en: "Sunday",
    id: "Minggu",
  },
  {
    en: "Google Maps Link",
    id: "Link Google Maps",
  },
  {
    en: "PIC Email",
    id: "Email Penanggung Jawab",
  },
  {
    en: "Location successfully updated",
    id: "Lokasi berhasil diubah",
  },
  {
    en: "Location successfully created",
    id: "Lokasi berhasil dibuat",
  },
  {
    en: "Failed to update location",
    id: "Gagal mengubah lokasi",
  },
  {
    en: "Failed to create location",
    id: "Gagal membuat lokasi",
  },
  {
    en: "Can not input more than 255 characters",
    id: "Max 255 karakter",
  },
  {
    en: "Location Successfully Deleted",
    id: "Lokasi Berhasil Dihapus",
  },
  {
    en: "Failed to Delete Location",
    id: "Gagal Menghapus lokasi",
  },
  {
    en: "Are you sure you want to delete location? Deleted Location cannot be undone.",
    id: "Anda yakin menghapus lokasi? Lokasi yang dihapus tidak dapat diubah kembali.",
  },
];

export default locationsLocaleConfig;
