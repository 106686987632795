import { Builder } from "./api.baseQuery";
import {
  AgendaAssignmentEndpoint,
  agendaAssignmentEndpoint,
} from "./endpoint/agendaAssignment/agendaAssignment.endpoint";
import {
  AgendaAttendanceEndpoint,
  agendaAttendanceEndpoint,
} from "./endpoint/agendaAttendance/agendaAttendance.endpoint";
import {
  AgendaEventEndpoint,
  agendaEventEndpoint,
} from "./endpoint/agendaEvent/agendaEvent.endpoint";
import {
  AgendaRolesEndpoint,
  agendaRolesEndpoint,
} from "./endpoint/agendaRoles/agendaRoles.endpoint";
import {
  AgendaScheduleEndpoint,
  agendaScheduleEndpoint,
} from "./endpoint/agendaSchedule/agendaSchedule.endpoint";
import {
  AgendaSubjectEndpoint,
  agendaSubjectEndpoint,
} from "./endpoint/agendaSubject/agendaSubject.endpoint";
import {
  AgendaTypesEndpoint,
  agendaTypesEndpoint,
} from "./endpoint/agendaTypes/agendaTypes.endpoint";
import {
  AnnouncementsEndpoint,
  announcementsEndpoint,
} from "./endpoint/announcements/announcements.endpoint";
import { AuthEndpoint, authEndpoint } from "./endpoint/auth/auth.endpoint";
import {
  CommitteeEndpoint,
  committeeEndpoint,
} from "./endpoint/committee/committee.endpoint";
import {
  CongregationEndpoint,
  congregationEndpoint,
} from "./endpoint/congregation/congregation.endpoint";
import {
  CouncilEndpoint,
  councilEndpoint,
} from "./endpoint/council/council.endpoint";
import {
  FamilyEndpoint,
  familyEndpoint,
} from "./endpoint/family/family.endpoint";
import { FAQEndpoint, faqEndpoint } from "./endpoint/faq/faq.endpoint";
import { GroupEndpoint, groupEndpoint } from "./endpoint/group/group.endpoint";
import {
  LocationEndpoint,
  locationEndpoint,
} from "./endpoint/location/location.endpoint";
import { LogEndpoint, logEndpoint } from "./endpoint/log/log.endpoint";
import { NewsEndpoint, newsEndpoint } from "./endpoint/news/news.endpoint";
import {
  OrganizationEndpoint,
  organizationEndpoint,
} from "./endpoint/organization/organization.endpoint";
import {
  PlaceReservationEndpoint,
  placeReservationEndpoint,
} from "./endpoint/placeReservation/placeReservation.endpoint";
import {
  PositionEndpoint,
  positionEndpoint,
} from "./endpoint/position/position.endpoint";
import {
  ReflectionsEndpoint,
  reflectionsEndpoint,
} from "./endpoint/reflections/reflections.endpoint";
import {
  RegionEndpoint,
  regionEndpoint,
} from "./endpoint/region/region.endpoint";
import { RoomEndpoint, roomEndpoint } from "./endpoint/room/room.endpoint";
import {
  SacramentEndpoint,
  sacramentEndpoint,
} from "./endpoint/sacrament/sacrament.endpoint";
import {
  SettingsEndpoint,
  settingsEndpoint,
} from "./endpoint/settings/settings.endpoint";
import {
  SubCongregationEndpoint,
  subCongregationEndpoint,
} from "./endpoint/subCongregation/subCongregation.endpoint";
import { UserEndpoint, userEndpoint } from "./endpoint/user/user.endpoint";

// #region INTERFACES

export type IEndpointTags<T> = Record<keyof T, string>;

// #endregion

export type IEndpoint = AuthEndpoint &
  NewsEndpoint &
  ReflectionsEndpoint &
  UserEndpoint &
  OrganizationEndpoint &
  RegionEndpoint &
  GroupEndpoint &
  SacramentEndpoint &
  FAQEndpoint &
  FamilyEndpoint &
  CongregationEndpoint &
  SubCongregationEndpoint &
  PositionEndpoint &
  SettingsEndpoint &
  CommitteeEndpoint &
  CouncilEndpoint &
  RoomEndpoint &
  AgendaTypesEndpoint &
  AgendaRolesEndpoint &
  AgendaEventEndpoint &
  AgendaSubjectEndpoint &
  AgendaScheduleEndpoint &
  AgendaAttendanceEndpoint &
  AgendaAssignmentEndpoint &
  AnnouncementsEndpoint &
  LocationEndpoint &
  PlaceReservationEndpoint &
  LogEndpoint;

const endpoint = (builder: Builder): IEndpoint => ({
  ...authEndpoint(builder),
  ...newsEndpoint(builder),
  ...reflectionsEndpoint(builder),
  ...userEndpoint(builder),
  ...organizationEndpoint(builder),
  ...regionEndpoint(builder),
  ...groupEndpoint(builder),
  ...sacramentEndpoint(builder),
  ...faqEndpoint(builder),
  ...familyEndpoint(builder),
  ...settingsEndpoint(builder),
  ...congregationEndpoint(builder),
  ...subCongregationEndpoint(builder),
  ...positionEndpoint(builder),
  ...committeeEndpoint(builder),
  ...councilEndpoint(builder),
  ...roomEndpoint(builder),
  ...agendaTypesEndpoint(builder),
  ...agendaRolesEndpoint(builder),
  ...agendaEventEndpoint(builder),
  ...agendaSubjectEndpoint(builder),
  ...agendaScheduleEndpoint(builder),
  ...agendaAttendanceEndpoint(builder),
  ...agendaAssignmentEndpoint(builder),
  ...locationEndpoint(builder),
  ...announcementsEndpoint(builder),
  ...placeReservationEndpoint(builder),
  ...logEndpoint(builder),
});

export default endpoint;
