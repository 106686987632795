import { useCallback, useState } from 'react';

/**
 * Common toggle state function.
 * @param initialState initial toggle value
 */
export default function useToggle(initialState = false): [state: boolean, toggle: () => void] {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback((): void => setState((_state) => !_state), []);

  return [state, toggle];
}
