import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type PositionRouteParam = DefaultRouteParam;

export const positionRoute: RouteConfig = {
  name: "Position",
  Component: React.lazy(() => import("./Position.view")),
  path: "/administration/position",
  isPrivate: true,

  options: {
    label: "Position",
    testID: "MenuPosition",
  },
};
