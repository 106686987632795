import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type AgendaEventRouteParam = DefaultRouteParam;

export const agendaEventRoute: RouteConfig = {
  name: "Agenda Event",
  Component: React.lazy(() => import("./AgendaEvent.view")),
  path: "/agenda/event/",
  isPrivate: true,

  options: {
    label: "Agenda Event",
    testID: "MenuAgendaEvent",
  },
};
