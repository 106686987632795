import React, { ReactNode, useCallback } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import routerConfig, { RouteConfig } from "../../config/router/router.config";
import { loginRoute } from "../../module/auth/Login/Login.route";
import { userRoute } from "../../module/user/User.route";
import { RootState } from "../store/store.app";

type RequireAuthProps = {
  children: JSX.Element;
  isAuthenticated: boolean;
};

function RequireAuth({ children, isAuthenticated }: RequireAuthProps) {
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to={loginRoute.path} state={{ from: location }} />;
  }
  return children;
}

export default function Router() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.session.isLoggedIn
  );

  const generateRoute = useCallback(
    (routeConfigs: RouteConfig[]): ReactNode =>
      routeConfigs.map(
        ({ name, path, Component, NormalComponent, isPrivate, options }) => {
          if (!Component) return undefined;

          return (
            <React.Fragment key={name}>
              <Route
                key={name}
                path={path}
                element={
                  <RequireAuth isAuthenticated={!isPrivate || isAuthenticated}>
                    {NormalComponent || <Component />}
                  </RequireAuth>
                }
              />

              {options?.hasSub && generateRoute(options.hasSub)}
            </React.Fragment>
          );
        }
      ),
    [isAuthenticated]
  );

  return (
    <Routes>
      {generateRoute(routerConfig)}
      <Route path="*" element={<Navigate to={userRoute.path} />} />
    </Routes>
  );
}
