import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IFAQ } from "../../../../model/faq/faq.model";

export type FAQEditRouteParam = {
  faq: IFAQ;
};

export const faqEditRoute: RouteConfig = {
  name: "Edit FAQ",
  Component: React.lazy(() => import("./FAQEdit.view")),
  path: "/master-data/faq/edit/:id",
  isPrivate: true,
};
