import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { ICouncil } from "../../../../model/council/council.model";

export type CouncilEditRouteParam = {
  council: ICouncil;
};

export const councilEditRoute: RouteConfig = {
  name: "Edit Council",
  Component: React.lazy(() => import("./CouncilEdit.view")),
  path: "/administration/council/edit/:id",
  isPrivate: true,
};
