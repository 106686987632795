// #region IMPORT
import urlcat from "urlcat";
import {
  ISubCongregationAutocompleteListApiRequest,
  ISubCongregationAutocompleteListApiResponse,
  ISubCongregationCreateApiRequest,
  ISubCongregationCreateApiResponse,
  ISubCongregationDeleteApiRequest,
  ISubCongregationDeleteApiResponse,
  ISubCongregationListApiRequest,
  ISubCongregationListApiResponse,
  ISubCongregationUpdateApiRequest,
  ISubCongregationUpdateApiResponse,
} from "../../../../model/subCongregation/subCongregation.model";
import { Builder, MD, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type SubCongregationEndpoint = {
  getSubCongregation: QD<
    ISubCongregationListApiRequest,
    ISubCongregationListApiResponse
  >;
  getSubCongregationAutocomplete: QD<
    ISubCongregationAutocompleteListApiRequest,
    ISubCongregationAutocompleteListApiResponse
  >;
  createSubCongregation: MD<
    ISubCongregationCreateApiRequest,
    ISubCongregationCreateApiResponse
  >;
  updateSubCongregation: MD<
    ISubCongregationUpdateApiRequest,
    ISubCongregationUpdateApiResponse
  >;
  deleteSubCongregation: MD<
    ISubCongregationDeleteApiRequest,
    ISubCongregationDeleteApiResponse
  >;
};
// #endregion

// #region ENDPOINT
export const subCongregationTagTypes: IEndpointTags<SubCongregationEndpoint> = {
  getSubCongregation: "get-sub-congregation",
  getSubCongregationAutocomplete: "get-sub-congregation-autocomplete",
  createSubCongregation: "create-sub-congregation",
  updateSubCongregation: "update-sub-congregation",
  deleteSubCongregation: "delete-sub-congregation",
};

export const subCongregationEndpoint = (
  builder: Builder
): SubCongregationEndpoint => ({
  getSubCongregation: builder.query({
    query: ({ congregationId, ...params }) => ({
      url: urlcat(`/congregations/${congregationId}/subs`, params),
    }),
    providesTags: [subCongregationTagTypes.getSubCongregation],
  }),
  getSubCongregationAutocomplete: builder.query({
    query: ({ congregationId, ...params }) => ({
      url: urlcat(
        `/auto-complete/congregations/${congregationId}/subs`,
        params
      ),
    }),
    providesTags: [subCongregationTagTypes.getSubCongregationAutocomplete],
  }),
  createSubCongregation: builder.mutation({
    query: ({ congregationId, ...body }) => ({
      url: `/congregations/${congregationId}/subs`,
      method: "POST",
      body,
    }),
    invalidatesTags: [subCongregationTagTypes.getSubCongregation],
  }),
  updateSubCongregation: builder.mutation({
    query: ({ id, congregationId, ...body }) => ({
      url: `/congregations/${congregationId}/subs/${id}`,
      method: "PATCH",
      body,
    }),
    invalidatesTags: [subCongregationTagTypes.getSubCongregation],
  }),
  deleteSubCongregation: builder.mutation({
    query: ({ id, congregationId }) => ({
      url: `/congregations/${congregationId}/subs/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [subCongregationTagTypes.getSubCongregation],
  }),
});
// #endregion
