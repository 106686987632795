import * as Sentry from '@sentry/react';
import envConfig from '../../config/env/env.config';

// TODO: skip common error
export const errorMonitoringService = Sentry;

export const initErrorMonitoring = (): void => {
  if (process.env.NODE_ENV === 'development') return;

  errorMonitoringService.init({
    environment: process.env.NODE_ENV,
    dsn: envConfig.sentryKey,
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
  });
};

export const setMonitoringContext = ({ username }: { username: string }) => {
  errorMonitoringService.setUser({
    username,
  });
};

export const captureError = (action: string, params?: unknown, response?: unknown) => {
  if (process.env.NODE_ENV === 'development') return;

  errorMonitoringService.captureMessage(action, {
    extra: {
      params,
      response,
    },
  });
};

export default errorMonitoringService;
