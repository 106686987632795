import { nanoid } from "@reduxjs/toolkit";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import id from "date-fns/locale/id";
import parse from "date-fns/parse";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import { saveAs } from "file-saver";
import {
  ActualFileObject,
  FileOrigin,
  FilePondFile,
  FilePondInitialFile,
  FileStatus,
  LoadServerConfigFunction,
  ProcessServerConfigFunction,
  ServerUrl,
} from "filepond";
import JSZip from "jszip";
import isNaN from "lodash/isNaN";
import * as yup from "yup";
import store from "../../app/store/store.app";
import { SnackbarTheme } from "../../component/molecule/Snackbar/Snackbar.molecule";
import envConfig from "../../config/env/env.config";
import {
  IRedirectUrl,
  RedirectUrlTarget,
  commonDateFormat,
  commonDateTimeformat,
  commonDateTimezone,
  sortDirectionLongLabel,
} from "../../constant/common/common.constant";
import { ErrorCodes, ErrorMessages } from "../../constant/error/error.constant";
import { IAutocompleteType } from "../../hook/common/useAutocomplete/useAutocomplete.hook";
import { AutocompleteGroupedType } from "../../hook/common/useAutocompleteMulti/useAutocompleteMulti.hook";
import { UseTranslator } from "../../hook/common/useTranslator/useTranslator.hook";
import {
  CommonLabelValue,
  IBaseResponse,
  IUploadFileResponse,
  SortDirection,
} from "../../model/common/common.model";
import localeConfig, {
  SupportedLanguage,
} from "../../service/locale/locale.config";
import { snackbarAction } from "../../service/reducer/store/snackbar/snackbar.store";

type GetUploaderProcessParams = {
  name?: string;
  url?: string;
  token?: string;
  onAbort?: () => void;
  onProcess?: () => void;
  onSuccess?: (remoteFiles?: FilePondFile, file?: ActualFileObject) => void;
  onError?: (message: string) => void;
};

/*
 * Redirect user based on provided parameters
 * @param redirectUrl
 */
export function doRedirectUrl({
  url,
  target = RedirectUrlTarget.SELF,
}: IRedirectUrl): void {
  if (target === RedirectUrlTarget.SELF) {
    window.location.href = url;
  } else {
    window.open(url, target);
  }
}

/**
 * Gets the language used by the user in their OS Settings
 * and returns it if the language is listed on the `Availability` list.
 * If not then returns the default language, which is currently `en`.
 * @returns Default language used for the application
 */
export function getDefaultLang(): string {
  const localeString = navigator.language || "";
  const languageString = localeString.split(/[_-]/)[0].toLowerCase();
  const checkLang = Object.values(SupportedLanguage).findIndex(
    (item) => item === languageString
  );

  return checkLang > 0 ? languageString : "id";
}

/**
 * Translate function to be used outside react component
 * @param key
 * @param currentLanguage
 * @returns
 */
export function toTranslate(key: string, currentLanguage: SupportedLanguage) {
  const translationIndex = localeConfig.findIndex((find) => find.en === key);

  if (
    translationIndex >= 0 &&
    localeConfig[translationIndex][currentLanguage] !== null
  ) {
    return localeConfig[translationIndex][currentLanguage] || key;
  }

  return key;
}

export const createWrapperAndAppendToBody = (wrapperId: string) => {
  const wrapperElement = document.createElement("div");
  wrapperElement.setAttribute("id", wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
};

/**
 * Replace plus simbol in URL to proper hex code, so the `decodeURIComponent` will correctly parse the given value
 * @param value
 * @returns
 */
export function replacePlusSign(value: string) {
  return value.replace(/\+/g, "%20");
}

export const isOldSafari = () => {
  const userAgentData = navigator.userAgent.split("/");
  if (!userAgentData[3]?.includes("Safari")) return false;
  return parseFloat(userAgentData[3]?.replace(" Safari", "")) < 15.0;
};

export function groupArrayBasedOnFirstCharacter(
  arr?: IAutocompleteType[]
): AutocompleteGroupedType[] {
  if (!arr?.length) return [];
  const groups: { [key: string]: IAutocompleteType[] } = {};
  arr.forEach((obj) => {
    const firstChar = obj.label.charAt(0).toUpperCase();
    if (!groups[firstChar]) {
      groups[firstChar] = [];
    }
    groups[firstChar].push(obj);
  });

  const result = Object.keys(groups)
    .map((letter) => ({
      letter,
      item: groups[letter].sort((a, b) => a.label.localeCompare(b.label)),
    }))
    .sort((a, b) => a.letter.localeCompare(b.letter));

  return result;
}

export function convertStringToDate(
  date: string,
  formatDate = commonDateFormat
) {
  return parse(date, formatDate, new Date());
}

export function getFormattedTimeFromDate(date?: Date): string | undefined {
  if (!date) return undefined;
  return format(date, "HH:mm");
}
export function getLongFormatDate(date: Date): string {
  return formatInTimeZone(date, commonDateTimezone, commonDateTimeformat, {
    locale: id,
  });
}
export function getShortFormatDate(date?: Date): string {
  if (!date) return "-";
  return formatInTimeZone(date, commonDateTimezone, commonDateFormat, {
    locale: id,
  });
}

export function bankAccountformatter(value?: string) {
  if (!value) return "";
  const groups = value.match(/.{1,4}/g);
  if (!groups) {
    // Input string is empty or has fewer than 4 digits
    return value;
  }
  return groups.join(" ");
}

export function phoneNumberFormatter(phoneNumber?: string): string {
  if (!phoneNumber) return "-";

  const formattedNumber = phoneNumber.startsWith("0")
    ? phoneNumber.replace(/0/s, "+62")
    : phoneNumber;

  const sanitizedNumber = formattedNumber.replace("+", "");
  const digitsOnly = /^\d+$/.test(sanitizedNumber);

  if (digitsOnly && sanitizedNumber.length >= 12) {
    const countryCode = sanitizedNumber.substr(0, 2);
    const locationNumber = sanitizedNumber.substr(2, 3);
    const groups = [
      sanitizedNumber.substr(5, 4),
      sanitizedNumber.substr(9, 4),
      sanitizedNumber.substr(13),
    ];

    return `+${countryCode} ${locationNumber} ${groups.join(" ")}`;
  }
  return formattedNumber;
}
export function numberPatternFormatter(data?: string): string {
  if (!data) return "-";

  const groups = [
    data.substr(0, 4),
    data.substr(5, 4),
    data.substr(9, 4),
    data.substr(13),
  ];

  return groups.join(" ");
}

export function enumToArray(enumObj: Record<string, string>): string[] {
  return Object.values(enumObj);
}

export function formatTimerFromSeconds(timer: number) {
  const minutes = Math.floor(timer / 60);
  const seconds = timer - minutes * timer;
  return `${minutes.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${seconds.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
}

export const getShortLabel = (label?: string) => {
  if (!label) return "";
  return label.length > 25 ? `${label.slice(0, 25)}...` : label;
};

export const stringToNumberDecimalFormatter = (
  str?: string | null
): number | null => {
  if (!str) return null;
  return Number(str.replace(/\./g, "").replace(",", "."));
};

/**
 * Format number to Indonesian currency
 * @param number
 * @param digits
 * @returns
 */
export function numberFormatter(
  number: string | number,
  digits?: number
): string {
  const numberFormat = new Intl.NumberFormat("id-ID", {
    maximumFractionDigits: digits || 2,
  });

  return numberFormat.format(Number(number)).replace(/\D00(?=\D*$)/, "");
}

/**
 * Map error code from response to show error message
 * @param {ErrorCode}code - Error codes
 * @param {ErrorCode}message - Error message
 * @returns {string} displayed error message
 */
export function mapErrorCodesToErrorMessage(
  code?: string,
  message?: string
): string {
  if (ErrorMessages[code as ErrorCodes])
    return ErrorMessages[code as ErrorCodes];
  return message || "Unknown Error";
}

/**
 * Get filter order direction label & value array
 * @param {UseTranslator} translator - The translator object
 * @returns {CommonLabelValue[]} order direction label & value array
 */
export function getOrderDirectionFilterLongOptions(
  translator: UseTranslator
): CommonLabelValue[] {
  return Object.values(SortDirection).map((_orderBy) => ({
    label: translator.translate(
      sortDirectionLongLabel[_orderBy as SortDirection]
    ),
    value: _orderBy,
  }));
}

/**
 * Convert unix timestamp to unix milliseconds timestamps
 * @param unix Unix timestamp
 * @returns
 */
export function convertUnixToUnixMs(unix: number): number {
  return unix * 1000;
}

/**
 * Convert unix milliseconds timestamps to unix timestamp
 * @param unixMs Unix Milliseconds timestamp
 * @returns
 */
export function convertUnixMsToUnix(unixMs: number): number {
  return unixMs / 1000;
}

/**
 * Handle common filepond uploader process
 */
export function getUploaderProcessHandler({
  name = "file",
  token = "",
  url = "",
  onAbort = () => {},
  onProcess = () => {},
  onSuccess = () => {},
  onError = () => {},
}: GetUploaderProcessParams): ServerUrl | ProcessServerConfigFunction {
  return (_fieldName, file, _metadata, load, error, progress, abort) => {
    onProcess();
    // construct file form data
    const formData = new FormData();
    formData.append(name, file);

    const request = new XMLHttpRequest();
    // open/set connection to remote url
    request.open("POST", `${envConfig.apiUploadUrl}${url}`);
    // set token auth
    request.setRequestHeader("X-Org-Key", envConfig.apiKey);
    if (token) request.setRequestHeader("authorization", `Bearer ${token}`);
    request.upload.onprogress = ({ lengthComputable, loaded, total }) => {
      // track on upload/download progress
      progress(lengthComputable, loaded, total);
    };
    request.onload = () => {
      const response = JSON.parse(
        request.responseText
      ) as IBaseResponse<IUploadFileResponse>;

      // handle on error response
      if (request.status < 200 || request.status > 300) {
        error(response.message);
        onError(response.message);
        return;
      }

      const responseUrl = response.payload.urls?.[0] || "";
      const remoteFilename = responseUrl?.replace(
        `${envConfig.s3BucketUrl}news/`,
        ""
      );

      const formattedFile: FilePondFile = {
        abortLoad: () => {},
        abortProcessing: () => {},
        getMetadata: () => {},
        requestPrepare: async () => {
          const data = new Blob(undefined);
          return Promise.resolve(data);
        },
        setMetadata: () => {},
        id: nanoid(),
        serverId: responseUrl,
        source: file,
        origin: FileOrigin.INPUT,
        status: FileStatus.PROCESSING_COMPLETE,
        file,
        fileExtension: file.type.replace("image/", ""),
        fileSize: file.size,
        fileType: file.type,
        filename: remoteFilename,
        filenameWithoutExtension: file.name.replace(
          `.${file.type.replace("image/", "")}`,
          ""
        ),
      };
      // handle on success response
      onSuccess(formattedFile, file);
      load(responseUrl);
    };
    request.onerror = () => {
      // also handle internal on error request
      error(ErrorCodes.FILEPOND_PROGRESS_ERROR);
      onError(ErrorCodes.FILEPOND_PROGRESS_ERROR);
    };
    // send the formData based on the pre-constructed request
    request.send(formData);

    return {
      abort: () => {
        // handle on request abort
        request.abort();
        abort();
        onAbort();
      },
    };
  };
}
/**
 * Handle common filepond uploader process
 */
export function getUploaderLoadProcessHandler():
  | ServerUrl
  | LoadServerConfigFunction {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/no-unused-vars
  return async (source, load, error, _progress, _abort) => {
    try {
      const response = await fetch(source as string);
      const blob = await response.blob();

      return load(blob);
    } catch (err) {
      return error("Failed to fetch images");
    }
  };
}

/**
 * Convert sentence to ellipsis
 * @param sentence the sentence will converted to ellipsis
 * @param nWord number of word will be shown
 * @returns the converted ellipsis sentence
 */
export function toEllipsis(sentence: string | null, nWord = 3) {
  if (!sentence) return sentence;
  const arrayWord = sentence.split(" ");
  if (arrayWord.length <= nWord) return sentence;
  return `${arrayWord.slice(0, nWord).join(" ")}...`;
}

export function getMillisTimestamp(timestamp?: number | null) {
  if (!timestamp) return new Date();
  return fromUnixTime(timestamp / 1000);
}
/**
 * Convert month index to label
 * @param monthInIndex month index starts with 0 === January
 * @returns "Januari" | "Februari" | etc...
 */
export function convertMonthIndexToLabel(
  idx: number,
  locale: Intl.LocalesArgument = "id-ID",
  options: Intl.DateTimeFormatOptions = { month: "long" }
) {
  const objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(idx);

  const month = objDate.toLocaleString(locale, options);
  return month;
}

/**
 * get localized weekday names
 * @param locale
 * @param dateStyle
 * @returns ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"]
 */
export function localizedWeekdayNames(
  locale: Intl.LocalesArgument = "id-ID",
  dateStyle: Intl.RelativeTimeFormatStyle = "short"
): string[] {
  const daysInWeek = 7;
  const dayNames: string[] = [];
  const currentDate = new Date();

  while (currentDate.getDay() !== 0) {
    currentDate.setDate(currentDate.getDate() + 1);
  }

  for (let day = 0; day < daysInWeek; day += 1) {
    dayNames.push(
      currentDate.toLocaleDateString(locale, { weekday: dateStyle })
    );
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dayNames;
}

/**
 * get localized months names
 * @param locale
 * @param dateStyle
 * @returns ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"]
 */
export function localizedMonthNames(
  locale: Intl.LocalesArgument = "id-ID",
  dateStyle: Intl.RelativeTimeFormatStyle = "short"
): string[] {
  const monthsInYear = 12;
  const monthNames: string[] = [];
  const currentDate = new Date();

  while (currentDate.getMonth() !== 0) {
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  for (let month = 0; month < monthsInYear; month += 1) {
    monthNames.push(
      currentDate.toLocaleDateString(locale, { month: dateStyle })
    );
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return monthNames;
}

/**
 * This will works with some rules:
 * 1. If the file source located in the same-origin CORS policy as the application.
 * 2. If the file source is on different location e.g s3 bucket, etc. Set the response headers `Content-Disposition: attachment`.
 * Otherwise it only view on new tab.
 */
export function doDownload(url: string) {
  if (!url) return;
  const link = document.createElement("a");
  link.href = url;
  // link.download = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function doDownloadFile(url: string): Promise<void> {
  try {
    // Fetch the file using the fetch API
    const response = await fetch(url);

    // Check if the request was successful
    if (!response.ok) {
      throw new Error(`Failed to fetch file from ${url}`);
    }

    // Get the blob from the response
    const fileBlob = await response.blob();

    // Create a download link
    const downloadUrl = window.URL.createObjectURL(fileBlob);
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;

    // Extract the file name from URL or use a default name
    const fileName = url.split("/").pop() || "download.jpg";
    anchor.download = fileName;

    // Trigger the download by simulating a click
    anchor.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Download failed", error);
  }
}

/**
 * Remove text from hashtag index to end
 * @param text text to be converted
 * @returns text without hashtag
 */
export function removeHashtag(text: string) {
  const indexHastag = text.indexOf("#");
  if (indexHastag < 0) return text;

  return text.slice(0, indexHastag);
}

/**
 * check is date valid or not
 * @param date
 * @returns boolean
 */
export function isDateValid(date: Date): boolean {
  return !isNaN(date.getTime());
}

/**
 * Format date from string YYYY MM DD
 * @param dateString dateString to be converted
 * @returns date
 */
export function getDateFromString(
  dateString: string,
  formatDate = "yyyyMMdd"
): Date | undefined {
  const formattedDate = parse(dateString, formatDate, new Date());
  if (isDateValid(formattedDate)) return formattedDate;
  return undefined;
}

export async function getBinaryContent({
  path,
  onSuccess,
}: {
  path: string;
  onSuccess: (data: string | ArrayBuffer) => Promise<void>;
}) {
  try {
    const res = await fetch(`${path}?x-request=xhr`);
    const data = await res?.arrayBuffer();
    if (data) await onSuccess(data);
  } catch (err) {
    throw new Error(err as string);
  }
}

/**
 *
 * @param url
 * @param translator
 * @param zipName
 * @returns
 */

export async function doDownloadAndExtractAllUrl(
  url: string[],
  translator: UseTranslator,
  zipName = "zipFile"
) {
  const zip = JSZip();

  if (!window.navigator.onLine) {
    store.dispatch(
      snackbarAction.show({
        type: SnackbarTheme.warning,
        message: translator.translate("No internet connection"),
      })
    );
    return;
  }
  if (!url.length) return;

  for (let i = 0; i < url.length; i += 1) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await getBinaryContent({
        path: url[i],
        onSuccess: async (data) => {
          try {
            zip.file(`${zipName}-data-${i}`, data, { binary: true });
            if (i === url.length - 1) {
              await zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(content, zipName);
              });
            }
          } catch (err) {
            if (typeof err === "string") {
              store.dispatch(
                snackbarAction.show({
                  type: SnackbarTheme.warning,
                  message: err,
                })
              );
            }
          }
        },
      });
    } catch (err) {
      store.dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translator.translate("Download Process Failed"),
        })
      );
    }
  }
}

export function generateUploaderFileFromUrl(url: string): FilePondInitialFile {
  return {
    source: url,
    options: {
      type: "local",
    },
  };
}

export function replacePlusAndMinus(input: string): string {
  // Use the replace method with a regular expression to replace all "+" or "-" with "-"
  if (!input) return "";
  const result = input?.replace(/[+-]/g, "");
  return result;
}

export function getPlusAndMinus(input: string): string | undefined {
  if (!input) return undefined;
  const pattern = /[+-]/g;
  const matches = input?.match(pattern);

  if (matches?.length) return matches[0];
  return undefined;
}

/**
 * yup contact number validation
 * @param fieldName
 * @returns
 */
export function validateContactNumber(fieldName: string) {
  return (value?: string) => {
    if (!value) return true;
    if (/^8/.test(value)) {
      return /(^8\d{8,11}$)/g.test(value)
        ? true
        : new yup.ValidationError(
            "Mobile number is invalid.",
            value,
            fieldName
          );
    }

    return /(^\d{2,3}\d{6,9}$)/g.test(value)
      ? true
      : new yup.ValidationError(
          "Telephone number is invalid.",
          value,
          fieldName
        );
  };
}

export const removeNonNumericString = (value: string) =>
  value.replace(/\D/g, "");

/**
 * yup Phone number validation
 * @param fieldName
 * @returns
 */
export function validatePhoneNumber(fieldName: string) {
  return (value?: string) => {
    if (!value) return true;
    const cleanedNumber = removeNonNumericString(value);
    if (/^0/.test(cleanedNumber)) {
      if (/(^0\d{8,11}$)/g.test(cleanedNumber)) {
        return true;
      }
      if (cleanedNumber.length < 9) {
        return new yup.ValidationError(
          "Phone number must be at least 9 digits.",
          cleanedNumber,
          fieldName
        );
      }
      if (cleanedNumber.length > 12) {
        return new yup.ValidationError(
          "Phone number maximum 12 digits.",
          cleanedNumber,
          fieldName
        );
      }
      return new yup.ValidationError(
        "Phone number is invalid.",
        cleanedNumber,
        fieldName
      );
    }
    return new yup.ValidationError(
      "Phone number start with 0.",
      cleanedNumber,
      fieldName
    );
  };
}

export function mapNumberToMonthLabel(type: number) {
  const mapper: Record<number | "DEFAULT", string> = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
    DEFAULT: "Not Found",
  };
  return mapper[type] || mapper.DEFAULT;
}

export function mapNumberToDayLabel(type: number) {
  const mapper: Record<number | "DEFAULT", string> = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
    DEFAULT: "Not Found",
  };
  return mapper[type] || mapper.DEFAULT;
}

export function generateArray({ length }: { length: number }) {
  return Array.from({ length }, (_, i) => i);
}

export function getTodayDateWithTime({
  hours,
  minutes,
}: {
  hours?: number;
  minutes?: number;
}) {
  if (hours !== undefined && minutes !== undefined) {
    return setSeconds(setMinutes(setHours(new Date(), hours), minutes), 0);
  }

  return undefined;
}

export function capitalizeFirstLetter(input: string): string {
  return input
    .trim()
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());
}
