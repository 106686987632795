// #region IMPORT
import urlcat from "urlcat";
import {
  ILogUserListApiRequest,
  ILogUserListApiResponse,
} from "../../../../model/log/log.model";
import { Builder, QD } from "../../api.baseQuery";
import { IEndpointTags } from "../../api.endpoint";
// #endregion

// #region INTERFACES
export type LogEndpoint = {
  getLogUser: QD<ILogUserListApiRequest, ILogUserListApiResponse>;
};
// #endregion

// #region ENDPOINT
export const logTagTypes: IEndpointTags<LogEndpoint> = {
  getLogUser: "get-log-user",
};

export const logEndpoint = (builder: Builder): LogEndpoint => ({
  getLogUser: builder.query({
    query: (param) => urlcat("/logs/user-logins", param),
    providesTags: [logTagTypes.getLogUser],
  }),
});
// #endregion
