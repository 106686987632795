import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../config/router/router.config";

export type ForgetPasswordRouteParam = DefaultRouteParam;

export const forgetPasswordRoute: RouteConfig = {
  name: "Forget Password",
  path: "/forget-password",
  Component: React.lazy(() => import("./ForgetPassword.view")),
  isPrivate: false,
  options: {
    label: "forgetPassword",
    testID: "MenuForgetPassword",
  },
};
