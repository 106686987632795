import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../../../config/router/router.config";

export type SubCongregationCreateRouteParam = DefaultRouteParam;

export const subCongregationCreateRoute: RouteConfig = {
  name: "Create Sub Congregation",
  Component: React.lazy(() => import("./SubCongregationCreate.view")),
  path: "/administration/congregation/:congregationId/subCongregation/create",
  isPrivate: true,
};
