import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { ISacrament } from "../../../../model/sacrament/sacrament.model";

export type SacramentEditRouteParam = {
  sacrament: ISacrament;
};

export const sacramentEditRoute: RouteConfig = {
  name: "Edit Sacrament",
  Component: React.lazy(() => import("./SacramentEdit.view")),
  path: "/master-data/sacrament/edit/:id",
  isPrivate: true,
};
