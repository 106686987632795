import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IFamily } from "../../../../model/family/family.model";

export type FamilyEditRouteParam = {
  family: IFamily;
};

export const familyEditRoute: RouteConfig = {
  name: "Edit Family",
  Component: React.lazy(() => import("./FamilyEdit.view")),
  path: "/user-management/family/edit/:id",
  isPrivate: true,
};
