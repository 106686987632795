import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type FamilyRouteParam = DefaultRouteParam;

export const familyRoute: RouteConfig = {
  name: "Family",
  Component: React.lazy(() => import("./Family.view")),
  path: "/user-management/family",
  isPrivate: true,

  options: {
    label: "Family",
    testID: "MenuFamily",
  },
};
