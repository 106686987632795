import React from "react";
import Merchant from "../../component/atom/Icon/SVG/Merchant";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";
import { placeRoute } from "../place/Place.route";
import { placeReservationRoute } from "../placeReservation/PlaceReservation.route";

export type LocationRouteParam = DefaultRouteParam;

export const locationRoute: RouteConfig = {
  name: "Lokasi",
  Component: React.lazy(() => import("./Location.view")),
  path: "/location",
  isPrivate: false,
  breadcrumb: "Lokasi",
  children: [
    {
      ...placeRoute,
      path: "place",
    },
    {
      ...placeReservationRoute,
      path: "reservation",
    },
  ],
  props: {
    isUnclickable: true,
  },
  options: {
    icon: <Merchant />,
    label: "Lokasi",
    testID: "MenuLocation",
    hasDivider: true,
    hasSub: [placeRoute, placeReservationRoute],
  },
};
