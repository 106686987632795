import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IAgendaSchedule } from "../../../../model/agendaSchedule/agendaSchedule.model";

export type AgendaScheduleEditRouteParam = {
  agendaSchedule: IAgendaSchedule;
};

export const agendaScheduleEditRoute: RouteConfig = {
  name: "Edit Agenda Schedule",
  Component: React.lazy(() => import("./AgendaScheduleEdit.view")),
  path: "/agenda/schedule/edit/:id",
  isPrivate: true,
};
