import { Locale } from "../locale.config";

const faqLocaleConfig: Locale[] = [
  {
    en: "FAQ",
    id: "FAQ",
  },
  {
    en: "Question",
    id: "Pertanyaan",
  },
  {
    en: "Question is required.",
    id: "Pertanyaan wajib diisi.",
  },
  {
    en: "Answer",
    id: "Jawaban",
  },
  {
    en: "Answer is required.",
    id: "Jawaban wajib diisi.",
  },
  {
    en: "Add FAQ",
    id: "Tambah FAQ",
  },
  {
    en: "Update FAQ",
    id: "Ubah FAQ",
  },
  {
    en: "FAQ successfully updated",
    id: "FAQ berhasil diubah",
  },
  {
    en: "FAQ successfully created",
    id: "FAQ berhasil dibuat",
  },
  {
    en: "Failed to update FAQ",
    id: "Gagal mengubah FAQ",
  },
  {
    en: "Failed to create FAQ",
    id: "Gagal membuat FAQ",
  },
  {
    en: "Delete FAQ",
    id: "Hapus FAQ",
  },
  {
    en: "Are you sure you want to delete fAQ? Deleted FAQ cannot be undone.",
    id: "Anda yakin menghapus FAQ? FAQ yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "FAQ Successfully Deleted",
    id: "FAQ Berhasil Dihapus",
  },
  {
    en: "Failed to Delete FAQ",
    id: "Gagal Menghapus FAQ",
  },
  {
    en: "Try adding FAQ by clicking the button on the top right, or you can click the button below",
    id: "Silahkan tambah FAQ dengan menekan tombol di pojok kanan atas, atau anda dapat menekan tombol dibawah",
  },
];

export default faqLocaleConfig;
