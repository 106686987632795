import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IPlaceReservation } from "../../../../model/placeReservation/placeReservation.model";

export type PlaceReservationDetailRouteParam = {
  placeReservation: IPlaceReservation;
};

export const placeReservationDetailRoute: RouteConfig = {
  name: "Detail Place Reservation",
  Component: React.lazy(() => import("./PlaceReservationDetail.view")),
  path: "/location/reservation/:id",
  isPrivate: true,
};
