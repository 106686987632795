import { Locale } from "../locale.config";

const agendaRolesLocaleConfig: Locale[] = [
  {
    en: "Agenda Roles",
    id: "Peran Kegiatan",
  },
  {
    en: "Agenda Roles Detail",
    id: "Detail Peran Kegiatan",
  },
  {
    en: "Add Agenda Roles",
    id: "Tambah Peran Kegiatan",
  },
  {
    en: "Update Agenda Roles",
    id: "Ubah Peran Kegiatan",
  },
  {
    en: "Delete Agenda Roles",
    id: "Hapus Peran Kegiatan",
  },
  {
    en: "Are you sure you want to delete agenda Roles? Deleted Agenda Roles cannot be undone.",
    id: "Anda yakin menghapus peran kegiatan? Peran Kegiatan yang dihapus tidak dapat diubah kembali.",
  },
  {
    en: "Agenda Roles Successfully Deleted",
    id: "Peran Kegiatan Berhasil dihapus",
  },
  {
    en: "Failed to Delete Agenda Roles",
    id: "Gagal Menghapus Peran Kegiatan",
  },
  {
    en: "Agenda Roles successfully created",
    id: "Peran Kegiatan berhasil dibuat",
  },
  {
    en: "Failed to create agenda Roles",
    id: "Gagal membuat peran kegiatan",
  },
  {
    en: "Agenda Roles successfully updated",
    id: "Peran Kegiatan berhasil diubah",
  },
  {
    en: "Failed to update agenda Roles",
    id: "Gagal mengubah peran legiatan",
  },
  {
    en: "Try adding Agenda Roles by clicking the button on the top right, or you can click the button below",
    id: "Tekan tombol di pojok kanan atas untuk menambahkan, atau klik tombol di bawah",
  },
];

export default agendaRolesLocaleConfig;
