import React from "react";
import { RouteConfig } from "../../../../config/router/router.config";
import { IAgendaEvent } from "../../../../model/agendaEvent/agendaEvent.model";

export type AgendaEventEditRouteParam = {
  agendaEvent: IAgendaEvent;
};

export const agendaEventEditRoute: RouteConfig = {
  name: "Edit Agenda Event",
  Component: React.lazy(() => import("./AgendaEventEdit.view")),
  path: "/agenda/event/edit/:id",
  isPrivate: true,
};
