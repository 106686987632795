import React from "react";
import {
  DefaultRouteParam,
  RouteConfig,
} from "../../config/router/router.config";

export type CouncilRouteParam = DefaultRouteParam;

export const councilRoute: RouteConfig = {
  name: "Council",
  Component: React.lazy(() => import("./Council.view")),
  path: "/administration/council",
  isPrivate: true,

  options: {
    label: "Council",
    testID: "MenuCouncil",
  },
};
